import React from "react";
import { Chip, useTheme } from "components/common/ExternalComponents";
import { ChipProps } from "utils/types/CommonTypes";

function CustomChip({ tag, filterBy }: ChipProps) {
  const theme = useTheme();

  switch (tag || filterBy) {
    case "Outflow":
    case "Transfer":
    case "Unsuccessful":
      return (
        <Chip
          label={
            tag === "Outflow"
              ? "Outflow"
              : filterBy === "Transfer"
              ? "Transfer"
              : "Unsuccessful"
          }
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.purple}`,
              color: `${theme.palette.common.purpleIcon}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Inflow":
    case "Card":
    case "Successful":
      return (
        <Chip
          label={
            tag === "Inflow"
              ? "Inflow"
              : filterBy === "Successful"
              ? "Successful"
              : "Card"
          }
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.green}`,
              color: `${theme.palette.common.greenIcon}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Balance":
    case "Pending":
      return (
        <Chip
          label={filterBy === "Balance" ? "Balance" : "Pending"}
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.prdPur}`,
              color: `${theme.palette.common.prdT}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    default:
      return (
        <Chip
          label="All"
          sx={{
            "&.MuiChip-root": {
              color: `${theme.palette.common.black}`,
              fontWeight: 600,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "0px 10px",
            },
          }}
        />
      );
  }
}
export default CustomChip;
