import React from "react";
import { List, ListItemButton } from "components/common/ExternalComponents";
import CustomChip from "components/chips/TableChips";
import { FilterOptionProps } from "utils/types/CommonTypes";

const FilterOptions = ({
  options,
  handleChange,
  popoverId,
}: FilterOptionProps) => {
  const getParameterValue = (option: any) => {
    switch (option) {
      case "Inflow":
        return "CREDIT";
      case "Outflow":
        return "DEBIT";
      case "Card":
        return "CARD";
      case "Transfer":
        return "BANK_TRANSFER";
      case "Balance":
        return "BALANCE";
      case "Successful":
        return "SUCCESS";
      case "Unsuccessful":
        return "FAILED";
      case "Pending":
        return "INITIALIZED";
      default:
        return "";
    }
  };

  return (
    <List>
      {options.map((element: any) => {
        return (
          <ListItemButton
            key={element}
            onClick={(e) =>
              handleChange(e, getParameterValue(element), popoverId)
            }
          >
            {element === "Inflow" ? (
              <CustomChip tag={element} />
            ) : element === "Outflow" ? (
              <CustomChip tag={element} />
            ) : (
              <CustomChip filterBy={element} />
            )}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default FilterOptions;
