import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  VisibilityOff,
  Visibility,
  Formik,
  Form,
  useNavigate,
  useLocation,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { FormikControl } from "components/form/FormikControl";
import CustomLink from "components/links/Link";
import { FORGOT_PASSWORD_ROUTE, PASSWORD_RESET_ROUTE } from "constants/Routes";
import AuthFormLayout from "layouts/AuthFormLayout";
import AuthLayout from "layouts/AuthLayout";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { otpValidator } from "utils/validators/AuthFormValidator";

type inputValue = { [key: string]: any };

const initialValues = {
  otp: "",
};

const EnterOTPPage: React.FC<any> = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (values: inputValue) => {
    dispatch(globalLoadingAction(true));
    navigate(PASSWORD_RESET_ROUTE, { state: { state, values } });
  };

  return (
    <AuthLayout>
      <AuthFormLayout>
        <Formik
          initialValues={initialValues}
          validationSchema={otpValidator}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form placeholder={undefined}>
                <Box mb={4}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      color: "#6B6B6B",
                      fontWeight: 600,
                      marginBottom: "20px",
                    }}
                  >
                    Enter Verification Code{" "}
                  </Typography>
                  <FormikControl
                    control="input2"
                    placeholder="Enter otp"
                    name={"otp"}
                    id={"otp"}
                    type={!showPassword ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box mt={1} textAlign={"center"}>
                    <Typography fontSize="11px">
                      Didn't receive a code?{" "}
                      <CustomLink
                        route={`/${FORGOT_PASSWORD_ROUTE}`}
                        styles={{
                          color: "black",
                          fontSize: "16px",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Resend
                      </CustomLink>
                    </Typography>
                  </Box>
                </Box>

                <Box textAlign="center">
                  <CustomButton
                    type="submit"
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    Proceed
                  </CustomButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </AuthFormLayout>
    </AuthLayout>
  );
};

export default EnterOTPPage;
