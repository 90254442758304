import React, { useEffect, useState } from "react";
import {
  Box,
  Close,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Typography,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import ModalLoader from "components/loader/ModalLoader";
import ModalList from "components/lists/ModalList";
import SearchBar from "components/search/SearchBar";
import { SIGNIN_ROUTE } from "constants/Routes";
import { WALLET_LIST_ENDPOINT } from "constants/Endpoints";
import {
  changePageNumber,
  userLogoutAction,
} from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";
import { ModalProps } from "utils/types/CommonTypes";

export const CustomModal = ({
  setPage,
  setOpenDialog,
  setID,
  setAnchorElFilter,
  openDialog,
  url,
  text,
  localSearch,
  setLocalSearch,
  formik,
  setWalletOrTerminal,
}: ModalProps) => {
  const [options, setOptions] = useState<any[]>();
  const [loadProgress, setLoadProgress] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (option_id: string, formik: any) => {
    setPage && setPage(0);
    dispatch(changePageNumber(0));
    if (!formik) {
      url === WALLET_LIST_ENDPOINT
        ? setWalletOrTerminal("wallet")
        : setWalletOrTerminal("terminal");
      setID(option_id);
    } else {
      setID(option_id);
      formik?.setFieldValue("wallet", option_id);
    }
    setOpenDialog(false);
    setAnchorElFilter && setAnchorElFilter(null);
  };

  const selectOption = async () => {
    setLoadProgress(true);
    try {
      if (localSearch) {
        url = url + `?search=${localSearch}`;
      }
      const resp = await customFetch.get(url);
      setOptions(resp?.data?.results);
      setLoadProgress(false);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      selectOption();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [localSearch]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          height: "500px",
          overflow: "scroll",
        },
      }}
    >
      <Paper
        sx={{
          width: "500px",
          p: 4,
          height: "inherit",
        }}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            variant="h4"
            color="initial"
            sx={{
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Select {text}
          </Typography>{" "}
          <Close
            sx={{
              cursor: "pointer",
              fontSize: "1.8rem",
              color: `${theme.palette.common.lightBlue}`,
            }}
            onClick={() => setOpenDialog(false)}
          />
        </Stack>
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              padding: 0,
              height: "100%",
              overflow: "scroll",
            },
          }}
        >
          <Box width={"100%"}>
            <Box mt={3} mb={3} width={"inherit"}>
              <SearchBar
                localSearch={localSearch}
                setLocalSearch={setLocalSearch}
                setPage={setPage}
                border={`2px solid ${theme.palette.common.lightBlue}`}
                border2={"none"}
                height2={"0.4rem"}
              />
            </Box>
            {loadProgress ? (
              <ModalLoader />
            ) : (
              <ModalList
                options={options}
                handleChange={handleChange}
                formik={formik}
              />
            )}
          </Box>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};

export default CustomModal;
