import React from "react";
import Loading from "components/loader/Loading";
import {
  ErrorBoundary,
  BrowserRouter as Router,
} from "components/common/ExternalComponents";
import Fallback from "components/error/Fallback";
import Routes from "containers/Routes";

const App = () => {
  const errorHandler = (error: any, info: React.ErrorInfo) => {
    console.log(error);
    console.log(info);
  };

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
      <Loading />
      <Router>
        <Routes />
      </Router>
    </ErrorBoundary>
  );
};

export default App;
