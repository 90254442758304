import React, { useEffect, useRef, useState } from "react";
import {
  toast,
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomChip from "components/chips/Chip";
import DateComponent from "components/date/DateComponent";
import SearchBar from "components/search/SearchBar";
import DownloadBtn from "components/buttons/DownloadButton";
import FilterBtn from "components/buttons/filter/FilterButton";
import { BALANCE_DETAILS_ROUTE, SIGNIN_ROUTE } from "constants/Routes";
import { KYC_LIST_ENDPOINT } from "constants/Endpoints";
import Indicators from "pages/dvault/merchantVerification/Indicators";
import {
  changePageNumber,
  userLogoutAction,
} from "service/store/UserProfileSlice";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { dateConverter2, formattedDate } from "utils/Helpers";
import {
  Column,
  DashboardData,
  DateRange,
  createData,
} from "utils/types/CommonTypes";
import { customFetch } from "utils/Axios";

const columns: Column[] = [
  { id: "sn", label: "S/N", minWidth: 70, align: "center" },
  { id: "fullName", label: "Full Name", minWidth: 150, align: "left" },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "left",
  },
  { id: "phoneNumber", label: "Phone Number", minWidth: 100, align: "left" },
  { id: "tier", label: "Tier", minWidth: 50, align: "center" },
  {
    id: "dateOfRegistration",
    label: "Date of registration",
    minWidth: 100,
    align: "center",
  },
];

interface RowDetails {
  [key: string]: any;
  kycID: string;
}

function DashboardTable() {
  const { pageNumber } = useAppSelector((store) => store.profileSliceReducer);
  const [page, setPage] = useState<number | any>(pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allKYC, setAllKYC] = useState<never[]>([]);
  const currentID = useRef<string>("");
  const [localSearch, setLocalSearch] = useState<string>("");
  const [idCardIsVerified, setIdCardIsVerified] = useState<boolean>();
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(pageNumber);
  };

  let url = `${KYC_LIST_ENDPOINT}?page=${page + 1}&page_size=${rowsPerPage}`;

  const getAllKYC = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (idCardIsVerified === true) {
        url = url + `&is_verified=${true}`;
      }
      if (idCardIsVerified === false) {
        url = url + `&is_verified=${false}`;
      }
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }
      const resp = await customFetch.get(url);
      const newData = resp?.data?.results?.map(
        (kycDetail: DashboardData, index: number) => {
          const {
            first_name,
            last_name,
            phone_number,
            id,
            tier,
            created_at,
            email,
          } = kycDetail;

          return createData(
            page * rowsPerPage + index + 1,
            `${first_name} ${last_name}`,
            email,
            phone_number,
            tier,
            formattedDate(created_at),
            id
          );
        }
      );
      setAllKYC(newData);
      setTotalCount(resp.data.count);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllKYC();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [page, localSearch, idCardIsVerified, dateRange, rowsPerPage]);

  return (
    <>
      <DateComponent
        setPage={setPage}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <Indicators />

      <Box
        sx={{
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <Paper elevation={1}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              px={4}
              py={1.8}
            >
              <Box>
                <SearchBar
                  localSearch={localSearch}
                  setLocalSearch={setLocalSearch}
                  setPage={setPage}
                />

                <DownloadBtn
                  url={KYC_LIST_ENDPOINT}
                  dateRange={dateRange}
                  localSearch={localSearch}
                  idCardIsVerified={idCardIsVerified}
                  platformId={""}
                  terminalID={""}
                  walletID={""}
                  downloadMethodFilter={""}
                  downloadStatusFilter={""}
                  downloadTypeFilter={""}
                />
              </Box>

              <FilterBtn
                setPage={setPage}
                setFilter={setIdCardIsVerified}
                options={["verified", "unverified", "All"]}
              />
            </Stack>
          </Paper>
        </Box>

        {allKYC && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                        color: theme.palette.common.greyColor,
                        fontWeight: "600",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allKYC.map((row: RowDetails, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() =>
                          navigate(`${BALANCE_DETAILS_ROUTE}/${row.kycID}`)
                        }
                        hover
                        onMouseOver={() => {
                          currentID.current = row.kycID;
                        }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                color: theme.palette.common.tableBodyColor,
                                fontWeight: "400",
                                lineHeight: "24px",
                              }}
                            >
                              {value === "verified" ? (
                                <CustomChip label="verified" />
                              ) : value === "unverified" ? (
                                <CustomChip label="unverified" />
                              ) : value === "flagged" ? (
                                <CustomChip label="flagged" />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default DashboardTable;
