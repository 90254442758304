import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getItem, saveItem } from "service/helper/StorageHelper";

export const userProfileSliceName = "profileSliceReducer";
export const userProfileStorageName = "profile";

interface Values {
  credential: string;
  password: string;
}
export interface IProfile {
  pageNumber?: number | any;
  [key: string]: any;
}

const initialState: IProfile = {
  pageNumber: 0,
  refresh_token: "",
  token: "",
  user: {
    created_at: "",
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    middle_name: "",
    phone_number: "",
    updated_at: "",
    username: "",
    username_updates: 0,
  },
  ...(getItem(userProfileStorageName) || {}),
};

export const userProfileSlice = createSlice({
  name: userProfileSliceName,
  initialState,
  reducers: {
    userLoginAction(state: any, action: PayloadAction<{ [key: string]: any }>) {
      saveItem(userProfileStorageName, { ...action.payload });
      return { ...state, ...action.payload };
    },
    userLogoutAction(): any {
      // deleteItem(userProfileStorageName);
      // deleteItem(roleStorageName);
      sessionStorage.clear();
      return {};
    },
    userUpdateProfileAction(
      state: any,
      action: PayloadAction<{ [key: string]: any }>
    ) {
      saveItem(userProfileStorageName, { ...state, ...action.payload });
      return { ...state, ...action.payload };
    },
    changePageNumber: (state: any, action: any) => {
      state.pageNumber = action.payload;
    },
  },
});

export const {
  userLoginAction,
  userLogoutAction,
  userUpdateProfileAction,
  changePageNumber,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
