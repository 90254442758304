// Auth api
export const LOGIN_ENDPOINT = "auth/login/";
export const FORGOT_PASSWORD_ENDPOINT = "auth/generate-otp/";
export const RESET_PASSWORD_ENDPOINT = "auth/reset-password/";

// User api
export const GET_USER_ROLE_ENDPOINT = "dvault/__user_detail";

// KYC api
export const KYC_LIST_ENDPOINT = "dvault/__kyc_list";
export const KYC_DETAIL_ENDPOINT = "dvault/__kyc_detail";
export const KYC_UPDATE_ENDPOINT = "dvault/__kyc_update";
export const KYC_METRICS_ENDPOINT = "dvault/__kyc_metrics";
export const KYC_UPLOAD_ENDPOINT = "auth/upload";
export const KYC_TIERS_ENDPOINT = "dvault/__get_kyc_tiers";
export const ID_CARD_UPDATE_ENDPOINT = "dvault/__id_card_update";

// PRD api
export const TRANSACTION_HISTORY_LIST_ENDPOINT =
  "dvault/__transaction_history_list";
export const TRANSACTION_CHARGES_ENDPOINT = "dvault/__transaction_charges";

// Wallet api
export const WALLET_LIST_ENDPOINT = "dvault/__wallet_list_external";
export const WALLET_DETAIL_ENDPOINT = "dvault/__wallet_detail";
export const WALLET_TOTAL_ENDPOINT = "dvault/__wallets_total_balance";

// Platform api
export const PLATFORM_LIST_ENDPOINT = "dvault/__platform_list";
export const PLATFORM_METRICS_ENDPOINT =
  "dvault/__platform_transaction_metrics";

// Terminal distribution api
export const CREATE_TERMINAL_ENDPOINT = "dvault/__pos_terminal_create";
export const TERMINAL_LIST_ENDPOINT = "dvault/__pos_terminal_list";
export const UPDATE_TERMINAL_ENDPOINT = "dvault/__pos_terminal_update";

// Terminal request api
export const POS_REQUEST_ENDPOINT = "dukka/__pos_requests";
export const UPDATE_POS_REQUEST_ENDPOINT = "dukka/__update_pos_request";
export const TERMINAL_REQUEST_TOTAL_ENDPOINT = "dukka/__pos_request_totals";
