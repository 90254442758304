import { saveAs, toast } from "components/common/ExternalComponents";

export const arrayToCSV = (data: any) => {
  const csvRows = [];
  const header = Object.keys(data[0]);
  csvRows.push(header.join(","));
  data.forEach((obj: any) => {
    const values = Object.values(obj);
    const csvRow = values.map((value) => JSON.stringify(value));
    csvRows.push(csvRow.join(","));
  });
  return csvRows.join("\n");
};

export function downloadFile(data: any, filename: string) {
  try {
    const blob = new Blob([arrayToCSV(data)], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `${filename}.csv`);
  } catch (error: any) {
    console.error("Error downloading file:", error);
    toast.error(error);
  }
}
