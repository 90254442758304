import React, { useState } from "react";
import {
  ArrowDropDown,
  Box,
  Button,
  Popover,
  Theme,
  useTheme,
} from "components/common/ExternalComponents";
import TableFilterOptions from "components/buttons/filter/TableFilterOptions";
import { changePageNumber } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";

const TableFilterButton = ({
  text,
  setPage,
  popoverId,
  options,
  setStatus,
  setPaymentMethod,
  setTransactionType,
  setDownloadTypeFilter,
  setDownloadStatusFilter,
  setDownloadMethodFilter,
}: any) => {
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null>(null);

  const theme: Theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (e: any, popoverId: string) => {
    setAnchorEl(e.currentTarget);
    setOpenPopoverId(popoverId);
  };

  const handleClose = (e: any) => {
    setOpenPopoverId(null);
  };

  const handleChange = (e: any, filterInput: string, popoverId: string) => {
    setPage(0);
    dispatch(changePageNumber(0));
    setStatus(filterInput);
    setPaymentMethod(filterInput);
    setTransactionType(filterInput);
    if (popoverId === "type-popover") {
      setDownloadTypeFilter(filterInput);
    }
    if (popoverId === "payment_method-popover") {
      setDownloadMethodFilter(filterInput);
    }
    if (popoverId === "status-popover") {
      setDownloadStatusFilter(filterInput);
    }
    handleClose(e);
  };

  return (
    <>
      <Button
        endIcon={<ArrowDropDown />}
        aria-describedby={popoverId}
        onClick={(e) => handleClick(e, popoverId)}
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "#EFF1F9",
          color: theme.palette.common.greyColor,
          fontWeight: "600",
        }}
      >
        {text}
      </Button>
      <Popover
        id={popoverId}
        open={openPopoverId === popoverId}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box sx={{ width: "150px" }}>
          <TableFilterOptions
            handleChange={handleChange}
            options={options}
            popoverId={popoverId}
          />
        </Box>
      </Popover>
    </>
  );
};

export default TableFilterButton;
