import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  useFormik,
  Yup,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { KYC_UPDATE_ENDPOINT, KYC_UPLOAD_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";

const validationSchema = Yup.object().shape({
  selfie: Yup.mixed().required("This is a required field"),
});

interface UpdateSelfieFormProps {
  handleClose: any;
  kycID: string;
  passport_photo: string;
  [key: string]: any;
}

function UpdateSelfieForm({
  handleClose,
  kycID,
  passport_photo,
  idCards,
}: UpdateSelfieFormProps) {
  const [runSelfieEndpoint, setRunSelfieEndpoint] = useState(false);
  const [newPhoto, setNewPhoto] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateCredentials = async () => {
    if (kycID) {
      try {
        const resp = await customFetch.patch(
          `${KYC_UPDATE_ENDPOINT}?id=${kycID}`,
          {
            passport_photo: newPhoto,
          }
        );
        dispatch(globalLoadingAction(true));
        toast.success(`Update successful`);
        handleClose();
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(userLogoutAction());
          return navigate(SIGNIN_ROUTE);
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          error?.response?.data?.error?.server
            ? toast.error(error?.response?.data?.error?.server[0])
            : toast.error("Update failed");
        }
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error)
          : toast.error("Update failed");
        handleClose();
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      selfie: passport_photo,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      if (runSelfieEndpoint && values.selfie) {
        try {
          const resp = await customFetch.post(
            KYC_UPLOAD_ENDPOINT,
            { file: values.selfie },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          setNewPhoto(resp?.data?.url);
        } catch (error: any) {
          if (error?.response?.status === 401) {
            dispatch(userLogoutAction());
            return navigate(SIGNIN_ROUTE);
          }
          error?.response?.data?.error
            ? toast.error(error?.response?.data?.error)
            : toast.error("An error occurred");
        }
      }
    },
  });

  useEffect(() => {
    if (newPhoto) {
      updateCredentials();
    }
  }, [newPhoto]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          py: 1,
        }}
      >
        <Box
          component="label"
          sx={{
            fontWeight: 500,
            display: "block",
            color: `#454E68`,
          }}
          htmlFor="selfie"
        >
          {"Upload selfie (< 1mb)"}
        </Box>
        <Box
          sx={{
            border: `1px solid gray`,
            padding: "10px 10px",
            borderRadius: "5px",
          }}
        >
          <input
            id="selfie"
            name="selfie"
            type="file"
            accept=".png, .jpeg, .jpg, image/png, image/jpeg"
            onChange={(event) => {
              setRunSelfieEndpoint(true);
              const files = event.target.files;
              if (files && files.length > 0) {
                formik.setFieldValue("selfie", files[0]);
              } else {
                formik.setFieldValue("selfie", null);
              }
            }}
          />
        </Box>
      </Box>

      <Stack
        direction="row"
        justifyContent={"flex-end"}
        sx={{
          marginTop: "20px",
        }}
      >
        <CustomButton
          type="submit"
          variant="outlined"
          color="secondary"
          sx={{
            marginRight: "10px",
            background: "white",
            border: `1px solid ${theme.palette.common.black}`,
            "&:hover": {
              background: "rgba(0, 0, 0, 0.05)",
            },
          }}
          onClick={handleClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!formik.dirty || !formik.isValid}
          sx={{
            background: "black",
            color: "white",
          }}
        >
          {formik.isSubmitting ? (
            <CircularProgress
              size={22}
              sx={{
                color: "common.white",
                margin: "0px 28px",
              }}
            />
          ) : (
            "Update Selfie"
          )}
        </CustomButton>
      </Stack>
    </form>
  );
}

export default UpdateSelfieForm;
