import React from "react";
import simone from "assets/images/loginAssets/simoneBigLogo.svg";
import {
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";

const FallBack = ({ resetErrorBoundary }: any) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      {matches && (
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Content only available on Desktop mode
        </Typography>
      )}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100vh"}
        sx={{ background: "#F5F6Fa" }}
      >
        <Stack
          direction={"column"}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            margin: "0 auto",
          }}
        >
          <Box
            component="img"
            alt="Dvault"
            src={simone}
            sx={{
              width: "180px",
              margin: "0 auto",
            }}
          />
          <Box width={"500px"} textAlign={"center"} mb={4}>
            <Typography
              variant="h1"
              sx={{ textAlign: "center", fontSize: "4rem", color: "#6B6B6B" }}
            >
              Oops!!!
            </Typography>
            <Typography
              variant="h3"
              sx={{
                lineHeight: "25px",
                marginTop: { md: "2rem" },
                fontSize: "1rem",
              }}
            >
              Something must have gone wrong. We apologize and we'll try to fix
              it. In the meantime, do retry.
            </Typography>
          </Box>
          <Box textAlign={"center"}>
            <CustomButton
              onClick={resetErrorBoundary}
              color="secondary"
              variant="contained"
              sx={{
                marginLeft: "10px",
                background: "black",
                color: "white",
                border: "1px solid black",
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.05)",
                  color: "black",
                },
              }}
            >
              Try again
            </CustomButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default FallBack;
