import React from "react";
import ReactDOM from "react-dom/client";
import App from "./containers/App";
import store from "./service/store/Store";
import {
  ToastContainer,
  CssBaseline,
  ThemeProvider,
  Provider,
} from "./components/common/ExternalComponents";
import "react-toastify/dist/ReactToastify.css";
import { theme } from "./utils/Theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <App />
      </ThemeProvider>
    </Provider>
  </>
);
