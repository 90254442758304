import React, { useEffect } from "react";
import { authFormLayoutStyles } from "assets/styles/auth/AuthStyles";
import success1 from "assets/images/loginAssets/success.svg";
import {
  Box,
  Grid,
  Typography,
  Formik,
  Form,
  useNavigate,
} from "components/common/ExternalComponents";
import { FormikControl } from "components/form/FormikControl";
import CustomButton from "components/buttons/Button";
import { FORGOT_PASSWORD_ENDPOINT } from "constants/Endpoints";
import { ENTER_OTP_ROUTE } from "constants/Routes";
import AuthFormLayout from "layouts/AuthFormLayout";
import AuthLayout from "layouts/AuthLayout";
import { useRequest } from "service/hook/RequestHook";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { useAppDispatch } from "service/hook/Hooks";
import {
  PHONE_NUMBER,
  phoneNumberValidator,
} from "utils/validators/AuthFormValidator";

type inputValue = { [key: string]: any };

const initialValues = {
  [PHONE_NUMBER]: "",
};

const ForgotPasswordPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const {
    state: { loading, success, data },
    makeRequest,
  } = useRequest();

  const onSubmit = (values: inputValue) => {
    makeRequest(FORGOT_PASSWORD_ENDPOINT, {
      body: {
        phone_number: values.phone_number.split(" ").join(""),
      },
      redirectOn401: false,
      bearer: false,
    });
    dispatch(globalLoadingAction(true));
  };

  useEffect(() => {
    success && dispatch(globalLoadingAction(false)) && setShowModal(true);
  }, [success]);

  return (
    <AuthLayout>
      {showModal ? (
        <Box sx={authFormLayoutStyles.container}>
          <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
              <Box
                component="img"
                src={success1}
                alt="simone logo"
                width="100px"
              />
            </Grid>
            <Grid item width="90%">
              <Box mb={4} textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#6B6B6B",
                    fontWeight: 600,
                    marginBottom: "20px",
                  }}
                >
                  Kindly check your phone
                </Typography>
                <Typography sx={{ fontWeight: 500 }}>
                  A One Time Password has been sent to the number provided.
                </Typography>
              </Box>

              <Box textAlign="center">
                <CustomButton
                  onClick={() => navigate(ENTER_OTP_ROUTE, { state: { data } })}
                >
                  Proceed
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <AuthFormLayout>
          <Formik
            initialValues={initialValues}
            validationSchema={phoneNumberValidator}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form placeholder={undefined}>
                  <Box mb={4} textAlign={"center"}>
                    <Typography
                      sx={{
                        color: "#6B6B6B",
                        fontWeight: 600,
                        marginBottom: "20px",
                      }}
                    >
                      Kindly enter your phone number
                    </Typography>
                    <Box
                      component={FormikControl}
                      control="telephone"
                      name={PHONE_NUMBER}
                      label="Enter phone number"
                      type="tel"
                      onChange={formik.handleChange("phone_number")}
                      value={formik?.values?.phone_number}
                    />
                  </Box>

                  <Box textAlign="center">
                    <CustomButton
                      type="submit"
                      disabled={!formik.dirty || !formik.isValid || loading}
                    >
                      Proceed
                    </CustomButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </AuthFormLayout>
      )}
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
