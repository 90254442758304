import React from "react";
import {
  Backdrop,
  CircularProgress,
} from "components/common/ExternalComponents";
import { useAppSelector } from "service/hook/Hooks";

const Loading = () => {
  const { loading } = useAppSelector((state) => state.globalSliceReducer);

  return (
    <Backdrop
      open={loading}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
export default Loading;
