import React, { useEffect } from "react";
import { useNavigate } from "components/common/ExternalComponents";
import CustomCardLink from "components/links/CardLinks";
import {
  SIGNIN_ROUTE,
  TERMINAL_DISTRIBUTION_ROUTE,
  TERMINAL_REQUEST_ROUTE,
} from "constants/Routes";
import { useAppSelector } from "service/hook/Hooks";

const AmsHomePage = () => {
  const { token } = useAppSelector((state) => state.profileSliceReducer);
  const navigate = useNavigate();

  useEffect(() => {
    !token && navigate(SIGNIN_ROUTE);
  }, [token]);

  return (
    <CustomCardLink
      linkArr={[TERMINAL_DISTRIBUTION_ROUTE, TERMINAL_REQUEST_ROUTE]}
    />
  );
};

export default AmsHomePage;
