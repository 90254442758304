import React from "react";
import NotFound from "components/error/NotFound";
import { Route, Routes as Router } from "components/common/ExternalComponents";
import {
  ASSET_ROUTE,
  ENTER_OTP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LANDING_ROUTE,
  PASSWORD_RESET_ROUTE,
  SIGNIN_ROUTE,
  TMS_ROUTE,
  MERCHANT_ROUTE,
  DVAULT_ROUTE,
  BALANCE_DETAILS_ROUTE,
  BALANCE_DETAILS_EDIT_ROUTE,
  PRD_ROUTE,
  PRD_DETAILS_ROUTE,
  MERCHANT_VERIFICATION_ROUTE,
  PAYMENT_MANAGEMENT_ROUTE,
  REVENUE_ROUTE,
  SOFTWARE_UPDATE_ROUTE,
  TERMINAL_DISTRIBUTION_ROUTE,
  TERMINAL_REQUEST_ROUTE,
} from "constants/Routes";
import AppLayout from "layouts/AppLayout";
import TMS from "pages/tms/Index";
import Signin from "pages/auth/SignIn";
import ForgotPassword from "pages/auth/ForgotPassword";
import EnterPassword from "pages/auth/EnterOtp";
import ResetPassword from "pages/auth/ResetPassword";
import Asset from "pages/ams/Index";
import DvaultHomePage from "pages/dvault/Index";
import Dashboard from "pages/dvault/merchantVerification/Dashboard";
import BalanceDetails from "pages/dvault/merchantVerification/BalanceDetails";
import PRD from "pages/tms/prd/Prd";
import PrdDetailedView from "pages/tms/prd/PrdDetailedView";
import EditBalanceDetails from "pages/dvault/merchantVerification/EditBalanceDetails";
import PaymentManagement from "pages/dvault/paymentManagement/PaymentManagement";
import Revenue from "pages/tms/revenue/Revenue";
import Merchant from "pages/tms/merchant/Merchants";
import SoftwareUpdate from "pages/tms/software_updates/SoftwareUpdate";
import TerminalDistribution from "pages/ams/terminalDistribution/TerminalDistribution";
import TerminalRequest from "pages/ams/trd/TerminalRequest";
import {
  DvaultRoles,
  TmsRoles,
  AmsRoles,
} from "service/helper/RoleFunctionsHelper";
import ProtectedAdminRoute from "utils/ProtectedAdminRoute";

const Routes = () => {
  return (
    <Router>
      <Route path={SIGNIN_ROUTE} element={<Signin />} />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
      <Route path={ENTER_OTP_ROUTE} element={<EnterPassword />} />
      <Route path={PASSWORD_RESET_ROUTE} element={<ResetPassword />} />

      <Route path={LANDING_ROUTE} element={<AppLayout />}>
        <Route element={<ProtectedAdminRoute allowedRoles={DvaultRoles} />}>
          <Route path={DVAULT_ROUTE}>
            <Route index element={<DvaultHomePage />} />
            <Route path={MERCHANT_VERIFICATION_ROUTE}>
              <Route index element={<Dashboard />} />
              <Route path={`${BALANCE_DETAILS_ROUTE}/:id`}>
                <Route index element={<BalanceDetails />} />
                <Route
                  path={BALANCE_DETAILS_EDIT_ROUTE}
                  element={<EditBalanceDetails />}
                />
              </Route>
            </Route>
            <Route path={PAYMENT_MANAGEMENT_ROUTE}>
              <Route index element={<PaymentManagement />} />
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedAdminRoute allowedRoles={TmsRoles} />}>
          <Route path={TMS_ROUTE}>
            <Route index element={<TMS />} />
            <Route path={PRD_ROUTE}>
              <Route index element={<PRD />} />
              <Route path={PRD_DETAILS_ROUTE} element={<PrdDetailedView />} />
            </Route>
            <Route path={REVENUE_ROUTE}>
              <Route index element={<Revenue />} />
            </Route>
            <Route path={MERCHANT_ROUTE}>
              <Route index element={<Merchant />} />
            </Route>
            <Route path={SOFTWARE_UPDATE_ROUTE}>
              <Route index element={<SoftwareUpdate />} />
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedAdminRoute allowedRoles={AmsRoles} />}>
          <Route path={ASSET_ROUTE}>
            <Route index element={<Asset />} />
            <Route path={TERMINAL_DISTRIBUTION_ROUTE}>
              <Route index element={<TerminalDistribution />} />
            </Route>
            <Route path={TERMINAL_REQUEST_ROUTE}>
              <Route index element={<TerminalRequest />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Router>
  );
};

export default Routes;
