import React, { ReactNode } from "react";
import {
  Box,
  Grid,
  Typography,
  NavLink,
} from "components/common/ExternalComponents";

interface CustomLinkProps {
  children?: ReactNode;
  text?: string;
  route: string;
  styles?: { [key: string]: any };
  image: any;
}

const CustomNavLink: React.FC<CustomLinkProps> = ({
  children,
  text,
  route,
  styles,
  image,
}) => (
  <NavLink
    to={route}
    style={{ color: "inherit", textDecoration: "none", ...styles }}
  >
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        my: 2,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        paddingLeft: "10px",
        borderRadius: "10px",
        border: "none",
      }}
    >
      <Grid item xs={10} sx={{ display: "flex", border: "none" }}>
        <Box component={"img"} src={image} />
        <Typography
          variant="body1"
          sx={{
            fontSize: "24px",
            color: "#C0C0C6",
            paddingLeft: (text || children) === "TMS" ? "20px" : "10px",
          }}
        >
          {text || children}
        </Typography>
      </Grid>
    </Grid>
  </NavLink>
);
export default CustomNavLink;
