export const authLayoutStyles = {
  container: {
    height: '100vh',
    p: '1rem',
  },
  leftCard: {
    width: '100%',
    borderRadius: '10px',
    p: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const authFormLayoutStyles = {
  container: {
    width: '80%',
    m: 'auto',
    alignItems: 'center',
  },
};
