import React from "react";
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  Theme,
} from "components/common/ExternalComponents";
import CustomChip from "components/chips/TableChips";
import TableFilterButton from "components/buttons/filter/TableFilterButton";
import { useAppDispatch } from "service/hook/Hooks";
import { changePageNumber } from "service/store/UserProfileSlice";

interface Column {
  id: string;
  label: string;
  minWidth: number;
  align: "left" | "center" | "right";
}

const columns: Column[] = [
  {
    id: "dateOfRegistration",
    label: "Date/Time",
    minWidth: 130,
    align: "center",
  },
  { id: "sender", label: "Sender", minWidth: 250, align: "center" },
  {
    id: "wallet_balance_before",
    label: "Balance before",
    minWidth: 170,
    align: "center",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 150,
    align: "center",
  },
  { id: "charges_amount", label: "Charges", minWidth: 150, align: "center" },
  {
    id: "wallet_balance_after",
    label: "Balance after",
    minWidth: 150,
    align: "center",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
    align: "center",
  },
  { id: "type", label: "Cash flow", minWidth: 150, align: "center" },
  {
    id: "payment_method",
    label: "Method",
    minWidth: 150,
    align: "center",
  },
  {
    id: "terminal_id",
    label: "Terminal ID",
    minWidth: 150,
    align: "center",
  },
  {
    id: "card_number",
    label: "Maxed number",
    minWidth: 170,
    align: "center",
  },
  {
    id: "reference_number",
    label: "RRN",
    minWidth: 150,
    align: "center",
  },
  { id: "status", label: "Status", minWidth: 150, align: "center" },
];

type PrdHistoryTableProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  [key: string]: any;
};

const PrdHistoryTable = ({
  page,
  setPage,
  pageNumber,
  rowsPerPage,
  setRowsPerPage,
  setDownloadStatusFilter,
  setDownloadTypeFilter,
  setDownloadMethodFilter,
  totalCount,
  allTransactions,
  setStatus,
  setPaymentMethod,
  setTransactionType,
}: PrdHistoryTableProps) => {
  const theme: Theme = useTheme();
  const dispatch = useAppDispatch();

  let filterFunc = (column: Column, popoverId: string) => {
    switch (column.id) {
      case "type":
        return (
          <TableFilterButton
            text={"Cash flow"}
            setPage={setPage}
            popoverId={popoverId}
            options={["Inflow", "Outflow", "All"]}
            setStatus={setStatus}
            setTransactionType={setTransactionType}
            setPaymentMethod={setPaymentMethod}
            setDownloadTypeFilter={setDownloadTypeFilter}
            setDownloadMethodFilter={setDownloadMethodFilter}
            setDownloadStatusFilter={setDownloadStatusFilter}
          />
        );
      case "payment_method":
        return (
          <TableFilterButton
            text={"Method"}
            setPage={setPage}
            popoverId={popoverId}
            options={["Card", "Transfer", "Balance", "All"]}
            setStatus={setStatus}
            setTransactionType={setTransactionType}
            setPaymentMethod={setPaymentMethod}
            setDownloadTypeFilter={setDownloadTypeFilter}
            setDownloadMethodFilter={setDownloadMethodFilter}
            setDownloadStatusFilter={setDownloadStatusFilter}
          />
        );
      case "status":
        return (
          <TableFilterButton
            text={"Status"}
            setPage={setPage}
            popoverId={popoverId}
            options={["Successful", "Unsuccessful", "Pending", "All"]}
            setStatus={setStatus}
            setTransactionType={setTransactionType}
            setPaymentMethod={setPaymentMethod}
            setDownloadTypeFilter={setDownloadTypeFilter}
            setDownloadMethodFilter={setDownloadMethodFilter}
            setDownloadStatusFilter={setDownloadStatusFilter}
          />
        );
      default:
        return (
          <Button
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#EFF1F9",
              color: theme.palette.common.greyColor,
              fontWeight: "600",
            }}
          >
            {column.label}
          </Button>
        );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    // setPageNumber(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    // setPage(0);
    setPage(pageNumber);
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        {allTransactions && (
          <>
            <TableContainer
              component={Paper}
              sx={{
                px: 4,
                py: 5,
                borderRadius: "13px",
                border: "1px solid #E8EBEE",
                paddingBottom: 6,
                boxShadow: `0px 10px 20px 5px ${theme.palette.common.background}`,
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                        color: theme.palette.common.greyColor,
                        fontWeight: "600",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const popoverId = `${column.id}-popover`;
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {filterFunc(column, popoverId)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTransactions.map((row: any, index: number) => {
                    return (
                      <TableRow key={index} hover>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              sx={{
                                color: theme.palette.common.tableBodyColor,
                                fontWeight: "400",
                                lineHeight: "24px",
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {value === "CREDIT" ? (
                                <CustomChip tag={"Inflow"} />
                              ) : value === "DEBIT" ? (
                                <CustomChip tag={"Outflow"} />
                              ) : value === "CARD" ? (
                                <CustomChip filterBy={"Card"} />
                              ) : value === "BALANCE" ? (
                                <CustomChip filterBy={"Balance"} />
                              ) : value === "BANK_TRANSFER" ? (
                                <CustomChip filterBy={"Transfer"} />
                              ) : value === "SUCCESS" ? (
                                <CustomChip filterBy={"Successful"} />
                              ) : value === "FAILED" ? (
                                <CustomChip filterBy={"Unsuccessful"} />
                              ) : value === "INITIALIZED" ? (
                                <CustomChip filterBy={"Pending"} />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default PrdHistoryTable;
