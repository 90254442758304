import React from "react";
import { Box, Stack } from "components/common/ExternalComponents";
import CustomLink from "components/links/Link";
import { displayImage } from "utils/DisplayImage";

const CustomCardLink = ({ linkArr }: any | string[]) => {
  return (
    <Stack
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        marginTop: "30px",
      }}
    >
      <Stack
        direction="row"
        width={"52%"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ rowGap: "30px" }}
      >
        {linkArr.map((item: any, index: number) => {
          return (
            <CustomLink key={index} route={item}>
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "-2px 0px 15px 4px rgba(140,161,182, 0.37)",
                    borderRadius: "23px",
                    height: "225px",
                  },
                }}
              >
                <Box
                  component="img"
                  sx={{ width: "250px" }}
                  src={displayImage(item)}
                />
              </Box>
            </CustomLink>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default CustomCardLink;
