import axios from "axios";
import { getItem } from "service/helper/StorageHelper";
import { userProfileStorageName } from "service/store/UserProfileSlice";

export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const customFetch = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a request interceptor to add the authorization header to each request
customFetch.interceptors.request.use(
  (config: any) => {
    config.headers["Authorization"] =
      getItem(userProfileStorageName) === undefined
        ? ""
        : `Bearer ${getItem(userProfileStorageName)?.token}`;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
