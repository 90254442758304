import React from "react";
import { Box, Stack } from "components/common/ExternalComponents";
import CustomNavDropdown from "components/dropdown/CustomNavDropdown";
import Breadcrumbs from "components/nav/BreadCrumb";
import { theme } from "utils/Theme";

const Navbar = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        px: 6,
        py: 2,
        background: theme.palette.common.white,
      }}
    >
      <Box component={"span"}>
        <Breadcrumbs />
      </Box>
      <CustomNavDropdown />
    </Stack>
  );
};

export default Navbar;
