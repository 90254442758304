import Group1 from "assets/images/tmsAssets/group1.svg";
import Group2 from "assets/images/tmsAssets/group2.svg";
import Group3 from "assets/images/tmsAssets/group3.svg";
import Group4 from "assets/images/tmsAssets/group4.svg";
import dvaultImage1 from "assets/images/dvaultAssets/dvaultImage1.svg";
import dvaultImage2 from "assets/images/dvaultAssets/dvaultImage2.svg";
import trd from "assets/images/amsAssets/trd.svg";
import terminalDistribution from "assets/images/amsAssets/terminalDistribution.svg";
import {
  PRD_ROUTE,
  REVENUE_ROUTE,
  MERCHANT_ROUTE,
  SOFTWARE_UPDATE_ROUTE,
  MERCHANT_VERIFICATION_ROUTE,
  PAYMENT_MANAGEMENT_ROUTE,
  TERMINAL_REQUEST_ROUTE,
  TERMINAL_DISTRIBUTION_ROUTE,
} from "constants/Routes";

export const displayImage = (item: string) => {
  switch (item) {
    case PRD_ROUTE:
      return Group1;
    case REVENUE_ROUTE:
      return Group2;
    case MERCHANT_ROUTE:
      return Group3;
    case SOFTWARE_UPDATE_ROUTE:
      return Group4;
    case MERCHANT_VERIFICATION_ROUTE:
      return dvaultImage1;
    case PAYMENT_MANAGEMENT_ROUTE:
      return dvaultImage2;
    case TERMINAL_REQUEST_ROUTE:
      return trd;
    case TERMINAL_DISTRIBUTION_ROUTE:
      return terminalDistribution;
    default:
      return Group1;
  }
};
