import React, { useEffect, useState } from "react";
import FrontID from "assets/images/dvaultAssets/frontID.jpeg";
import BackID from "assets/images/dvaultAssets/backID.jpeg";
import CustomButton from "components/buttons/Button";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  Close,
  ArrowDropDown,
  Popover,
  ListItemButton,
  List,
  MenuItem,
} from "components/common/ExternalComponents";
import UpdateIDform from "pages/dvault/merchantVerification/UpdateIdForm";
import { IdCard } from "utils/types/CommonTypes";
import CustomChip from "components/chips/Chip";
import DvaultModal from "components/modals/DvaultModal";

interface IdDetailsProps {
  id_cards: IdCard[];
  [key: string]: any;
}

function IDdetails({ id_cards, id: ID }: IdDetailsProps) {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElBtn, setAnchorElBtn] = useState(null);
  const [idCard, setIdCard] = useState(id_cards[0]);
  const [idCardId, setIdCardId] = useState("");

  const openPopover = Boolean(anchorEl);
  const openPopoverBtn = Boolean(anchorElBtn);
  const id = openPopover ? "status-popover" : undefined;
  const idBtn = openPopoverBtn ? "btn-popover" : undefined;

  const handleStatusClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleStatusClickBtn = (e: any) => {
    setAnchorElBtn(e.currentTarget);
  };

  const handleStatusCloseBtn = () => {
    setAnchorElBtn(null);
  };

  const handleInfoClickOpen = () => {
    setOpen(true);
  };

  const handleInfoClose = () => {
    setOpen(false);
  };

  const handleStatusClickOpen = () => {
    setOpenStatus(true);
  };

  const idDetailsArr = [
    {
      text: "Front of ID",
      src: idCard?.front_id_photo || FrontID,
      alt: "frontID",
    },
    {
      text: "Back of ID",
      src: idCard?.back_id_photo || BackID,
      alt: "backID",
    },
  ];

  useEffect(() => {
    if (id_cards.length >= 1) {
      setIdCard(id_cards[0]);
      setIdCardId(id_cards[0]?.id);
    }
  }, [id_cards]);

  return (
    <Paper sx={{ padding: "30px" }}>
      <Grid container justifyContent="space-between">
        <Grid item xs={3}>
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                fontSize: "2rem",
                marginBottom: "30px",
              }}
            >
              ID Details
            </Typography>
            <Box
              sx={{
                marginBottom: "30px",
              }}
            >
              <Box
                component="span"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  marginRight: "10px",
                  fontWeight: 500,
                }}
              >
                ID Type:
              </Box>
              <Box
                component="span"
                sx={{
                  fontWeight: 500,
                }}
              >
                {idCard?.type}
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "30px",
              }}
            >
              <Box
                component="span"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  marginRight: "10px",
                  fontWeight: 500,
                }}
              >
                ID Number:
              </Box>
              <Box
                component="span"
                sx={{
                  fontWeight: 500,
                }}
              >
                {idCard?.number}
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "30px",
              }}
            >
              <Box
                component="span"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  marginRight: "10px",
                  fontWeight: 500,
                }}
              >
                No of ID submitted:
              </Box>
              <Box
                component="span"
                sx={{
                  fontWeight: 500,
                }}
              >
                {id_cards.length}
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "30px",
              }}
            >
              <Box
                component="span"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  marginRight: "10px",
                  fontWeight: 500,
                }}
              >
                Status:
              </Box>
              <Box
                component="span"
                sx={{
                  fontWeight: 500,
                }}
              >
                <CustomChip label={idCard?.verification_status} />
              </Box>
            </Box>
            <Stack
              direction="column"
              width={"100%"}
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <DvaultModal
                status={idCard?.verification_status}
                kycID={idCardId}
                setAnchorEl={setAnchorEl}
                setOpenStatus={setOpenStatus}
                openStatus={openStatus}
              />

              {id_cards.length > 1 && (
                <CustomButton
                  variant="outlined"
                  endIcon={<ArrowDropDown />}
                  aria-describedby={idBtn}
                  onClick={handleStatusClickBtn}
                  color="secondary"
                  sx={{
                    textTransform: "capitalize",
                    mb: "20px",
                  }}
                >
                  Select ID
                </CustomButton>
              )}

              <CustomButton
                variant="outlined"
                endIcon={<ArrowDropDown />}
                aria-describedby={id}
                onClick={handleStatusClick}
                color="secondary"
                sx={{
                  textTransform: "capitalize",
                  mb: "20px",
                }}
              >
                Change ID Status
              </CustomButton>

              <CustomButton
                variant="outlined"
                color="secondary"
                sx={{
                  textTransform: "capitalize",
                  mb: "20px",
                }}
                onClick={handleInfoClickOpen}
              >
                Update ID information
              </CustomButton>
            </Stack>

            <Popover
              id={idBtn}
              open={openPopoverBtn}
              anchorEl={anchorElBtn}
              onClose={handleStatusCloseBtn}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                sx={{
                  width: "180px",
                }}
              >
                {id_cards.length >= 1 &&
                  id_cards.map((element, index) => {
                    return (
                      <MenuItem
                        key={element?.number}
                        onClick={() => {
                          setIdCard(id_cards[index]);
                          setIdCardId(element?.id);
                          handleStatusCloseBtn();
                        }}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {element?.type}
                      </MenuItem>
                    );
                  })}
              </Box>
            </Popover>

            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleStatusClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                sx={{
                  width: "165px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <List>
                  {idCard?.verification_status === "APPROVED" ? (
                    <ListItemButton onClick={() => handleStatusClickOpen()}>
                      <CustomChip label="REJECTED" />
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={() => handleStatusClickOpen()}>
                      <CustomChip label="APPROVED" />
                    </ListItemButton>
                  )}
                </List>
              </Box>
            </Popover>

            <Dialog
              open={open}
              onClose={handleInfoClose}
              PaperProps={{
                sx: {
                  borderRadius: "16px",
                },
              }}
            >
              <Paper
                sx={{
                  width: "500px",
                  p: 4,
                }}
              >
                <Close
                  sx={{
                    display: "block",
                    fontSize: "2.5rem",
                    marginLeft: "auto",
                    color: `${theme.palette.common.lightBlue}`,
                  }}
                  onClick={handleInfoClose}
                />
                <Typography
                  variant="h4"
                  color="initial"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Update ID information
                </Typography>
                <DialogContent
                  sx={{
                    "&.MuiDialogContent-root": {
                      padding: 0,
                    },
                  }}
                >
                  <Box>
                    <UpdateIDform
                      handleClose={handleInfoClose}
                      kycID={idCard?.id}
                      number={idCard?.number}
                      type={idCard?.type}
                      backID={idCard?.back_id_photo}
                      frontID={idCard?.front_id_photo}
                      id_cards={id_cards}
                      ID={ID}
                    />
                  </Box>
                </DialogContent>
              </Paper>
            </Dialog>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Stack direction={"column"} alignItems={"flex-end"}>
            {idDetailsArr.map((element, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "550px",
                    marginBottom: "40px",
                  }}
                >
                  <Box
                    component="img"
                    src={element.src}
                    alt={element.alt}
                    sx={{
                      display: "block",
                      width: "100%",
                      height: "350px",
                      objectFit: "fill",
                      overFlow: "hidden",
                      borderRadius: "30px",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "right",
                      color: `${theme.palette.common.lightBlue}`,
                      fontWeight: 500,
                      my: 1,
                    }}
                  >
                    {element.text}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default IDdetails;
