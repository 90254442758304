import React, { useState } from "react";
import down from "assets/images/amsAssets/down.svg";
import up from "assets/images/amsAssets/up.svg";
import CustomChip from "components/chips/Chip";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "components/common/ExternalComponents";
import TerminalModal from "components/modals/TerminalModal";
import UpdateTerminal from "pages/ams/terminalDistribution/UpdateTerminal";

interface RowProps {
  element: { [key: string]: any };
  columns: any[];
  index: number;
  columnsExtra: any[];
  currentID: React.MutableRefObject<string>;
  [key: string]: any;
}

const Row = ({
  element,
  columns,
  index,
  columnsExtra,
  currentID,
  updateTerminalList,
  setUpdateTerminalList,
}: RowProps) => {
  const theme = useTheme();
  const [openTableDetails, setOpenTableDetails] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleInfoClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <TableRow
        tabIndex={-1}
        key={element.account_name + element.name + index}
        onClick={() => {
          currentID.current = element.tid;
        }}
      >
        {columns.map((column: any) => {
          const value = element[column.id];
          return (
            <TableCell
              sx={{
                color: theme.palette.common.tableBodyColor,
                fontWeight: "400",
                lineHeight: "24px",
              }}
              key={column.id}
              align={column.align}
            >
              {column.id === "sn" ? (
                <IconButton
                  aria-label="expand row"
                  size="large"
                  onClick={() => setOpenTableDetails(!openTableDetails)}
                >
                  {openTableDetails ? (
                    <Box component={"img"} src={down} />
                  ) : (
                    <Box component={"img"} src={up} />
                  )}
                </IconButton>
              ) : column.id === "is_disabled" ? (
                value ? (
                  <CustomChip disabled={true} />
                ) : (
                  <CustomChip disabled={false} />
                )
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={openTableDetails} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="more table details">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: `${theme.palette.common.prdT}`,
                    }}
                  >
                    {columnsExtra.map((column: any) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: `${theme.palette.common.white}`,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {columnsExtra.map((column) => {
                      const value = element.extraDetails[0][column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <TerminalModal
                              text={"Link Terminal"}
                              openDialog={openDialog}
                              setOpenDialog={setOpenDialog}
                              children={
                                <UpdateTerminal
                                  element={element}
                                  handleInfoClose={handleInfoClose}
                                  setUpdateTerminalList={setUpdateTerminalList}
                                  updateTerminalList={updateTerminalList}
                                />
                              }
                            />
                          ) : value === null || value === "" ? (
                            " - "
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
