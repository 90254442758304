import { Yup } from "components/common/ExternalComponents";

export const createOrUpdateInitialValues = {
  terminalID: "",
  serialNumber: "",
  name: "",
};

export const outForDeliveryInitialValues = {
  terminalSerialNumber: "",
};

export const deliveredInitialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  terminalSerialnumber: "",
};

export const createTerminalValidator = Yup.object().shape({
  terminalID: Yup.string().required("This field is required"),
  serialNumber: Yup.string(),
  wallet: Yup.string(),
});

export const updateTerminalValidator = Yup.object().shape({
  terminalID: Yup.string(),
  serialNumber: Yup.string(),
  wallet: Yup.string().required("Please select a wallet you would link to"),
});

export const outForDeliveryTerminalValidator = Yup.object().shape({
  terminalSerialNumber: Yup.string().required("Required"),
});

export const deliveredTerminalValidator = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[a-zA-Z]+$/,
      "First name of recipient should contain only letters"
    )
    .required("Required"),
  lastName: Yup.string().matches(
    /^[a-zA-Z]+$/,
    "Last name of recipient should contain only letters"
  ),
  phoneNumber: Yup.string()
    .matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, "Not a valid number")
    .required("Required"),
});
