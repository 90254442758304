import React from "react";
import {
  Close,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Typography,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { theme } from "utils/Theme";
import { ModalProps } from "utils/types/CommonTypes";

const TerminalModal = ({
  element,
  children,
  text,
  openDialog,
  setOpenDialog,
}: ModalProps) => {
  return (
    <>
      <CustomButton
        color="secondary"
        variant="contained"
        sx={{
          fontWeight: 400,
        }}
        onClick={() => setOpenDialog(true)}
        disabled={element?.is_disabled}
      >
        {text}
      </CustomButton>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <Paper
          sx={{
            width: "500px",
            p: 4,
          }}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Typography
              variant="h4"
              color="initial"
              sx={{
                fontWeight: 500,
                fontSize: "24px",
              }}
            >
              {text}
            </Typography>{" "}
            <Close
              sx={{
                fontSize: "2rem",
                color: `${theme.palette.common.lightBlue}`,
              }}
              onClick={() => setOpenDialog(false)}
            />
          </Stack>

          <DialogContent
            sx={{
              "&.MuiDialogContent-root": {
                padding: 0,
              },
            }}
          >
            {children}
          </DialogContent>
        </Paper>
      </Dialog>
    </>
  );
};

export default TerminalModal;
