import { ReactNode } from "react";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align: "left" | "right" | "center";
}

export interface IndicatorData {
  id?: number;
  image?: any;
  total?: string;
  bgColor?: string;
  text: string;
  src?: any;
  shadow?: string;
}

export interface DashboardData {
  phone_number: number;
  created_at: string;
  id: string;
  id_cards: { is_verified: boolean };
  email: string;
  first_name: string;
  last_name: string;
  tier?: number;
}

export type IdCard = {
  type: string;
  number: string;
  id: string;
  is_verified: boolean;
  front_id_photo: null | string;
  back_id_photo: null | string;
  created_at: string;
  [key: string]: any;
};

export interface DateRange {
  startDate: any;
  endDate: any;
  key: string;
}

export function createData(
  sn?: any,
  fullName?: string,
  email?: string,
  phoneNumber?: number,
  tier?: number,
  dateOfRegistration?: string,
  kycID?: string
) {
  return {
    sn,
    fullName,
    email,
    phoneNumber,
    tier,
    dateOfRegistration,
    kycID,
  };
}

export interface DownloadData {
  url: string;
  dateRange: { [key: string]: any };
  localSearch: string;
  idCardIsVerified?: boolean;
  platformId?: string;
  terminalID?: string;
  walletID?: string;
  downloadMethodFilter?: string;
  downloadStatusFilter?: string;
  downloadTypeFilter?: string;
}

export interface SearchData {
  setPage: any;
  setLocalSearch: any;
  localSearch: string;
  border?: any;
  border2?: string | any;
  height2?: any;
  onChange?: any;
}

export type FilterOptionProps = {
  options: string[];
  handleChange?: any;
  popoverId?: string;
};

export type FilterProps = {
  setPage?: any;
  setFilter?: any;
  options: string[];
  setOpenWalletDialog?: any;
  setOpenTerminalDialog?: any;
  setWalletOrTerminal?: any;
};

export interface ChipProps {
  label?: string;
  tag?: "Inflow" | "Outflow";
  filterBy?:
    | "Successful"
    | "Unsuccessful"
    | "Pending"
    | "Balance"
    | "Transfer"
    | "Card";
  disabled?: boolean;
  onClick?: any;
}

export interface PlatformData {
  allocated_wallets: number;
  created_at: string;
  email: string;
  id: any;
  name: string;
  platformkey: { [key: string]: any };
  staff: string[];
  updated_at: string;
  wallet: null;
}

export interface PlatformListProps {
  setPlatformId?: React.Dispatch<React.SetStateAction<string>> | any;
  anchorEl?: any;
  setAnchorEl?: any;
  open?: any;
  handleClosePlatform?: any;
  list?: any;
  platformId?: string;
}

export interface ModalProps {
  setPage?: any;
  setOpenDialog?: any;
  setID?: any;
  setAnchorElFilter?: any;
  openDialog?: any;
  url?: any;
  text?: string;
  localSearch?: string | any;
  setLocalSearch?: any;
  children?: ReactNode;
  element?: any;
  formik?: any;
  options?: any[];
  handleChange?: any;
  setWalletOrTerminal?: any;
}

export interface RequestProps {
  id: string;
  merchant_address: string;
  merchant_business_category: string;
  merchant_business_name: string;
  merchant_name: string;
  merchant_phone_number: string;
  referral_code: string;
  status: string;
  terminal_serial_no: string;
  updated_at: string;
  updated_by: string;
  created_at: string;
  [key: string]: any;
}

export interface PrdData {
  dateOfRegistration: string;
  sender: string;
  amount: string;
  charges_amount: any;
  type: string;
  payment_method: string;
  description: string;
  terminal_id: string;
  card_number: string;
  reference_number: string;
  status: string;
  wallet_balance_after: any;
  wallet_balance_before: any;
}
