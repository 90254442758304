import React, { useEffect, useState } from "react";
import { Box, useNavigate, toast } from "components/common/ExternalComponents";
import inflow from "assets/images/tmsAssets/inflow.svg";
import transfer from "assets/images/tmsAssets/transfer.svg";
import PrdIndicatorCard from "pages/tms/prd/PrdIndicatorCard";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { SIGNIN_ROUTE } from "constants/Routes";
import { TRANSACTION_CHARGES_ENDPOINT } from "constants/Endpoints";
import { IndicatorData } from "utils/types/CommonTypes";
import { dateConverter2, formatter } from "utils/Helpers";

export default function PrdDetailedViewIndicators({
  terminalID,
  dateRange,
  totalCount,
  walletTotal,
}: any) {
  const [indicatorsData, setIndicatorsData] = useState<IndicatorData[] | null>(
    []
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let url = `${TRANSACTION_CHARGES_ENDPOINT}?terminal_id=${terminalID}`;

  const getMetrics = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }
      const resp = await customFetch.get(url);
      setIndicatorsData([
        {
          id: 1,
          image: transfer,
          total: resp?.data?.charge_volume,
          text: "Total transaction charge volume",
          shadow: "",
        },
        {
          id: 2,
          image: inflow,
          total: formatter.format(resp?.data?.charges_value),
          shadow: "",
          text: "Total transaction charge value",
        },
        {
          id: 3,
          image: transfer,
          total: totalCount,
          text: "Total transaction count",
          shadow: "",
        },
        {
          id: 4,
          image: inflow,
          total: formatter.format(walletTotal?.total_balance),
          text: "Total transaction balance",
          shadow: "",
        },
      ]);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
      dispatch(globalLoadingAction(false));
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getMetrics();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [terminalID, dateRange, totalCount, walletTotal]);

  return (
    <Box sx={{ paddingTop: "20px", width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          overflow: "scroll",
        }}
      >
        {indicatorsData &&
          indicatorsData.map((data) => {
            const { id, image, total, text } = data;
            return (
              <Box key={id}>
                <PrdIndicatorCard
                  src={image}
                  total={total}
                  text={text}
                  shadow="0px 202.825px 490.554px rgba(120, 120, 168, 0.17)"
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
