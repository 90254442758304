import React from "react";
import { TextError } from "components/form/TextError";
import {
  FormControl,
  TextField,
  FormLabel,
  Typography,
  MenuItem,
  ErrorMessage,
} from "components/common/ExternalComponents";

interface InputProp {
  [key: string]: any;
}

export const Select = (props: InputProp) => {
  const {
    label,
    name,
    type,
    error,
    meta,
    field,
    form,
    background,
    required,
    helperText,
    maxLength,
    defaultValue,
    value,
    options,
    ...rest
  } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        "& .css-13nvzlm-MuiFormHelperText-root": {
          fontSize: "13px",
          ml: 0,
        },
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          color: "black",
          fontWeight: 500,
          paddingBottom: "10px",
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <TextField
        id={props.name}
        type={type || "text"}
        name={name}
        helperText={helperText}
        value={value}
        {...field}
        {...rest}
        SelectProps={{
          MenuProps: {
            sx: { maxHeight: "50%" },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "35px",
            width: "400px",
            color: "black",
            fontSize: "14px",
            background: `${background || "#F5f5f5"}`,
            ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
        select
      >
        {options.map((option: any) => {
          return (
            <MenuItem key={option.key} value={option.value}>
              {option.key}
            </MenuItem>
          );
        })}
      </TextField>
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
