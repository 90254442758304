import React from "react";
import { Box } from "components/common/ExternalComponents";

interface ErrorProp {
  [key: string]: any;
}

export const TextError = (props: ErrorProp) => {
  return (
    <Box sx={{ color: "red", fontSize: "14px", mt: 1 }}>{props.children}</Box>
  );
};
