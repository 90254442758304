import React from "react";
import { Chip, useTheme } from "components/common/ExternalComponents";
import { ChipProps } from "utils/types/CommonTypes";

function CustomChip({ label, disabled, onClick }: ChipProps) {
  const theme = useTheme();

  switch (label || disabled) {
    case "unverified":
    case "PENDING":
    case "By Terminal":
    case true:
      return (
        <Chip
          onClick={label === "By Terminal" ? onClick : undefined}
          label={
            disabled === true
              ? "Inactive"
              : label === "unverified"
              ? "unverified"
              : label === "By Terminal" ? "By Terminal" : "pending"
          }
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.purple}`,
              color: `${theme.palette.common.purpleIcon}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "verified":
    case "APPROVED":
    case false:
      return (
        <Chip
          label={
            disabled === false
              ? "Active"
              : label === "verified"
              ? "verified"
              : "approved"
          }
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.green}`,
              color: `${theme.palette.common.greenIcon}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "REJECTED":
    case "flagged":
      return (
        <Chip
          label={label === "flagged" ? "flagged" : "rejected"}
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.flagRed}`,
              color: `${theme.palette.common.flagIcon}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "By Wallet":
      return (
        <Chip
          onClick={label === "By Wallet" ? onClick : undefined}
          label={"By Wallet"}
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.prdPur}`,
              color: `${theme.palette.common.prdT}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "New request":
      return (
        <Chip
          label="New request"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.purple}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Cancelled":
      return (
        <Chip
          label="Cancelled"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.red}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Out for delivery":
      return (
        <Chip
          label="Out for delivery"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.yellow}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Delivered":
      return (
        <Chip
          label="Delivered"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.green}`,
              color: `${theme.palette.common.darkGreen}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Returned":
      return (
        <Chip
          label="Returned"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.grey}`,
              color: "white",
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    default:
      return (
        <Chip
          label={label === "All" ? "All" : "Reset"}
          sx={{
            "&.MuiChip-root": {
              color: `${theme.palette.common.black}`,
              fontWeight: 600,
              marginLeft: "auto",
              marginRight: "auto",
              padding: "0px 10px",
            },
          }}
        />
      );
  }
}

export default CustomChip;
