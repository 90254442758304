export const STATE_OF_ORIGIN = [
  {value:"AB", key:"Abia"},
  {value:"AD", key:"Adamawa"},
  {value:"AK", key:"Akwa Ibom"},
  {value:"AN", key:"Anambra"},
  {value:"BA", key:"Bauchi"},
  {value:"BE", key:"Benue"},
  {value:"BO", key:"Borno"},
  {value:"BY", key:"Bayelsa"},
  {value:"CR", key:"Cross River"},
  {value:"DE", key:"Delta"},
  {value:"EB", key:"Ebonyi"},
  {value:"ED", key:"Edo"},
  {value:"EK", key:"Ekiti"},
  {value:"EN", key:"Enugu"},
  {value:"FC", key:"Federal Capital Territory"},
  {value:"GO", key:"Gombe"},
  {value:"JI", key:"Jigawa"},
  {value:"IM", key:"Imo"},
  {value:"KD", key:"Kaduna"},
  {value:"KE", key:"Kebbi"},
  {value:"KN", key:"Kano"},
  {value:"KO", key:"Kogi"},
  {value:"LA", key:"Lagos"},
  {value:"KT", key:"Katsina"},
  {value:"KW", key:"Kwara"},
  {value:"NA", key:"Nasarawa"},
  {value:"NI", key:"Niger"},
  {value:"OG", key:"Ogun"},
  {value:"ON", key:"Ondo"},
  {value:"RI", key:"Rivers"},
  {value:"YO", key:"Oyo"},
  {value:"OS", key:"Osun"},
  {value:"SO", key:"Sokoto"},
  {value:"PL", key:"Plateau"},
  {value:"TA", key:"Taraba"},
  {value:"ZA", key:"Zamfara"},
];

export const countries = [
  {value:"AF", key:"Afghanistan"},
  {value:"AX", key:"Åland Islands"},
  {value:"AL", key:"Albania"},
  {value:"DZ", key:"Algeria"},
  {value:"AS", key:"American Samoa"},
  {value:"AD", key:"Andorra"},
  {value:"AO", key:"Angola"},
  {value:"AI", key:"Anguilla"},
  {value:"AQ", key:"Antarctica"},
  {value:"AG", key:"Antigua and Barbuda"},
  {value:"AR", key:"Argentina"},
  {value:"AM", key:"Armenia"},
  {value:"AW", key:"Aruba"},
  {value:"AU", key:"Australia"},
  {value:"AT", key:"Austria"},
  {value:"AZ", key:"Azerbaijan"},
  {value:"BS", key:"Bahamas"},
  {value:"BH", key:"Bahrain"},
  {value:"BD", key:"Bangladesh"},
  {value:"BB", key:"Barbados"},
  {value:"BY", key:"Belarus"},
  {value:"BE", key:"Belgium"},
  {value:"BZ", key:"Belize"},
  {value:"BJ", key:"Benin"},
  {value:"BM", key:"Bermuda"},
  {value:"BT", key:"Bhutan"},
  {value:"BO", key:"Bolivia"},
  {value:"BQ", key:"Bonaire, Sint Eustatius and Saba"},
  {value:"BA", key:"Bosnia and Herzegovina"},
  {value:"BW", key:"Botswana"},
  {value:"BV", key:"Bouvet Island"},
  {value:"BR", key:"Brazil"},
  {value:"IO", key:"British Indian Ocean Territory"},
  {value:"BN", key:"Brunei"},
  {value:"BG", key:"Bulgaria"},
  {value:"BF", key:"Burkina Faso"},
  {value:"BI", key:"Burundi"},
  {value:"CV", key:"Cabo Verde"},
  {value:"KH", key:"Cambodia"},
  {value:"CM", key:"Cameroon"},
  {value:"CA", key:"Canada"},
  {value:"KY", key:"Cayman Islands"},
  {value:"CF", key:"Central African Republic"},
  {value:"TD", key:"Chad"},
  {value:"CL", key:"Chile"},
  {value:"CN", key:"China"},
  {value:"CX", key:"Christmas Island"},
  {value:"CC", key:"Cocos (Keeling) Islands"},
  {value:"CO", key:"Colombia"},
  {value:"KM", key:"Comoros"},
  {value:"CG", key:"Congo"},
  {value:"CD", key:"Congo (the Democratic Republic of the)"},
  {value:"CK", key:"Cook Islands"},
  {value:"CR", key:"Costa Rica"},
  {value:"CI", key:"Côte d'Ivoire"},
  {value:"HR", key:"Croatia"},
  {value:"CU", key:"Cuba"},
  {value:"CW", key:"Curaçao"},
  {value:"CY", key:"Cyprus"},
  {value:"CZ", key:"Czechia"},
  {value:"DK", key:"Denmark"},
  {value:"DJ", key:"Djibouti"},
  {value:"DM", key:"Dominica"},
  {value:"DO", key:"Dominican Republic"},
  {value:"EC", key:"Ecuador"},
  {value:"EG", key:"Egypt"},
  {value:"SV", key:"El Salvador"},
  {value:"GQ", key:"Equatorial Guinea"},
  {value:"ER", key:"Eritrea"},
  {value:"EE", key:"Estonia"},
  {value:"SZ", key:"Eswatini"},
  {value:"ET", key:"Ethiopia"},
  {value:"FK", key:"Falkland Islands (Malvinas)"},
  {value:"FO", key:"Faroe Islands"},
  {value:"FJ", key:"Fiji"},
  {value:"FI", key:"Finland"},
  {value:"FR", key:"France"},
  {value:"GF", key:"French Guiana"},
  {value:"PF", key:"French Polynesia"},
  {value:"TF", key:"French Southern Territories"},
  {value:"GA", key:"Gabon"},
  {value:"GM", key:"Gambia"},
  {value:"GE", key:"Georgia"},
  {value:"DE", key:"Germany"},
  {value: "GH", key:"Ghana"},
  {value: "GI", key:"Gibraltar"},
  {value: "GR", key:"Greece"},
  {value: "GL", key:"Greenland"},
  {value: "GD", key:"Grenada"},
  {value: "GP", key:"Guadeloupe"},
  {value: "GU", key:"Guam"},
  {value: "GT", key:"Guatemala"},
  {value: "GG", key:"Guernsey"},
  {value: "GN", key:"Guinea"},
  {value: "GW", key:"Guinea-Bissau"},
  {value: "GY", key:"Guyana"},
  {value: "HT", key:"Haiti"},
  {value: "HM", key:"Heard Island and McDonald Islands"},
  {value: "VA", key:"Holy See"},
  {value: "HN", key:"Honduras"},
  {value: "HK", key:"Hong Kong"},
  {value: "HU", key:"Hungary"},
  {value: "IS", key:"Iceland"},
  {value: "IN", key:"India"},
  {value: "ID", key:"Indonesia"},
  {value: "IR", key:"Iran"},
  {value: "IQ", key:"Iraq"},
  {value: "IE", key:"Ireland"},
  {value: "IM", key:"Isle of Man"},
  {value: "IL", key:"Israel"},
  {value: "IT", key:"Italy"},
  {value: "JM", key:"Jamaica"},
  {value: "JP", key:"Japan"},
  {value: "JE", key:"Jersey"},
  {value: "JO", key:"Jordan"},
  {value: "KZ", key:"Kazakhstan"},
  {value: "KE", key:"Kenya"},
  {value: "KI", key:"Kiribati"},
  {value: "KW", key:"Kuwait"},
  {value: "KG", key:"Kyrgyzstan"},
  {value: "LA", key:"Laos"},
  {value: "LV", key:"Latvia"},
  {value: "LB", key:"Lebanon"},
  {value: "LS", key:"Lesotho"},
  {value: "LR", key:"Liberia"},
  {value: "LY", key:"Libya"},
  {value: "LI", key:"Liechtenstein"},
  {value: "LT", key:"Lithuania"},
  {value: "LU", key:"Luxembourg"},
  {value: "MO", key:"Macao"},
  {value: "MG", key:"Madagascar"},
  {value: "MW", key:"Malawi"},
  {value: "MY", key:"Malaysia"},
  {value: "MV", key:"Maldives"},
  {value: "ML", key:"Mali"},
  {value: "MT", key:"Malta"},
  {value: "MH", key:"Marshall Islands"},
  {value: "MQ", key:"Martinique"},
  {value: "MR", key:"Mauritania"},
  {value: "MU", key:"Mauritius"},
  {value: "YT", key:"Mayotte"},
  {value: "MX", key:"Mexico"},
  {value: "FM", key:"Micronesia (Federated States of)"},
  {value: "MD", key:"Moldova"},
  {value: "MC", key:"Monaco"},
  {value: "MN", key:"Mongolia"},
  {value: "ME", key:"Montenegro"},
  {value: "MS", key:"Montserrat"},
  {value: "MA", key:"Morocco"},
  {value: "MZ", key:"Mozambique"},
  {value: "MM", key:"Myanmar"},
  {value: "NA", key:"Namibia"},
  {value: "NR", key:"Nauru"},
  {value: "NP", key:"Nepal"},
  {value: "NL", key:"Netherlands"},
  {value: "NC", key:"New Caledonia"},
  {value: "NZ", key:"New Zealand"},
  {value: "NI", key:"Nicaragua"},
  {value: "NE", key:"Niger"},
  {value: "NG", key:"Nigeria"},
  {value:"NU", key:"Niue"},
  {value:"NF", key:"Norfolk Island"},
  {value:"KP", key:"North Korea"},
  {value:"MK", key:"North Macedonia"},
  {value:"MP", key:"Northern Mariana Islands"},
  {value:"NO", key:"Norway"},
  {value:"OM", key:"Oman"},
  {value:"PK", key:"Pakistan"},
  {value:"PW", key:"Palau"},
  {value:"PS", key:"Palestine, State of"},
  {value:"PA", key:"Panama"},
  {value:"PG", key:"Papua New Guinea"},
  {value:"PY", key:"Paraguay"},
  {value:"PE", key:"Peru"},
  {value:"PH", key:"Philippines"},
  {value:"PN", key:"Pitcairn"},
  {value:"PL", key:"Poland"},
  {value:"PT", key:"Portugal"},
  {value:"PR", key:"Puerto Rico"},
  {value:"QA", key:"Qatar"},
  {value:"RE", key:"Réunion"},
  {value:"RO", key:"Romania"},
  {value:"RU", key:"Russia"},
  {value:"RW", key:"Rwanda"},
  {value:"BL", key:"Saint Barthélemy"},
  {value:"SH", key:"Saint Helena, Ascension and Tristan da Cunha"},
  {value:"KN", key:"Saint Kitts and Nevis"},
  {value:"LC", key:"Saint Lucia"},
  {value:"MF", key:"Saint Martin (French part)"},
  {value:"PM", key:"Saint Pierre and Miquelon"},
  {value:"VC", key:"Saint Vincent and the Grenadines"},
  {value:"WS", key:"Samoa"},
  {value:"SM", key:"San Marino"},
  {value:"ST", key:"Sao Tome and Principe"},
  {value:"SA", key:"Saudi Arabia"},
  {value:"SN", key:"Senegal"},
  {value:"RS", key:"Serbia"},
  {value:"SC", key:"Seychelles"},
  {value:"SL", key:"Sierra Leone"},
  {value:"SG", key:"Singapore"},
  {value:"SX", key:"Sint Maarten (Dutch part)"},
  {value:"SK", key:"Slovakia"},
  {value:"SI", key:"Slovenia"},
  {value:"SB", key:"Solomon Islands"},
  {value:"SO", key:"Somalia"},
  {value:"ZA", key:"South Africa"},
  {value:"GS", key:"South Georgia and the South Sandwich Islands"},
  {value:"KR", key:"South Korea"},
  {value:"SS", key:"South Sudan"},
  {value:"ES", key:"Spain"},
  {value:"LK", key:"Sri Lanka"},
  {value:"SD", key:"Sudan"},
  {value:"SR", key:"Suriname"},
  {value:"SJ", key:"Svalbard and Jan Mayen"},
  {value:"SE", key:"Sweden"},
  {value:"CH", key:"Switzerland"},
  {value:"SY", key:"Syria"},
  {value:"TW", key:"Taiwan"},
  {value:"TJ", key:"Tajikistan"},
  {value:"TZ", key:"Tanzania"},
  {value:"TH", key:"Thailand"},
  {value:"TL", key:"Timor-Leste"},
  {value:"TG", key:"Togo"},
  {value:"TK", key:"Tokelau"},
  {value:"TO", key:"Tonga"},
  {value:"TT", key:"Trinidad and Tobago"},
  {value:"TN", key:"Tunisia"},
  {value:"TR", key:"Turkey"},
  {value:"TM", key:"Turkmenistan"},
  {value:"TC", key:"Turks and Caicos Islands"},
  {value:"TV", key:"Tuvalu"},
  {value:"UG", key:"Uganda"},
  {value:"UA", key:"Ukraine"},
  {value:"AE", key:"United Arab Emirates"},
  {value:"GB", key:"United Kingdom"},
  {value:"UM", key:"United States Minor Outlying Islands"},
  {value:"US", key:"United States of America"},
  {value:"UY", key:"Uruguay"},
  {value:"UZ", key:"Uzbekistan"},
  {value:"VU", key:"Vanuatu"},
  {value:"VE", key:"Venezuela"},
  {value:"VN", key:"Vietnam"},
  {value:"VG", key:"Virgin Islands (British)"},
  {value:"VI", key:"Virgin Islands (U.S.)"},
  {value:"WF", key:"Wallis and Futuna"},
  {value:"EH", key:"Western Sahara"},
  {value:"YE", key:"Yemen"},
  {value:"ZM", key:"Zambia"},
  {value:"ZW", key:"Zimbabwe"},
];