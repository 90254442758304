import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
  toast,
  useNavigate,
  Theme,
} from "components/common/ExternalComponents";
import DateComponent from "components/date/DateComponent";
import CustomButton from "components/buttons/Button";
import CustomChip from "components/chips/TableChips";
import { PRD_DETAILS_ROUTE, SIGNIN_ROUTE } from "constants/Routes";
import { TRANSACTION_HISTORY_LIST_ENDPOINT } from "constants/Endpoints";
import PRDIndicators from "pages/tms/prd/PrdIndicators";
import {
  changePageNumber,
  userLogoutAction,
} from "service/store/UserProfileSlice";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { Column, DateRange } from "utils/types/CommonTypes";
import { customFetch } from "utils/Axios";
import { dateConverter2, formatter } from "utils/Helpers";

const columns: Column[] = [
  { id: "reference_id", label: "Unique ID", minWidth: 170, align: "center" },
  {
    id: "amount",
    label: "Amount",
    minWidth: 170,
    align: "center",
  },
  { id: "charges_amount", label: "Charges", minWidth: 170, align: "center" },
  { id: "type", label: "Cashflow", minWidth: 170, align: "center" },
];

interface TransactionData {
  amount: number;
  created_at: string;
  reference_id: string;
  charges_amount: number;
  type: string;
  extra_data: { [key: string]: any };
}

function createData(
  reference_id: string,
  amount: string,
  charges_amount: string,
  type: string
) {
  return {
    reference_id,
    amount,
    charges_amount,
    type,
  };
}

const PRD = () => {
  const [platformId, setPlatformId] = useState<string>("");
  const { pageNumber } = useAppSelector((store) => store.profileSliceReducer);
  const [page, setPage] = useState<number | any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allTransactions, setAllTransactions] = useState([]);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    // setPageNumber(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(pageNumber);
  };

  let url = `${TRANSACTION_HISTORY_LIST_ENDPOINT}?page=${
    page + 1
  }&page_size=${rowsPerPage}`;

  const getAllTransactions = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }
      const resp = await customFetch.get(url);
      const newData = resp?.data?.results?.map((element: TransactionData) => {
        const { reference_id, amount, extra_data, type } = element;
        return createData(
          reference_id,
          formatter.format(amount),
          extra_data.charges ? formatter.format(extra_data.charges) : "-----",
          type
        );
      });
      setAllTransactions(newData);
      setTotalCount(resp?.data?.count);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllTransactions();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [page, dateRange]);

  return (
    <Box sx={{ width: "inherit" }}>
      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{ color: theme.palette.common.dateColor, marginBottom: ".6rem" }}
        >
          Date
        </Typography>
        <DateComponent
          setPage={setPage}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <PRDIndicators platformId={platformId} setPlatformId={setPlatformId} />
      </Box>

      <Box
        sx={{
          marginTop: "30px",
          width: "-webkit-fill-available",
        }}
      >
        {allTransactions && (
          <>
            <TableContainer
              component={Paper}
              elevation={2}
              sx={{
                width: "inherit",
                px: 4.5,
                borderRadius: "13px",
                border: "1px solid #E8EBEE",
                paddingBottom: 6,
                boxShadow: `0px 10px 20px 5px ${theme.palette.common.background}`,
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "inherit",
                  height: "65px",
                }}
              >
                <Typography
                  sx={{
                    color: "#212121",
                    fontWeight: "500",
                    fontSize: "19px",
                  }}
                >
                  Transaction history
                </Typography>
                <Box>
                  <CustomButton
                    sx={{
                      maxWidth: "100px",
                      background: theme.palette.common.black,
                      color: "white",
                    }}
                    color="secondary"
                    onClick={() => navigate(PRD_DETAILS_ROUTE)}
                    size="small"
                    variant="contained"
                  >
                    View Details
                  </CustomButton>
                </Box>
              </Stack>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                        color: theme.palette.common.greyColor,
                        fontWeight: "600",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allTransactions.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              sx={{
                                color: theme.palette.common.tableBodyColor,
                                fontWeight: "400",
                                lineHeight: "24px",
                              }}
                              key={column.id}
                              align={column.align}
                            >
                              {value === "CREDIT" ? (
                                <CustomChip tag={"Inflow"} />
                              ) : value === "DEBIT" ? (
                                <CustomChip tag={"Outflow"} />
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={totalCount || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default PRD;
