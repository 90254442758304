import React, { useEffect } from "react";
import { useNavigate } from "components/common/ExternalComponents";
import CustomCardLink from "components/links/CardLinks";
import {
  MERCHANT_VERIFICATION_ROUTE,
  PAYMENT_MANAGEMENT_ROUTE,
  SIGNIN_ROUTE,
} from "constants/Routes";
import { useAppSelector } from "service/hook/Hooks";

const DvaultHomePage = () => {
  const { token } = useAppSelector((state) => state.profileSliceReducer);
  const navigate = useNavigate();

  useEffect(() => {
    !token && navigate(SIGNIN_ROUTE);
  }, [token]);

  return (
    <CustomCardLink
      linkArr={[MERCHANT_VERIFICATION_ROUTE, PAYMENT_MANAGEMENT_ROUTE]}
    />
  );
};

export default DvaultHomePage;
