import React, { useEffect } from "react";
import { useNavigate } from "components/common/ExternalComponents";
import CustomCardLink from "components/links/CardLinks";
import {
  MERCHANT_ROUTE,
  PRD_ROUTE,
  REVENUE_ROUTE,
  SIGNIN_ROUTE,
  SOFTWARE_UPDATE_ROUTE,
} from "constants/Routes";
import { useAppSelector } from "service/hook/Hooks";

const TmsHomePage = () => {
  const { token } = useAppSelector((state) => state.profileSliceReducer);
  const navigate = useNavigate();

  useEffect(() => {
    !token && navigate(SIGNIN_ROUTE);
  }, [token]);

  return (
    <CustomCardLink
      linkArr={[
        PRD_ROUTE,
        REVENUE_ROUTE,
        MERCHANT_ROUTE,
        SOFTWARE_UPDATE_ROUTE,
      ]}
    />
  );
};

export default TmsHomePage;
