import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Yup,
  toast,
  Field,
  Form,
  Formik,
  useNavigate,
} from "components/common/ExternalComponents";
import { FormikControl } from "components/form/FormikControl";
import { UPDATE_POS_REQUEST_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";

interface Props {
  currentID: any;
  status: any;
  [key: string]: any;
}

interface FormikProps {
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  [key: string]: any;
}

function CancelledOrReturnedForm({
  currentID,
  onClose,
  status,
  updateTerminalRequest,
  setUpdateTerminalRequest,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [action, setAction] = useState(
    status === "cancelled" ? "reason_for_cancellation" : "reason_for_return"
  );
  const initialValues = {
    [action]: "",
  };

  const validationSchema = Yup.object().shape({
    [action]: Yup.string().required("Required"),
  });

  const updateCall = async (actionToPerform: string) => {
    let url = `${UPDATE_POS_REQUEST_ENDPOINT}?id=${currentID}`;
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.patch(url, {
        [action]: actionToPerform,
        status: status,
      });
      onClose();
      setUpdateTerminalRequest(!updateTerminalRequest);
    } catch (error: any) {
      console.log(error);
      if (
        error.response.detail ===
        "Authentication credentials were not provided."
      ) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.message)
        : toast.error("An error occurred");
      dispatch(globalLoadingAction(false));
    }
  };

  const onSubmit = (values: { [key: string]: any }) => {
    const actionToPerform = values[action];
    updateCall(actionToPerform);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, isSubmitting }: FormikProps) => {
        return (
          <Form placeholder={undefined}>
            <Box>
              <Field name={action}>
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      required={" *"}
                      background={"null"}
                      id={action}
                      control="input3"
                      label={
                        status === "cancelled"
                          ? "Reason for cancellation"
                          : "Reason for return"
                      }
                      name={action}
                      value={form?.values[action]}
                      onChange={form.handleChange(action)}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box sx={{ marginTop: "25px" }}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!dirty || !isValid || isSubmitting}
                sx={{
                  "&.MuiButton-root": {
                    textTransform: "Capitalize",
                    borderRadius: "10px",
                    display: "block",
                    marginLeft: "auto",
                  },
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={22}
                    sx={{
                      color: "common.white",
                      margin: "0px 10px",
                    }}
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CancelledOrReturnedForm;
