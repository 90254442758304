import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import { WALLET_DETAIL_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { capitalizeFirstLetter, formatter } from "utils/Helpers";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";

const WalletDetails = ({ walletID }: any) => {
  const [walletDetails, setWalletDetails] = useState<{ [key: string]: any }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let url = WALLET_DETAIL_ENDPOINT;

  const walletDetail = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (walletID) {
        url = url + `?id=${walletID}`;
      }
      const resp = await customFetch.get(url);
      setWalletDetails(resp?.data);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      } else if (
        error.response.status === 404 ||
        error.response.status === 500
      ) {
        error?.response?.data?.error?.server
          ? toast.error(error?.response?.data?.error?.server[0])
          : toast.error("An error ocurred");
      } else {
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error?.gateway[0])
          : toast.error("An error ocurred");
      }
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      walletID && walletDetail();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [walletID]);

  return (
    <Paper
      elevation={1}
      sx={{
        width: "inherit",
        px: 4,
        py: 2,
        borderRadius: "8px",
        border: "1px solid #E8EBEE",
        boxShadow: `0px 10px 20px 5px ${theme.palette.common.background}`,
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.lightBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            Account Name:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.darkBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            {walletDetails?.account_name
              ? `${capitalizeFirstLetter(walletDetails?.account_name)}`
              : " - "}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.lightBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            Account Number:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.darkBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            {walletDetails?.account_number}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.lightBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            Account Balance:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: `${theme.palette.common.darkBlue}`,
              fontWeight: 500,
              my: 0.5,
            }}
          >
            {walletDetails?.balance
              ? formatter.format(walletDetails?.balance)
              : formatter.format(0)}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default WalletDetails;
