import React from "react";
import { Box, CircularProgress } from "components/common/ExternalComponents";
import { theme } from "utils/Theme";

const ModalLoader = () => {
  return (
    <Box
      width={"inherit"}
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "inherit",
      }}
    >
      <CircularProgress
        size={52}
        sx={{
          color: theme.palette.common.primaryColor,
          margin: "120px auto",
        }}
      />
    </Box>
  );
};

export default ModalLoader;
