import React from "react";
import {
  Container,
  Typography,
  Box,
} from "components/common/ExternalComponents";

function Merchant() {
  return (
    <Container sx={{ overflow: "hidden" }} fixed>
      <Box
        sx={{
          height: "100vh",
          width: "inherit",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography>Sorry! This dashboard is not available yet.</Typography>
      </Box>
    </Container>
  );
}

export default Merchant;
