import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Yup,
  useFormik,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import {
  ID_CARD_UPDATE_ENDPOINT,
  KYC_UPDATE_ENDPOINT,
  KYC_UPLOAD_ENDPOINT,
} from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import CustomButton from "components/buttons/Button";
import { theme } from "utils/Theme";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { customFetch } from "utils/Axios";
import { IdCard } from "utils/types/CommonTypes";

const selectOptions = [
  { value: "NATIONAL ID", label: "National Identification number(NIN)" },
  {
    value: "DRIVERS LICENCE",
    label: `Driver's License`,
  },
  {
    value: "VOTERS CARD",
    label: `Voter's card`,
  },
  {
    value: "CAC",
    label: `CAC`,
  },
  {
    value: "PROOF OF ADDRESS",
    label: `Proof of address`,
  },
  {
    value: "INTERNATIONAL PASSPORT",
    label: `International Passport`,
  },
  {
    value: "BVN",
    label: `BVN`,
  },
];

const validationSchema = Yup.object().shape({
  // idType: Yup.string().required('This is a required field'),
  // idNumber: Yup.string().required('This is a required field'),
  // frontID: Yup.mixed().required('This is a required field'),
  // backID: Yup.mixed().required('This is a required field'),
});

interface UpdateIdFormProps {
  handleClose: any;
  number: string;
  type: string;
  frontID: null | string;
  backID: null | string;
  kycID: string;
  id_cards: IdCard[];
  ID: string;
}

function UpdateIDform({
  handleClose,
  number,
  type,
  frontID,
  backID,
  kycID,
  id_cards,
  ID,
}: UpdateIdFormProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [newPhotos, setNewPhotos] = useState<
    React.Dispatch<React.SetStateAction<boolean>> | any
  >(false);
  const [runPhotosEndpoint, setRunPhotosEndpoint] = useState(false);

  const updateCredentials = async (
    idTypeInput: string,
    idNumberInput: string
  ) => {
    if (kycID) {
      try {
        const resp = await customFetch.patch(
          `${ID_CARD_UPDATE_ENDPOINT}?id=${kycID}`,
          {
            number: idNumberInput === number ? undefined : idNumberInput,
            type: idTypeInput === type ? undefined : idTypeInput,
            front_id_photo: newPhotos[0]?.url ? newPhotos[0]?.url : undefined,
            back_id_photo: newPhotos[1]?.url ? newPhotos[1]?.url : undefined,
          }
        );
        dispatch(globalLoadingAction(true));
        toast.success(`Update successful`);
        handleClose();
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(userLogoutAction());
          return navigate(SIGNIN_ROUTE);
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          error?.response?.data?.error?.server
            ? toast.error(error?.response?.data?.error?.server[0])
            : toast.error("Update failed");
        }
        error?.response?.data?.error
          ? toast.error({ ...error?.response?.data?.error })
          : toast.error("Update failed");
        handleClose();
      }
    } else {
      if (id_cards.length < 1 && ID) {
        try {
          const resp = await customFetch.patch(
            `${KYC_UPDATE_ENDPOINT}?id=${ID}`,
            {
              number: idNumberInput === number ? undefined : idNumberInput,
              type: idTypeInput === type ? undefined : idTypeInput,
              front_id_photo: newPhotos[0]?.url ? newPhotos[0]?.url : undefined,
              back_id_photo: newPhotos[1]?.url ? newPhotos[1]?.url : undefined,
            }
          );
          dispatch(globalLoadingAction(true));
          toast.success(`Update successful`);
          handleClose();
        } catch (error: any) {
          if (error.response.status === 401) {
            dispatch(userLogoutAction());
            return navigate(SIGNIN_ROUTE);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            error?.response?.data?.error?.server
              ? toast.error(error?.response?.data?.error?.server[0])
              : toast.error("Update failed");
          }
          error?.response?.data?.error
            ? toast.error({ ...error?.response?.data?.error })
            : toast.error("Update failed");
          handleClose();
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      idType: type,
      idNumber: number,
      frontID: frontID,
      backID: backID,
    },
    onSubmit: async (values) => {
      const requestArray = [
        {
          url: KYC_UPLOAD_ENDPOINT,
          data: {
            file: values.frontID,
          },
        },
        {
          url: KYC_UPLOAD_ENDPOINT,
          data: {
            file: values.backID,
          },
        },
      ];
      if (runPhotosEndpoint && values.backID && values.frontID) {
        try {
          const postRequests = requestArray.map(async (requestObject) => {
            const response = await customFetch.post(
              requestObject.url,
              requestObject.data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            return response.data;
          });
          const responses = await Promise.all(postRequests);
          setNewPhotos(responses);
        } catch (error: any) {
          if (error.response.status === 401) {
            dispatch(userLogoutAction());
            return navigate(SIGNIN_ROUTE);
          }
          error?.response?.data?.error
            ? toast.error(error?.response?.data?.error)
            : toast.error("An error occurred");
        }
      } else {
        updateCredentials(formik.values?.idType, formik.values?.idNumber);
      }
    },
    validationSchema,
  });

  useEffect(() => {
    if (newPhotos) {
      updateCredentials(formik.values?.idType, formik.values?.idNumber);
    }
  }, [newPhotos]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          py: 2,
        }}
      >
        <TextField
          select
          color="secondary"
          name="idType"
          label="ID Type"
          variant="outlined"
          fullWidth
          value={formik.values.idType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          py: 2,
        }}
      >
        <TextField
          name="idNumber"
          color="secondary"
          label="ID Number"
          variant="outlined"
          fullWidth
          value={formik.values.idNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          // error={!(formik.errors.idNumber && formik.touched.idNumber)}
          helperText={
            formik.errors.idNumber && formik.touched.idNumber
              ? formik.errors.idNumber
              : ""
          }
        />
      </Box>
      <Box
        sx={{
          py: 1,
        }}
      >
        <Box
          component="label"
          sx={{
            fontWeight: 500,
            display: "block",
            color: `#454E68`,
          }}
          htmlFor="frontID"
        >
          {"Front of ID (< 1mb)"}
        </Box>
        <Box
          sx={{
            border: `1px solid gray`,
            padding: "10px 10px",
            borderRadius: "5px",
          }}
        >
          <input
            id="frontID"
            name="frontID"
            type="file"
            accept=".png, .jpeg, .jpg, image/png, image/jpeg"
            onChange={(event) => {
              setRunPhotosEndpoint(true);
              const files = event.target.files;
              if (files && files.length > 0) {
                formik.setFieldValue("frontID", files[0]);
              } else {
                formik.setFieldValue("frontID", null);
              }
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          py: 1,
        }}
      >
        <Box
          component="label"
          sx={{
            fontWeight: 500,
            display: "block",
            color: `#454E68`,
          }}
          htmlFor="backID"
        >
          {"Back of ID (< 1mb)"}
        </Box>
        <Box
          sx={{
            border: `1px solid gray`,
            padding: "10px 10px",
            borderRadius: "5px",
          }}
        >
          <input
            id="backID"
            name="backID"
            type="file"
            accept=".png, .jpeg, .jpg, image/png, image/jpeg"
            onChange={(event) => {
              setRunPhotosEndpoint(true);
              const files = event.target.files;
              if (files && files.length > 0) {
                formik.setFieldValue("backID", files[0]);
              } else {
                formik.setFieldValue("backID", null);
              }
            }}
          />
        </Box>
      </Box>

      <Stack
        direction="row"
        justifyContent={"flex-end"}
        sx={{
          marginTop: "50px",
        }}
      >
        <CustomButton
          // type="submit"
          variant="outlined"
          color="secondary"
          sx={{
            marginRight: "10px",
            background: "white",
            border: `1px solid ${theme.palette.common.black}`,
            "&:hover": {
              background: "rgba(0, 0, 0, 0.05)",
            },
          }}
          onClick={handleClose}
        >
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={!formik.dirty}
          sx={{
            background: "black",
            color: "white",
          }}
        >
          {formik.isSubmitting ? (
            <CircularProgress
              size={22}
              sx={{
                color: "common.white",
                margin: "0px 10px",
              }}
            />
          ) : (
            "Update"
          )}
        </CustomButton>
      </Stack>
    </form>
  );
}

export default UpdateIDform;
