import React from "react";
import CustomButton from "components/buttons/Button";
import {
  FileDownload,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import { SIGNIN_ROUTE } from "constants/Routes";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { customFetch } from "utils/Axios";
import { dateConverter2 } from "utils/Helpers";
import { downloadFile } from "utils/DownloadUtil";
import { theme } from "utils/Theme";
import { DownloadData } from "utils/types/CommonTypes";

const DownloadBtn = ({
  url,
  dateRange,
  localSearch,
  idCardIsVerified,
  platformId,
  terminalID,
  walletID,
  downloadMethodFilter,
  downloadStatusFilter,
  downloadTypeFilter,
}: DownloadData) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const downloadHistory = () => {
    customFetch
      .get(
        `${url}?created_at=${dateConverter2(
          dateRange.startDate
        )},${dateConverter2(
          dateRange.endDate
        )}&download=${true}&search=${localSearch}&is_verified=${idCardIsVerified}&platform_id=${platformId}&terminal_id=${terminalID}&wallet_id=${walletID}&status=${downloadStatusFilter}&type=${downloadTypeFilter}&payment_method=${downloadMethodFilter}`
      )
      .then((response: any) => {
        const csvData = response.data;
        const filename = `KYC_List_${dateConverter2(
          dateRange.startDate
        )}__${dateConverter2(dateRange.endDate)}`;
        downloadFile(csvData, filename);
      })
      .catch((error: any) => {
        console.log("Error", error);
        if (error.response.status === 401) {
          dispatch(userLogoutAction());
          return navigate(SIGNIN_ROUTE);
        } else {
          error?.response?.data?.error
            ? toast.error(...[error?.response?.data?.error])
            : toast.error("An error ocurred");
        }
      });
  };

  return (
    <CustomButton
      variant="contained"
      endIcon={<FileDownload />}
      disabled={!(dateRange.startDate && dateRange.endDate)}
      sx={{
        marginLeft: "10px",
        color: theme.palette.common.white,
        border: `2px solid ${theme.palette.common.lightBlue}`,
        backgroundColor: theme.palette.common.lightBlue,
        "&:hover": {
          backgroundColor: theme.palette.common.lightBlue,
        },
      }}
      onClick={() => downloadHistory()}
    >
      Download report
    </CustomButton>
  );
};

export default DownloadBtn;
