import React, { useState, useEffect } from "react";
import CustomNavLink from "components/links/Navlink";
import CustomLink from "components/links/Link";
import {
  Backdrop,
  CircularProgress,
  Box,
  toast,
  Accordion,
  ArrowForwardIosSharp,
  AccordionSummary,
  AccordionDetails,
  useNavigate,
} from "components/common/ExternalComponents";
import { GET_USER_ROLE_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { roleAction } from "service/store/RoleSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { linkName } from "utils/LinksUtil";
import { checkRole, newArr } from "utils/CheckRole";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";

export default function CustomizedAccordions() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(true);
  const { token, user } = useAppSelector((store) => store.profileSliceReducer);
  const [role, setRole] = useState<any>();
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getRole = async () => {
    try {
      const resp = await customFetch.get(
        `${GET_USER_ROLE_ENDPOINT}?id=${user?.id}`
      );
      setRole(resp?.data);
    } catch (error: any) {
      if (error?.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    getRole();
    dispatch(globalLoadingAction(true));
  }, [token]);

  useEffect(() => {
    if (role) {
      setTimeout(() => {
        setUserRole(false);
        role?.roles[0] !== "" && checkRole(role?.roles);
        dispatch(globalLoadingAction(false));
        dispatch(roleAction({ ...role }));
      }, 750);
    }
  }, [role]);

  return (
    <>
      {role?.roles[0] !== "" ? (
        newArr?.map((item: any, index: number) => {
          return (
            <Accordion
              elevation={0}
              square
              key={index}
              style={{
                backgroundColor: "transparent",
                padding: 0,
                margin: "0px",
                position: "inherit",
                // "&.MuiPaper-root-MuiAccordion-root": {
                //   border: "1px solid red",
                // },
              }}
              expanded={expanded === `panel + ${index}`}
              onChange={handleChange(`panel + ${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={
                  <ArrowForwardIosSharp
                    sx={{
                      color: "#f3f3f3",
                      fontSize: "0.9rem",
                    }}
                  />
                }
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                  backgroundColor: "none",
                  flexDirection: "row",
                }}
              >
                <CustomNavLink route={item?.route} image={item.image}>
                  {item?.title}
                </CustomNavLink>
              </AccordionSummary>
              {item?.details.map((element: any, index: number) => {
                return (
                  <AccordionDetails
                    key={index}
                    sx={{
                      padding: theme.spacing(0.5),
                      marginLeft: "20px",
                      borderColor: "transparent",
                      borderLeft: "1px solid #f3f3f3",
                      paddingLeft: "10px",
                      paddingBottom: "5px",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "#323335",
                        borderRadius: "4px",
                        paddingLeft: "10px",
                      },
                    }}
                  >
                    <CustomLink route={linkName(element)}>
                      <Box color={theme.palette.common.white}>{element}</Box>
                    </CustomLink>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          );
        })
      ) : (
        <Backdrop
          open={userRole}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
}
