import React, { ReactNode } from "react";
import simone_eye from "assets/images/loginAssets/simoneEye.svg";
import { authLayoutStyles } from "assets/styles/auth/AuthStyles";
import {
  Grid,
  Box,
  Theme,
  Typography,
  useMediaQuery,
} from "components/common/ExternalComponents";

interface AuthProps {
  children: ReactNode;
}

const AuthLayout: React.FC<AuthProps> = ({ children }) => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      {matches && (
        <Typography style={{ textAlign: "center" }}>
          Content only available on Desktop mode
        </Typography>
      )}
      <Grid container sx={authLayoutStyles.container}>
        <Grid item xs={6} sx={authLayoutStyles.leftCard}>
          <Box component="img" src={simone_eye} alt="eye icon" width="70%" />
        </Grid>
        <Grid item xs={6} display="flex">
          {children}
        </Grid>
      </Grid>
    </>
  );
};
export default AuthLayout;
