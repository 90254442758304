import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getItem, saveItem } from "service/helper/StorageHelper";

export const roleSliceName = "roleSliceReducer";
export const roleStorageName = "userDetail";

export interface UserDetail {
  auth_id: string;
  created_at: string;
  email: string;
  id: string;
  is_active: boolean;
  is_archived: boolean;
  is_superuser: boolean;
  last_login: string;
  phone_number: string;
  profile: {
    country: string;
    created_at: string;
    first_name: string;
    gender: string;
    last_name: string;
    updated_at: string;
  };
  roles: string[];
  updated_at: string;
  username: string;
}

export const initialState: UserDetail = {
  auth_id: "",
  created_at: "",
  email: "",
  id: "",
  is_active: true,
  is_archived: false,
  is_superuser: true,
  last_login: "",
  phone_number: "",
  profile: {
    country: "",
    created_at: "",
    first_name: "",
    gender: "",
    last_name: "",
    updated_at: "",
  },
  // roles: "",
  roles: [],
  updated_at: "",
  username: "",
  ...(getItem(roleStorageName) || {}),
};

export const roleSlice = createSlice({
  name: roleSliceName,
  initialState,
  reducers: {
    roleAction(state: any, action: PayloadAction<{ [key: string]: any }>) {
      saveItem(roleStorageName, { ...action.payload });
      return { ...state, ...action.payload };
    },
  },
});

export const { roleAction } = roleSlice.actions;

export default roleSlice.reducer;
