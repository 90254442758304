import React from "react";
import {
  Navigate,
  useLocation,
  Outlet,
} from "components/common/ExternalComponents";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppSelector } from "service/hook/Hooks";

const ProtectedAdminRoute = ({ allowedRoles }: any) => {
  const { roles } = useAppSelector((state) => state.roleSliceReducer);
  const location = useLocation();

  return roles?.find((role: string) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : (
    <Navigate to={SIGNIN_ROUTE} state={{ from: location }} replace />
  );
};

export default ProtectedAdminRoute;
