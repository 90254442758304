import React, { useState } from "react";
import down from "assets/images/amsAssets/down.svg";
import up from "assets/images/amsAssets/up.svg";
import CustomChip from "components/chips/Chip";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Close,
  ArrowDropDown,
} from "components/common/ExternalComponents";
import CancelledOrReturnedForm from "pages/ams/trd/CancelledOrReturned";
import DeliveredForm from "pages/ams/trd/DeliveredForm";
import OutForDeliveryForm from "pages/ams/trd/OutForDeliveryForm";

interface RowProps {
  element: { [key: string]: any };
  columns: any[];
  index: number;
  columnsExtra: any[];
  currentID: React.MutableRefObject<string>;
  [key: string]: any;
}

const TRDRow = ({
  element,
  columns,
  index,
  columnsExtra,
  currentID,
  updateTerminalRequest,
  setUpdateTerminalRequest,
}: RowProps) => {
  const { merchantName, businessName } = element;
  const [openTableDetails, setOpenTableDetails] = useState(false);
  const [tableAnchorEl, setTableAnchorEl] = useState(null);
  const openTableAnchorEl = Boolean(tableAnchorEl);
  const tableID = openTableAnchorEl ? "table-popover" : undefined;
  const [showOutForDeliveryForm, setShowOutForDeliveryForm] = useState(false);
  const [showDeliveredForm, setShowDeliveredForm] = useState(false);
  const [showCancelledOrReturnedForm, setShowCancelledOrReturnedForm] =
    useState(false);
  const [formStatusText, setFormStatusText] = useState("");
  const [formAction, setFormAction] = useState("cancelled");
  const [updateStatus, setUpdateStatus] = useState(false);

  const handlePopClick = (e: any) => {
    setTableAnchorEl(e.currentTarget);
  };

  const handlePopClose = (e: any) => {
    setTableAnchorEl(null);
  };

  const handlePopChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handlePopClose(e);
  };

  const handleUpdateStatusDialogOpen = () => {
    setUpdateStatus(true);
  };

  const handleUpdateStatusDialogClose = () => {
    setUpdateStatus(false);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={merchantName + businessName + index}
        onClick={() => {
          // this changes the useRef current value to the row serial number value
          currentID.current = element.sn;
        }}
      >
        {columns.map((column) => {
          const value = element[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.id === "sn" && openTableDetails === false ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenTableDetails(!openTableDetails)}
                >
                  <Box component={"img"} src={up} />
                </IconButton>
              ) : column.id === "sn" && openTableDetails === true ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenTableDetails(!openTableDetails)}
                >
                  <Box component={"img"} src={down} />
                </IconButton>
              ) : value === "New request" ? (
                <CustomChip label="New request" />
              ) : value === "Out for delivery" ? (
                <CustomChip label="Out for delivery" />
              ) : value === "Delivered" ? (
                <CustomChip label="Delivered" />
              ) : value === "Returned" ? (
                <CustomChip label="Returned" />
              ) : value === "Cancelled" ? (
                <CustomChip label="Cancelled" />
              ) : value === null ? (
                " - "
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={openTableDetails} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="more table details">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: `#231F20`,
                      },
                    }}
                  >
                    {columnsExtra.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {columnsExtra.map((column) => {
                      const value = element.extraDetails[0][column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <>
                              <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<ArrowDropDown />}
                                sx={{
                                  fontWeight: 400,
                                }}
                                aria-describedby={tableID}
                                onClick={handlePopClick}
                              >
                                Change Status
                              </Button>
                              <Popover
                                id={tableID}
                                open={openTableAnchorEl}
                                anchorEl={tableAnchorEl}
                                onClose={handlePopClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "200px",
                                  }}
                                >
                                  <List>
                                    {value === "Delivered" && (
                                      <ListItemButton
                                        onClick={(e) => {
                                          handlePopChange(e);
                                          handleUpdateStatusDialogOpen();
                                          setShowDeliveredForm(false);
                                          setShowOutForDeliveryForm(false);
                                          setFormStatusText("Returned");
                                          setFormAction("returned");
                                          setShowCancelledOrReturnedForm(true);
                                        }}
                                      >
                                        <CustomChip label="Returned" />
                                      </ListItemButton>
                                    )}
                                    {value === "New request" && (
                                      <List>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(true);
                                            setFormStatusText(
                                              "Out for delivery"
                                            );
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                          }}
                                        >
                                          <CustomChip label="Out for delivery" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(false);
                                            setFormAction("cancelled");
                                            setShowCancelledOrReturnedForm(
                                              true
                                            );
                                            setFormStatusText("Cancelled");
                                          }}
                                        >
                                          <CustomChip label="Cancelled" />
                                        </ListItemButton>
                                      </List>
                                    )}
                                    {value === "Out for delivery" && (
                                      <>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(true);
                                            setShowOutForDeliveryForm(false);
                                            setFormStatusText("Delivered");
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                          }}
                                        >
                                          <CustomChip label="Delivered" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(false);
                                            setFormStatusText("Returned");
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                          }}
                                        >
                                          <CustomChip label="Returned" />
                                        </ListItemButton>
                                      </>
                                    )}
                                    {value === "Returned" && (
                                      <>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(true);
                                            setShowOutForDeliveryForm(false);
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            setFormStatusText("Delivered");
                                          }}
                                        >
                                          <CustomChip label="Delivered" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e);
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(true);
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            setFormStatusText(
                                              "Out for delivery"
                                            );
                                            // setShowOutForDeliveryForm(false);
                                          }}
                                        >
                                          <CustomChip label="Out for delivery" />
                                        </ListItemButton>
                                      </>
                                    )}
                                  </List>
                                </Box>
                              </Popover>
                              {/* Dialog box pops out when a status change is selected */}
                              <Dialog
                                open={updateStatus}
                                onClose={handleUpdateStatusDialogClose}
                                PaperProps={{
                                  sx: {
                                    borderRadius: "16px",
                                  },
                                }}
                              >
                                <Paper
                                  sx={{
                                    width: "500px",
                                    p: 4,
                                  }}
                                >
                                  <Stack
                                    width={"100%"}
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{
                                        mb: "2rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Update Status - {formStatusText}
                                    </Typography>
                                    <Close
                                      sx={{
                                        display: "block",
                                        fontSize: "2rem",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleUpdateStatusDialogClose}
                                    />
                                  </Stack>
                                  <DialogContent
                                    sx={{
                                      "&.MuiDialogContent-root": {
                                        padding: 0,
                                      },
                                    }}
                                  >
                                    <Box>
                                      {showOutForDeliveryForm && (
                                        <OutForDeliveryForm
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          currentID={currentID.current}
                                          updateTerminalRequest={
                                            updateTerminalRequest
                                          }
                                          setUpdateTerminalRequest={
                                            setUpdateTerminalRequest
                                          }
                                        />
                                      )}
                                      {showCancelledOrReturnedForm && (
                                        <CancelledOrReturnedForm
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          currentID={currentID.current}
                                          terminalSerialNumber={
                                            element.extraDetails[0]
                                              .terminalSerialNo
                                          }
                                          updateTerminalRequest={
                                            updateTerminalRequest
                                          }
                                          setUpdateTerminalRequest={
                                            setUpdateTerminalRequest
                                          }
                                          status={formAction}
                                        />
                                      )}
                                      {showDeliveredForm && (
                                        <DeliveredForm
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          currentID={currentID.current}
                                          terminalSerialNumber={
                                            element.extraDetails[0]
                                              .terminalSerialNo
                                          }
                                          updateTerminalRequest={
                                            updateTerminalRequest
                                          }
                                          setUpdateTerminalRequest={
                                            setUpdateTerminalRequest
                                          }
                                        />
                                      )}
                                    </Box>
                                  </DialogContent>
                                </Paper>
                              </Dialog>
                            </>
                          ) : value === null || value === "" ? (
                            " - "
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRDRow;
