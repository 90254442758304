import React from "react";
import { List, ListItemButton } from "components/common/ExternalComponents";
import CustomChip from "components/chips/Chip";
import { FilterOptionProps } from "utils/types/CommonTypes";

const FilterOptions = ({ options, handleChange }: FilterOptionProps) => {
  const getParameterValue = (option: any) => {
    switch (option) {
      case "verified":
        return true;
      case "unverified":
        return false;
      case "Inactive":
        return true;
      case "Active":
        return false;
      case "New request":
        return "new_request";
      case "Out for delivery":
        return "out_for_delivery";
      case "Delivered":
        return "delivered";
      case "Cancelled":
        return "cancelled";
      case "Returned":
        return "returned";
      case "By Terminal":
        return "By Terminal";
      case "By Wallet":
        return "By Wallet";
      case "Reset":
        return "Reset";
      default:
        return "";
    }
  };

  return (
    <List>
      {options.map((element: any) => {
        return (
          <ListItemButton
            key={element}
            onClick={(e) => handleChange(e, getParameterValue(element))}
          >
            {element === "Active" ? (
              <CustomChip disabled={false} />
            ) : element === "Inactive" ? (
              <CustomChip disabled={true} />
            ) : (
              <CustomChip label={element} />
            )}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default FilterOptions;
