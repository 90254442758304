import React from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "components/common/ExternalComponents";
import { IndicatorData } from "utils/types/CommonTypes";

function PrdIndicatorCard({ src, total, bgColor, text }: IndicatorData) {
  const theme = useTheme();
  return (
    <Box sx={{ marginRight: "15px" }}>
      <Paper
        elevation={2}
        sx={{
          minWidth: "300px",
          borderRadius: "13px",
          border: "1px solid #E8EBEE",
          px: 2,
          py: 2,
          boxShadow: `10px 15px 25px 2px ${theme.palette.common.background}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Stack>
            <Box component={"span"}>
              <Box
                component={"img"}
                src={src}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  // objectFit: "cover",
                  border: "10px solid transparent",
                  display: "block",
                  height: "4rem",
                  width: "4rem",
                  overflow: "visible",
                  borderRadius: "50%",
                  background: `${bgColor}`,
                  margin: "10px 0",
                }}
              />
            </Box>
          </Stack>
          <Stack height={"inherit"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                marginLeft: "10px",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.common.lightBlue,
                  fontWeight: "400",
                  lineHeight: "24px",
                  margin: "5px 0",
                }}
              >
                {text}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    textAlign: "center",
                  },
                  fontWeight: 500,
                  color: theme.palette.common.black,
                  fontSize: "1.5rem",
                }}
              >
                {total}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

export default PrdIndicatorCard;
