import React, { useEffect, useState } from "react";
import {
  Box,
  toast,
  useNavigate,
  useParams,
} from "components/common/ExternalComponents";
import { KYC_DETAIL_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import InfoDetails from "pages/dvault/merchantVerification/InfoDetails";
import IDdetails from "pages/dvault/merchantVerification/IdDetails";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";
import { IdCard } from "utils/types/CommonTypes";

interface KycDetailsProps {
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string;
  state: string;
  email: string;
  country: string;
  address: string;
  created_at: string;
  updated_at: string;
  id: string;
  gender: string;
  tier?: number;
  id_cards: IdCard[];
  nearest_landmark: string;
  passport_photo: any;
  phone_number: string;
  platform: string;
  verification_result: { [key: string]: any };
}

function BalanceDetails() {
  const [isVerified, setIsVerified] = useState<boolean>();
  const [KYCDetails, setKYCDetails] = useState<KycDetailsProps>({
    first_name: "",
    last_name: "",
    middle_name: "",
    date_of_birth: "",
    state: "",
    email: "",
    country: "",
    address: "",
    phone_number: "",
    created_at: "",
    id: "",
    id_cards: [],
    updated_at: "",
    nearest_landmark: "",
    passport_photo: null,
    platform: "",
    verification_result: {},
    gender: "",
  });
  const { loading } = useAppSelector((store) => store.globalSliceReducer);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let url = `${KYC_DETAIL_ENDPOINT}?id=${id}`;

  const getKycDetails = async () => {
    try {
      const resp = await customFetch.get(url);
      const data = resp?.data;
      setIsVerified(data?.id_card?.is_verified);
      dispatch(globalLoadingAction(false));
      return setKYCDetails({
        first_name: data?.first_name,
        last_name: data?.last_name,
        middle_name: data?.middle_name,
        date_of_birth: data?.date_of_birth,
        state: data?.state,
        email: data?.email,
        country: data?.country,
        address: data?.address,
        phone_number: data?.phone_number,
        created_at: data?.created_at,
        id: data?.id,
        id_cards: data?.id_cards,
        updated_at: data?.updated_at,
        nearest_landmark: data?.nearest_landmark,
        passport_photo: data?.passport_photo,
        platform: data?.platform,
        verification_result: data?.verification_result,
        gender: data?.gender,
        tier: data?.tier,
      });
    } catch (error: any) {
      if (error?.response?.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    getKycDetails();
  }, []);

  useEffect(() => {
    getKycDetails();
  }, [loading]);

  return (
    <>
      <Box>
        <InfoDetails
          {...KYCDetails}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
        />
      </Box>

      <Box sx={{ margin: "50px 0px" }}>
        <IDdetails {...KYCDetails} isVerified={isVerified} />
      </Box>
    </>
  );
}

export default BalanceDetails;
