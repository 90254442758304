import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  toast,
  format,
  parseISO,
  useNavigate,
} from "components/common/ExternalComponents";
import SearchBar from "components/search/SearchBar";
import FilterBtn from "components/buttons/filter/FilterButton";
import TerminalModal from "components/modals/TerminalModal";
import { SIGNIN_ROUTE } from "constants/Routes";
import { TERMINAL_LIST_ENDPOINT } from "constants/Endpoints";
import Indicators from "pages/ams/terminalDistribution/TerminalIndicators";
import Row from "pages/ams/terminalDistribution/Row";
import CreateTerminal from "pages/ams/terminalDistribution/CreateTerminal";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import {
  changePageNumber,
  userLogoutAction,
} from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";
import { Column } from "utils/types/CommonTypes";

const columnsExtra: Column[] = [
  { id: "status", label: "", align: "center" },
  {
    id: "serial_number",
    label: "Terminal Serial Number",
    align: "left",
  },
  { id: "tid", label: "Terminal ID", align: "center" },
  { id: "model_type", label: "Terminal Model", align: "center" },
  {
    id: "updated_at",
    label: "Date Updated",
    align: "center",
  },
];

const columns: Column[] = [
  { id: "sn", label: " ", align: "left" },
  { id: "account_name", label: "Merchant Name", align: "left" },
  { id: "name", label: "Account name", align: "left" },
  { id: "created_at", label: "Date", minWidth: 100, align: "left" },
  { id: "is_disabled", label: "Status", minWidth: 100, align: "center" },
];

interface TerminalData {
  created_at: string;
  features: any[];
  id: string;
  imei: string;
  is_disabled: false;
  model_type: string;
  name: string;
  serial_number: string;
  tid: string;
  updated_at: string;
  wallet: { account_name: string; account_number: string; id: string };
}

function createData(
  sn: any,
  account_name: string,
  name: string,
  created_at: string,
  is_disabled: boolean,
  serial_number: string,
  tid: string,
  model_type: string,
  updated_at: string,
  wallet_id: string
) {
  return {
    sn,
    account_name,
    name,
    created_at,
    is_disabled,
    extraDetails: [
      {
        serial_number,
        tid,
        model_type,
        updated_at,
        wallet_id,
      },
    ],
  };
}

function TerminalDistribution() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useAppSelector((store) => store.profileSliceReducer);
  const currentID = useRef("");
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updateTerminalList, setUpdateTerminalList] = useState<boolean>(false);
  const [page, setPage] = useState<number | any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [terminals, setAllTerminals] = useState([]);
  const [localSearch, setLocalSearch] = useState<string>("");
  const [terminalActivity, setTerminalActivity] = useState<boolean | null>();

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(pageNumber);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  let url = `${TERMINAL_LIST_ENDPOINT}?page=${
    page + 1
  }&page_size=${rowsPerPage}`;

  const getAllTerminalList = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (terminalActivity === true) {
        url = url + `&is_disabled=true`;
      }
      if (terminalActivity === false) {
        url = url + `&is_disabled=false`;
      }
      const resp = await customFetch.get(url);
      const newData = resp?.data?.results.map(
        (terminalDetail: TerminalData) => {
          const {
            id,
            wallet,
            name,
            serial_number,
            tid,
            created_at,
            is_disabled,
            model_type,
            updated_at,
          } = terminalDetail;
          // Parsing the data object to a more readable format
          const formattedDate = (dateString: string) =>
            format(parseISO(dateString), "dd/MM/yyyy");
          return createData(
            id,
            wallet?.account_name ? wallet?.account_name : "----",
            name || "----",
            formattedDate(created_at),
            is_disabled,
            serial_number || "----",
            tid || "----",
            model_type || "----",
            formattedDate(updated_at),
            wallet?.id
          );
        }
      );
      setAllTerminals(newData);
      setTotalCount(resp.data.count);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    getAllTerminalList();
  }, [
    page,
    localSearch,
    terminalActivity,
    navigate,
    rowsPerPage,
    dispatch,
    updateTerminalList,
  ]);

  return (
    <>
      <Indicators />

      <Box
        sx={{
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "inherit",
            marginBottom: "30px",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              width: "inherit",
              direction: "row",
              alignItems: "center",
              justifyContent: "space-between",
              px: 4,
              py: 1.8,
            }}
          >
            <Stack
              width={"100%"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                width="21rem"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <SearchBar
                    localSearch={localSearch}
                    setLocalSearch={setLocalSearch}
                    setPage={setPage}
                    border={`2px solid ${theme.palette.common.lightBlue}`}
                    border2="none"
                    height2="0.4rem"
                  />
                </Box>
                <FilterBtn
                  setPage={setPage}
                  setFilter={setTerminalActivity}
                  options={["Inactive", "Active", "All"]}
                />
              </Stack>
              <TerminalModal
                text={"Create Terminal"}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                children={
                  <CreateTerminal
                    handleDialogClose={handleDialogClose}
                    setUpdateTerminalList={setUpdateTerminalList}
                    updateTerminalList={updateTerminalList}
                  />
                }
              />
            </Stack>
          </Paper>
        </Box>

        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    backgroundColor: "#EFF1F9",
                    color: theme.palette.common.greyColor,
                    fontWeight: "600",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {terminals.map(
                (element: { [key: string]: any }, index: number) => {
                  return (
                    <Row
                      key={index}
                      element={element}
                      columns={columns}
                      index={index}
                      columnsExtra={columnsExtra}
                      currentID={currentID}
                      updateTerminalList={updateTerminalList}
                      setUpdateTerminalList={setUpdateTerminalList}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default TerminalDistribution;
