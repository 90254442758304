import React, { useState } from "react";
import {
  Box,
  Divider,
  Paper,
  Popover,
  Stack,
  Typography,
  useTheme,
  ArrowDropDown,
  AccountCircleOutlined,
  Theme,
} from "components/common/ExternalComponents";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { capitalizeFirstLetter } from "utils/Helpers";

const CustomNavDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null>(null);
  const { user } = useAppSelector((state) => state.profileSliceReducer);
  const dispatch = useAppDispatch();
  const theme: Theme = useTheme();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPop = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Stack direction="row" onClick={handleClick}>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#1B1C1E",
            cursor: "pointer",
          }}
        >
          {user?.first_name ? capitalizeFirstLetter(user?.first_name) : "admin"}
        </Typography>
        <ArrowDropDown />
      </Stack>
      <Popover
        id={idPop}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            "&.MuiPaper-root": {
              width: "250px",
              p: 2,
            },
          }}
        >
          <Stack>
            <Box>
              <AccountCircleOutlined
                sx={{
                  fontSize: "2.5rem",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  fontSize: "1.3rem",
                  my: 2,
                }}
              >
                {user?.first_name} {user?.last_name}
              </Typography>
              <Divider />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "500",
                  my: 1,
                }}
              >
                {user?.email}
              </Typography>
              <Divider />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "500",
                  my: 1,
                  color: `${theme.palette.common.flagIcon}`,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => dispatch(userLogoutAction())}
              >
                Sign out
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Popover>
    </Box>
  );
};
export default CustomNavDropdown;
