import React, { ReactNode } from "react";
import simoneLogo from "assets/images/loginAssets/simoneBigLogo.svg";
import { authFormLayoutStyles } from "assets/styles/auth/AuthStyles";
import { Grid, Box } from "components/common/ExternalComponents";

interface AuthProps {
  children: ReactNode;
}

const AuthFormLayout: React.FC<AuthProps> = ({ children }) => {
  return (
    <Box sx={authFormLayoutStyles.container}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Grid item sx={{}}>
          <Box
            component="img"
            src={simoneLogo}
            alt="simone logo"
            width="100px"
          />
        </Grid>
        <Grid item width="90%">
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuthFormLayout;
