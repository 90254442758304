import React from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  toast,
  ArrowBackIosNew,
  Field,
  Form,
  Formik,
  useNavigate,
  useLocation,
  useParams,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { FormikControl } from "components/form/FormikControl";
import { SIGNIN_ROUTE } from "constants/Routes";
import { KYC_UPDATE_ENDPOINT } from "constants/Endpoints";
import { useAppDispatch } from "service/hook/Hooks";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { capitalizeWords } from "service/helper/UserFunctionsHelper";
import { countries, STATE_OF_ORIGIN } from "utils/StateCountryCodes";
import { customFetch } from "utils/Axios";
import {
  editDetailsFormValidator,
  FIRST_NAME,
  MIDDLE_NAME,
  STATE,
  COUNTRY,
  PHONE_NUMBER,
  LAST_NAME,
  DATE_OF_BIRTH,
  EMAIL,
  ADDRESS,
} from "utils/validators/EditDetailsFormValidator";
import { theme } from "utils/Theme";
import dayjs from "dayjs";

interface FormikProps {
  field: { [key: string]: any };
  meta: { [key: string]: any };
  form: { [key: string]: any };
}

const EditBalanceDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const goBack = () => {
    navigate(-1);
  };
  const { id } = useParams();

  const handleSubmit = async (values: { [key: string]: any }) => {
    if (id) {
      try {
        const resp = await customFetch.patch(
          `${KYC_UPDATE_ENDPOINT}?id=${id}`,
          {
            first_name: values.first_name,
            last_name: values.last_name,
            middle_name: values.middle_name || undefined,
            email: values.email,
            phone_number: values.phone_number,
            date_of_birth: dayjs(new Date(values.date_of_birth)).format(
              "YYYY-MM-DD"
            ),
            country: values.country.length > 2 ? "NG" : state?.country,
            state: capitalizeWords(values.state).substring(0, 2),
            address: values.address,
          }
        );
        toast.success(`Details edited successfully`);
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(userLogoutAction());
          return navigate(SIGNIN_ROUTE);
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          error?.response?.data?.error?.server
            ? toast.error(error?.response?.data?.error?.server[0])
            : toast.error("Update failed");
        }
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error)
          : toast.error("Update failed");
      }
    }
  };

  const editDetailsInitialValues = {
    first_name: state?.first_name || "",
    last_name: state?.last_name || "",
    middle_name: state?.middle_name || "",
    phone_number: state?.phone_number || "",
    date_of_birth: state?.date_of_birth || "",
    state: state?.state || "",
    address: state?.address || "",
    country: state?.country || "",
    email: state?.email || "",
  };

  return (
    <Box
      width={"100%"}
      height={"auto"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography
        sx={{
          color: "#0C120C",
          textAlign: "left",
          width: "100%",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          cursor: "pointer",
        }}
        onClick={goBack}
      >
        <ArrowBackIosNew
          sx={{ fontSize: "small", marginRight: "10px", cursor: "pointer" }}
        />{" "}
        Edit balance details
      </Typography>
      <Paper
        elevation={2}
        sx={{
          py: 6,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "inherit",
          }}
        >
          <Formik
            initialValues={editDetailsInitialValues}
            validationSchema={editDetailsFormValidator}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <Form placeholder={undefined}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "900px",
                      height: "465px",
                    }}
                  >
                    <Stack direction={"column"} height={"inherit"}>
                      <Field name={FIRST_NAME}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="input"
                              label="First name"
                              name={FIRST_NAME}
                              value={formik?.values?.first_name.replace(
                                /[^a-z]/gi,
                                ""
                              )}
                              onChange={formik.handleChange(FIRST_NAME)}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                      <Field name={MIDDLE_NAME}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              control="input"
                              label="Middle name"
                              name={MIDDLE_NAME}
                              value={
                                formik?.values?.middle_name
                                  ? formik?.values?.middle_name.replace(
                                      /[^a-z]/gi,
                                      ""
                                    )
                                  : null
                              }
                              onChange={formik.handleChange(MIDDLE_NAME)}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                      <Field name={STATE}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="select"
                              label="State"
                              name={STATE}
                              value={formik?.values?.state.replace(
                                /[^a-z]/gi,
                                ""
                              )}
                              onChange={formik.handleChange(STATE)}
                              field={field}
                              meta={meta}
                              form={form}
                              options={STATE_OF_ORIGIN}
                            />
                          );
                        }}
                      </Field>
                      <Field name={COUNTRY}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="select"
                              label="Country"
                              name={COUNTRY}
                              value={formik?.values?.country.replace(
                                /[^a-z]/gi,
                                ""
                              )}
                              onChange={formik.handleChange(COUNTRY)}
                              field={field}
                              meta={meta}
                              form={form}
                              options={countries}
                            />
                          );
                        }}
                      </Field>
                      <Field name={PHONE_NUMBER}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="input"
                              label="Phone number"
                              name={PHONE_NUMBER}
                              value={formik?.values?.phone_number.replace(
                                /\D/g,
                                ""
                              )}
                              maxLength={14}
                              onChange={formik.handleChange(PHONE_NUMBER)}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                    </Stack>
                    <Stack direction={"column"} height={"inherit"}>
                      <Field name={LAST_NAME}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="input"
                              label="Last name"
                              name={LAST_NAME}
                              value={formik?.values?.last_name.replace(
                                /[^a-z]/gi,
                                ""
                              )}
                              onChange={formik.handleChange(LAST_NAME)}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                      <Field name={DATE_OF_BIRTH}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              control="date"
                              type="date"
                              label="Date of birth"
                              name={DATE_OF_BIRTH}
                              value={formik?.values?.date_of_birth}
                              onChange={(DATE_OF_BIRTH: any) =>
                                formik.setFieldValue(
                                  "date_of_birth",
                                  DATE_OF_BIRTH
                                )
                              }
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                      <Field name={EMAIL}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="input"
                              type="email"
                              label="Email"
                              name={EMAIL}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                      <Field name={ADDRESS}>
                        {({ field, meta, form }: FormikProps) => {
                          return (
                            <FormikControl
                              required=" *"
                              control="input"
                              type="address"
                              label="Address"
                              name={ADDRESS}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                    </Stack>
                  </Box>
                  <Box sx={{ marginTop: "50px" }}>
                    <Stack
                      direction="row"
                      width={"inherit"}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <CustomButton
                        color="secondary"
                        variant="contained"
                        type="submit"
                        sx={{
                          border: `1px solid ${theme.palette.common.black}`,
                          marginRight: "10px",
                          background: "black",
                          color: "white",
                        }}
                        disabled={!formik.dirty || !formik.isValid}
                      >
                        Submit
                      </CustomButton>
                      <CustomButton
                        color="secondary"
                        variant="contained"
                        sx={{
                          marginLeft: "10px",
                          background: "white",
                          color: "black",
                          border: "1px solid black",
                          "&:hover": {
                            background: "rgba(0, 0, 0, 0.05)",
                          },
                        }}
                        onClick={goBack}
                      >
                        Cancel
                      </CustomButton>
                    </Stack>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default EditBalanceDetails;
