import React, { useEffect, useState } from "react";
import ProfileImage from "assets/images/dvaultAssets/avatar.png";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
  Close,
  getName,
  useNavigate,
  Theme,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { BALANCE_DETAILS_EDIT_ROUTE } from "constants/Routes";
import UpdateSelfieForm from "pages/dvault/merchantVerification/UpdateSelfieForm";
import {
  capitalizeFirstLetter,
  convertCodeToState,
  formattedDate,
} from "utils/Helpers";

interface InfoDetailsProps {
  [key: string]: any;
}

const tierList = ["BASIC", "STARTUP", "BUSINESS", "BORDERLESS"];

function InfoDetails({
  first_name,
  last_name,
  middle_name,
  date_of_birth,
  state,
  email,
  country,
  address,
  phone_number,
  id: kycID,
  updated_at,
  passport_photo,
  id_cards,
  tier,
}: InfoDetailsProps) {
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const [openUpdateImage, setOpenUpdateImage] = useState<boolean>(false);

  const handleUpdateImageDialogOpen = () => {
    setOpenUpdateImage(true);
  };

  const handleUpdateImageDialogClose = () => {
    setOpenUpdateImage(false);
  };

  useEffect(() => {
    if (updated_at) {
      formattedDate(updated_at);
    }
  }, [updated_at]);

  return (
    <Paper sx={{ padding: "30px" }}>
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={2}
        justifyContent={"flex-start"}
      >
        <Typography
          variant="h3"
          sx={{
            color: `${theme.palette.common.darkBlue}`,
            fontWeight: "500",
            fontSize: "2rem",
          }}
        >
          {last_name
            ? `${capitalizeFirstLetter(first_name)} ${capitalizeFirstLetter(
                last_name
              )}`
            : " - "}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            color: `${theme.palette.common.darkBlue}`,
            fontWeight: "500",
            fontSize: "1rem",
          }}
        >
          Tier: {tier === 0 ? 0 : tierList[tier - 1]}
        </Typography>
      </Stack>
      <Grid
        container
        justifyContent={"space-between"}
        columnSpacing={1}
        sx={{
          my: 5,
        }}
      >
        <Grid item>
          <Box
            sx={{
              maxWidth: "400px",
            }}
          >
            <Box
              component="img"
              src={passport_photo || ProfileImage}
              alt={"Profile"}
              sx={{
                width: "300px",
                objectFit: "fill",
                height: "280px",
                overflow: "hidden",
                borderRadius: "15px",
                marginBottom: "20px",
              }}
            />
          </Box>
          <Box>
            <CustomButton
              variant="outlined"
              color="secondary"
              onClick={handleUpdateImageDialogOpen}
              sx={{
                fontWeight: 500,
              }}
            >
              Update image
            </CustomButton>
            <Dialog
              open={openUpdateImage}
              onClose={handleUpdateImageDialogClose}
              PaperProps={{
                sx: {
                  borderRadius: "16px",
                },
              }}
            >
              <Paper
                sx={{
                  width: "500px",
                  p: 4,
                }}
              >
                <Close
                  sx={{
                    display: "block",
                    fontSize: "2.5rem",
                    marginLeft: "auto",
                    color: `${theme.palette.common.lightBlue}`,
                  }}
                  onClick={handleUpdateImageDialogClose}
                />
                <Typography
                  variant="h4"
                  color="initial"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Update image
                </Typography>
                <DialogContent
                  sx={{
                    "&.MuiDialogContent-root": {
                      padding: 0,
                    },
                  }}
                >
                  <Box>
                    <UpdateSelfieForm
                      handleClose={handleUpdateImageDialogClose}
                      first_name={first_name}
                      kycID={kycID}
                      passport_photo={passport_photo}
                      idCards={id_cards}
                    />
                  </Box>
                </DialogContent>
              </Paper>
            </Dialog>
          </Box>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                First name
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {first_name ? `${capitalizeFirstLetter(first_name)}` : " - "}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Date of birth
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {date_of_birth}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Country
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {country ? getName(country) : " - "}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Updated at
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {updated_at !== "" && formattedDate(updated_at)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Last name
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {last_name ? `${capitalizeFirstLetter(last_name)}` : " - "}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                State
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {convertCodeToState(state)}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Address
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                  maxWidth: "200px",
                }}
              >
                {address}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Middle name
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {middle_name || " - "}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Email
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {email ? capitalizeFirstLetter(email) : ""}
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.lightBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                Phone number
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: `${theme.palette.common.darkBlue}`,
                  fontWeight: 500,
                  my: 0.5,
                }}
              >
                {phone_number}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          minWidth: 120,
          display: "block",
          marginLeft: "auto",
          width: 123,
        }}
      >
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={() =>
            navigate(BALANCE_DETAILS_EDIT_ROUTE, {
              state: {
                first_name,
                last_name,
                middle_name,
                date_of_birth,
                state,
                email,
                country,
                address,
                phone_number,
              },
            })
          }
          sx={{
            marginLeft: "10px",
            background: "white",
            color: "black",
            border: "1px solid black",
            "&:hover": {
              background: "rgba(0, 0, 0, 0.05)",
            },
          }}
        >
          Edit details
        </CustomButton>
      </Box>
    </Paper>
  );
}

export default InfoDetails;
