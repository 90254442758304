export const DvaultRoles = ["verification_officer"];

export const TmsRoles = [
  "admin",
  "platform_owner",
  "reconciliation_officer",
  "qa_user",
  "api_user",
];

export const AmsRoles = [
  "admin",
  "platform_owner",
  "qa_user",
  "api_user",
  "terminal_officer",
];

let sum: boolean;
let num: boolean;

export const canUserUseDVAULT = (role: string[]) => {
  role.map((el, index) => {
    index < role.length && DvaultRoles.includes(el)
      ? (sum = true)
      : (num = false);
  });
  return sum || num;
};

export const canUserUseTMS = (role: string[]) => {
  role.map((el, index) => {
    index < role.length && TmsRoles.includes(el) ? (sum = true) : (num = false);
  });
  return sum || num;
};

export const canUserUseAMS = (role: string[]) => {
  role.map((el, index) => {
    index < role.length && AmsRoles.includes(el) ? (sum = true) : (num = false);
  });
  return sum || num;
};

// export const canUserInputShipmentValue = (role: string | undefined) => {
//   switch (role) {
//     case 'SUPER_ADMIN':

//       return true;
//     default:

//       return false;
//   }
// };

// Validates if a terminalShipment User can make any modifications based on their role
// export const canUserMakeShipmentModification = (role: string | undefined) => {
//   switch (role) {
//     case 'SUPER_ADMIN':

//       return true;
//     case 'HYBRID_ADMIN':

//       return true;
//     case 'TMS_ADMIN':

//       return true;
//     default:
//       return false;
//   }
// };
// Validates if a terminalShipment User can make any modifications based on their role
