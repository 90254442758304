import React from "react";
import {
  InputAdornment,
  Search,
  TextField,
} from "components/common/ExternalComponents";
import { changePageNumber } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { SearchData } from "utils/types/CommonTypes";

const SearchBar = ({
  setPage,
  setLocalSearch,
  localSearch,
  onChange,
  border,
  border2,
  height2,
}: SearchData) => {
  const dispatch = useAppDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(e.target.value);
    setPage(0);
    dispatch(changePageNumber(0));
  };

  return (
    <TextField
      id="localSearch"
      value={localSearch}
      onChange={handleChange || onChange}
      sx={{
        width: "inherit",
        "& .MuiOutlinedInput-root": {
          height: "39px",
          border: { border },
        },
        "& .MuiOutlinedInput-input": {
          height: "0.4rem",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border2: { border2 },
          height2: { height2 },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        placeholder: "Search",
      }}
    />
  );
};

export default SearchBar;
