import { theme } from "utils/Theme";

export const landingPageStyles = {
  container: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: "60%",
    mx: "auto",
  },
  linearProgress: {
    "&.MuiLinearProgress-root": {
      backgroundColor: theme.palette.common.primaryColor,
    },
  },
};

export const formLabelStyle = { ml: 4, fontWeight: 600 };

export const globalStyles = {
  formLabelStyle: { ml: 4, fontWeight: 600 },
  tableHeadCellStyle: {
    borderBottom: `1px solid ${theme.palette.common.textColor}`,
    borderTop: `1px solid ${theme.palette.common.textColor}`,
    color: "black",
  },
  activeTabLink: {
    color: "black",
    textDecoration: "none",
    fontWeight: "800",
  },
  tabLink: {
    color: "black",
    textDecoration: "none",
    fontWeight: "500",
  },
  flexSpaceBtw: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableCellPadding: {
    "& .MuiTableCell-root": {
      py: "0.9rem",
      px: "1rem",
    },
  },
};
