import React, { useEffect, useState } from "react";
import VerifiedLogo from "assets/images/dvaultAssets/verifiedLogo.svg";
import UnverifiedLogo from "assets/images/dvaultAssets/unverifiedLogo.svg";
import flaggedLogo from "assets/images/dvaultAssets/flaggedLogo.svg";
import IndicatorCard from "components/others/IndicatorCard";
import { Grid, toast, useNavigate } from "components/common/ExternalComponents";
import { KYC_METRICS_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";
import { IndicatorData } from "utils/types/CommonTypes";

function Indicators() {
  const [indicatorsData, setIndicatorsData] = useState<IndicatorData[] | null>(
    []
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getMetrics = async () => {
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.get(KYC_METRICS_ENDPOINT);
      setIndicatorsData([
        {
          id: 1,
          image: VerifiedLogo,
          total: resp?.data?.verified,
          bgColor: theme.palette.common.green,
          text: "Total verified",
        },
        {
          id: 2,
          image: UnverifiedLogo,
          total: resp?.data?.unverified,
          bgColor: theme.palette.common.purple,
          text: "Total unverified",
        },
        {
          id: 3,
          image: flaggedLogo,
          total: resp?.data?.unverified,
          bgColor: theme.palette.common.flagRed,
          text: "Total flagged",
        },
      ]);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        sx={{
          marginTop: "30px",
        }}
      >
        {indicatorsData &&
          indicatorsData.map((data) => {
            const { id, image, total, bgColor, text } = data;
            return (
              <Grid
                item
                xs={6}
                md={3.5}
                key={id}
                sx={{
                  maxWidth: "300px",
                  marginRight: "40px",
                }}
              >
                <IndicatorCard
                  image={image}
                  total={total}
                  bgColor={bgColor}
                  text={text}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default Indicators;
