import React from "react";
import {
  Typography,
  Breadcrumbs,
  useLocation,
} from "components/common/ExternalComponents";
import CustomLink from "components/links/Link";
import { capitalizeFirstLetter } from "utils/Helpers";

export default function Breadcrumb() {
  const location = useLocation();
  const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "");

  return (
    <Breadcrumbs maxItems={2} separator="›" aria-label="breadcrumb">
      {crumbs.map((element, index) => {
        let isLast = crumbs.length - 1;
        let routeTo = `/${crumbs.slice(0, index + 1).join("/")}`;
        if (element.length === 36) return "";
        return index === isLast ? (
          <Typography
            key={index}
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              color: "#1B1C1E",
            }}
          >
            {capitalizeFirstLetter(element)}
          </Typography>
        ) : (
          <CustomLink
            styles={{
              fontWeight: 500,
              fontSize: "18px",
              color: "#8c8c8c",
              textDecoration: "none",
              "&:hover": {
                color: "#1B1C1E",
                cursor: "pointer",
              },
            }}
            key={index}
            route={routeTo}
          >
            {capitalizeFirstLetter(element)}
          </CustomLink>
        );
      })}
    </Breadcrumbs>
  );
}
