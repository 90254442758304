import React from "react";
import {
  FormControl,
  TextField,
  FormLabel,
  Typography,
  ErrorMessage,
} from "components/common/ExternalComponents";
import { TextError } from "components/form/TextError";

interface InputProp {
  [key: string]: any;
}

export const Input3 = (props: InputProp) => {
  const {
    label,
    name,
    type,
    error,
    meta,
    field,
    form,
    background,
    required,
    helperText,
    maxLength,
    defaultValue,
    value,
    ...rest
  } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        "& .css-13nvzlm-MuiFormHelperText-root": {
          fontSize: "13px",
          ml: 0,
        },
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          display: "block",
          py: 1,
          color: "common.grey",
          fontWeight: 500,
          paddingBottom: "10px",
          lineHeight: "20px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <TextField
        id={props.name}
        type={type || "text"}
        name={name}
        helperText={helperText}
        value={value}
        {...field}
        {...rest}
        inputProps={{
          autoComplete: "off",
          maxLength: maxLength,
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "35px",
            width: "400px",
            color: "black",
            fontSize: "14px",
            background: `${background || "#F5f5f5"}`,
            ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
      />
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
