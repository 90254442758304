import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Field,
  Formik,
  Form,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import { FormikControl } from "components/form/FormikControl";
import CustomButton from "components/buttons/Button";
import { CustomModal } from "components/modals/Modal";
import {
  UPDATE_TERMINAL_ENDPOINT,
  WALLET_LIST_ENDPOINT,
} from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { updateTerminalValidator } from "utils/validators/TerminalValidator";
import { theme } from "utils/Theme";
import { customFetch } from "utils/Axios";

interface FormikProps {
  field: { [key: string]: any };
  meta: { [key: string]: any };
  form: { [key: string]: any };
  [key: string]: any;
}

interface UpdateTerminalProps {
  [key: string]: any;
}

function UpdateTerminal({
  element,
  handleInfoClose,
  updateTerminalList,
  setUpdateTerminalList,
}: UpdateTerminalProps) {
  const [localSearch, setLocalSearch] = useState<string>("");
  const [openWalletDialog, setOpenWalletDialog] = useState<boolean>(false);
  const [walletID, setWalletID] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: { [key: string]: any }) => {
    if (element) {
      dispatch(globalLoadingAction(true));
      try {
        const resp = await customFetch.patch(
          `${UPDATE_TERMINAL_ENDPOINT}?id=${element?.sn}`,
          {
            tid: values?.terminalID,
            wallet: values?.wallet || walletID,
            serial_number: values?.serialNumber,
          }
        );
        toast.success(`Terminal linked successfully`);
        setOpenWalletDialog(false);
        setUpdateTerminalList(!updateTerminalList);
        handleInfoClose();
        dispatch(globalLoadingAction(false));
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(userLogoutAction());
          return navigate(SIGNIN_ROUTE);
        }
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.message)
          : toast.error("An error occurred");
        dispatch(globalLoadingAction(false));
      }
    }
  };

  const handleWalletOpen = () => {
    setOpenWalletDialog(true);
  };

  return (
    <Formik
      initialValues={{
        terminalID: element?.extraDetails[0].tid,
        serialNumber: element?.extraDetails[0].serial_number,
        wallet: element?.extraDetails[0].wallet_id,
      }}
      validationSchema={updateTerminalValidator}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Form placeholder={undefined}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Field name="terminalID">
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      control="input"
                      background="null"
                      label="Terminal ID"
                      name="terminalID"
                      value={formik?.values?.terminalID}
                      onChange={formik.handleChange}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Field name="serialNumber">
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      control="input"
                      background="null"
                      label="Serial number"
                      name="serialNumber"
                      value={formik?.values?.serialNumber}
                      onChange={formik.handleChange}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Box onClick={handleWalletOpen} width={"100%"}>
                <Field name="wallet">
                  {({ field, meta, form }: FormikProps) => {
                    return (
                      <FormikControl
                        required={" *"}
                        control="input"
                        background="null"
                        label="Wallet"
                        name="wallet"
                        onBlur={formik.handleBlur}
                        value={formik?.values?.wallet}
                        field={field}
                        meta={meta}
                        form={form}
                      />
                    );
                  }}
                </Field>
              </Box>
              <CustomModal
                formik={formik}
                setOpenDialog={setOpenWalletDialog}
                setID={setWalletID}
                openDialog={openWalletDialog}
                url={WALLET_LIST_ENDPOINT}
                text={"wallet"}
                localSearch={localSearch}
                setLocalSearch={setLocalSearch}
              />
            </Box>
            <Box>
              <Stack direction="row" justifyContent={"flex-end"} mt={2}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  sx={{
                    marginRight: "10px",
                    background: "white",
                    border: `1px solid ${theme.palette.common.black}`,
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.05)",
                    },
                  }}
                  onClick={() => handleInfoClose()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!formik.dirty}
                  sx={{
                    background: "black",
                    color: "white",
                  }}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: "common.white",
                        margin: "0px 10px",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </CustomButton>
              </Stack>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default UpdateTerminal;
