import { Yup } from "components/common/ExternalComponents";

export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const MIDDLE_NAME = "middle_name";
export const EMAIL = "email";
export const STATE = "state";
export const DATE_OF_BIRTH = "date_of_birth";
export const COUNTRY = "country";
export const ADDRESS = "address";
export const PHONE_NUMBER = "phone_number";

export const editDetailsFormValidator = () =>
  Yup.object({
    [FIRST_NAME]: Yup.string()
      .required("First name is required")
      .min(3, "Must be at least 3 characters"),
    [LAST_NAME]: Yup.string()
      .required("Last name is required")
      .min(3, "Must be at least 3 characters"),
    [MIDDLE_NAME]: Yup.string().min(3, "Must be at least 3 characters"),
    [PHONE_NUMBER]: Yup.string()
      .required("Phone number is required")
      .max(14, "Must not be more than 14 characters"),
    [STATE]: Yup.string().required("State is required"),
    [ADDRESS]: Yup.string().required("Address is required"),
    [COUNTRY]: Yup.string().required("Country is required"),
    [EMAIL]: Yup.string()
      .required("Email is a required field")
      .email("Email must be a valid email"),
    [DATE_OF_BIRTH]: Yup.date()
      .typeError("Date is not valid")
      .required("Date of birth is required")
      .max(new Date(), "Future Dates are not valid"),
  });
