import React from "react";
import simone from "assets/images/navigationAssets/simone.svg";
import CustomizedAccordions from "components/nav/Accordion";
import { Stack, Box } from "components/common/ExternalComponents";

const SideBar: React.FC = () => {
  return (
    <Box sx={{ px: 2, marginTop: "20px" }}>
      <Stack>
        <Box
          component="img"
          alt="Dvault"
          src={simone}
          sx={{
            width: "180px",
            margin: "0 auto",
          }}
        />
      </Stack>
      <Box mt="50px">
        <CustomizedAccordions />
      </Box>
    </Box>
  );
};
export default SideBar;
