import {
  createTheme,
  PaletteColorOptions,
  Theme,
  SxProps,
  CSSObject,
  outlinedInputClasses,
} from "components/common/ExternalComponents";

declare module "@mui/material/styles" {
  interface Theme {
    common: CommonColors;
    breakpoints: {
      down(arg0: string): string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    [key: string]: any;
  }

  interface CommonColors {
    red: string;
    green: string;
    purple: string;
    purpleIcon: string;
    greenIcon: string;
    flagRed: string;
    flagIcon: string;
    linkRedBg: string;
    darkBlue: string;
    lightBlue: string;
    black: string;
    white: string;
    background: string;
    prdPur: string;
    prdT: string;
    [key: string]: any;
  }

  interface PaletteOptions {
    accent?: PaletteColorOptions;
  }

  interface ThemeOptions {
    unstable_sx?: (props: SxProps<Theme>) => CSSObject;
  }
}

export const theme: Theme = createTheme({
  palette: {
    common: {
      red: "#E97D80",
      green: "#A5D6A7",
      purple: "#FFD8E4",
      flagRed: "#FFCCBC",
      flagIcon: "#BF360C",
      linkRedBg: "#FFEDED",
      darkBlue: "#032443",
      lightBlue: "#8CA1B6",
      black: "#000000",
      white: "#ffffff",
      background: "#F3F6F9",
      prdPur: "#E8DEF8",
      prdT: "#2A2A44",
      dateColor: "#333333",
      primaryColor: "#dbdbdb",
      secondaryColor: "#3cdfff",
      textColor: "#7d7e7e",
      successColor: "#02b517",
      tableBodyColor: "#7C8599",
      greyColor: "#464E5F",
      insetBoxShadow:
        "-1px -5px 0px 2px rgb(216 216 216 / 10%), 1px -2px 1px rgb(0 0 0 / 10%), inset 1px 0px 1px rgb(0 0 0/10%)",
    },
    primary: {
      main: "#E97D80",
    },
    secondary: {
      main: "#000000",
    },
    accent: {
      main: "#8CA1B6",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Poppins"].join(","),
    button: {
      textTransform: "capitalize",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#6F7E8C",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#E0E3E7",
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#B2BAC2",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#6F7E8C",
          },
        },
      },
    },
  },
});
