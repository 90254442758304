import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import DateComponent from "components/date/DateComponent";
import SearchBar from "components/search/SearchBar";
import DownloadBtn from "components/buttons/DownloadButton";
import { CustomModal } from "components/modals/Modal";
import Dropdown from "components/dropdown/Dropdown";
import WalletDetails from "components/wallet/WalletDetail";
import FilterBtn from "components/buttons/filter/FilterButton";
import { SIGNIN_ROUTE } from "constants/Routes";
import {
  TERMINAL_LIST_ENDPOINT,
  TRANSACTION_HISTORY_LIST_ENDPOINT,
  WALLET_LIST_ENDPOINT,
  WALLET_TOTAL_ENDPOINT,
} from "constants/Endpoints";
import PrdHistoryTable from "pages/tms/prd/PrdHistoryTable";
import PrdDetailedViewIndicators from "pages/tms/prd/PrdDetailedViewIndicators";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { customFetch } from "utils/Axios";
import { dateConverter2, formattedDate, formatter } from "utils/Helpers";
import { DateRange, PrdData } from "utils/types/CommonTypes";
import { theme } from "utils/Theme";

function createData(
  dateOfRegistration: string,
  sender: string,
  wallet_balance_before: any,
  amount: string,
  charges_amount: any,
  wallet_balance_after: any,
  description: string,
  type: string,
  payment_method: string,
  terminal_id: string,
  card_number: string,
  reference_number: string,
  status: string
): PrdData {
  return {
    dateOfRegistration,
    sender,
    type,
    payment_method,
    amount,
    charges_amount,
    description,
    terminal_id,
    card_number,
    reference_number,
    status,
    wallet_balance_after,
    wallet_balance_before,
  };
}

interface PrdHistoryTableData {
  created_at: string;
  sender: string;
  reference_id: string;
  amount: number;
  extra_data: {
    [key: string]: any;
  };
  type: string;
  charges_amount: any;
  description: string;
  payment_method: string;
  status: string;
  wallet_balance_after: any;
  wallet_balance_before: any;
}

const PrdDetailedView = () => {
  const [anchorElFilter, setAnchorElFilter] = useState<null>(null);
  const [platformId, setPlatformId] = useState<string>("");
  const [localSearch, setLocalSearch] = useState("");
  const [localSearchWallet, setLocalSearchWallet] = useState("");
  const { pageNumber } = useAppSelector((store) => store.profileSliceReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [localSearchTerminal, setLocalSearchTerminal] = useState("");
  const [openWalletDialog, setOpenWalletDialog] = useState<boolean>(false);
  const [openTerminalDialog, setOpenTerminalDialog] = useState<boolean>(false);
  const [terminalID, setTerminalID] = useState<string>("");
  const [downloadStatusFilter, setDownloadStatusFilter] = useState<string>("");
  const [downloadMethodFilter, setDownloadMethodFilter] = useState<string>("");
  const [downloadTypeFilter, setDownloadTypeFilter] = useState<string>("");
  const [walletID, setWalletID] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [walletTotal, setWalletTotal] = useState<{ [key: string]: any }>();
  const [allTransactions, setAllTransactions] = useState([]);
  const [status, setStatus] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [transactionType, setTransactionType] = useState<string>("");
  const [walletOrTerminal, setWalletOrTerminal] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let url = `${TRANSACTION_HISTORY_LIST_ENDPOINT}?page=${
    page + 1
  }&page_size=${rowsPerPage}`;

  const getWalletTotal = async () => {
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.get(WALLET_TOTAL_ENDPOINT);
      setWalletTotal(resp?.data);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      } else if (
        error.response.status === 404 ||
        error.response.status === 500
      ) {
        error?.response?.data?.error?.server
          ? toast.error(error?.response?.data?.error?.server[0])
          : toast.error("An error ocurred");
      } else {
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error?.gateway[0])
          : toast.error("An error ocurred");
      }
      dispatch(globalLoadingAction(false));
    }
  };

  const getAllTransactions = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (platformId) {
        url = url + `&platform_id=${platformId}`;
      }
      if (terminalID || walletID) {
        if (walletOrTerminal === "terminal") {
          url = url + `&terminal_id=${terminalID}`;
          setWalletID("");
        } else if (walletOrTerminal === "wallet") {
          url = url + `&wallet_id=${walletID}`;
        } else {
          setTerminalID("");
          setWalletID("");
        }
      }
      if (status === "SUCCESS") {
        url = url + `&status=SUCCESS`;
      }
      if (status === "FAILED") {
        url = url + `&status=FAILED`;
      }
      if (status === "INITIALIZED") {
        url = url + `&status=INITIALIZED`;
      }
      if (transactionType === "CREDIT") {
        url = url + `&type=CREDIT`;
      }
      if (transactionType === "DEBIT") {
        url = url + `&type=DEBIT`;
      }
      if (paymentMethod === "CARD") {
        url = url + `&payment_method=CARD`;
      }
      if (paymentMethod === "BALANCE") {
        url = url + `&payment_method=BALANCE`;
      }
      if (paymentMethod === "BANK_TRANSFER") {
        url = url + `&payment_method=BANK_TRANSFER`;
      }
      if (dateRange.startDate && dateRange.endDate) {
        url =
          url +
          `&created_at=${dateConverter2(dateRange.startDate)},${dateConverter2(
            dateRange.endDate
          )}`;
      }
      const resp = await customFetch.get(url);
      const newData = resp?.data?.results?.map(
        (element: PrdHistoryTableData) => {
          const {
            created_at,
            sender,
            wallet_balance_before,
            amount,
            wallet_balance_after,
            type,
            description,
            payment_method,
            extra_data,
            status,
            charges_amount,
          } = element;

          return createData(
            formattedDate(created_at),
            sender,
            wallet_balance_before
              ? formatter.format(wallet_balance_before)
              : formatter.format(0),
            amount ? formatter.format(amount) : formatter.format(0),
            charges_amount
              ? formatter.format(charges_amount)
              : formatter.format(0),
            wallet_balance_after
              ? formatter.format(wallet_balance_after)
              : formatter.format(0),
            description,
            type,
            payment_method,
            extra_data.terminalId ? extra_data.terminalId : "-----",
            extra_data.cardNumber ? extra_data.cardNumber : "-----",
            extra_data.referenceNumber ? extra_data.referenceNumber : "-----",
            status
          );
        }
      );
      setAllTransactions(newData);
      setTotalCount(resp?.data?.count);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    getWalletTotal();
  }, []);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllTransactions();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [
    page,
    rowsPerPage,
    localSearch,
    platformId,
    terminalID,
    walletID,
    status,
    paymentMethod,
    transactionType,
    dateRange,
    walletOrTerminal,
  ]);

  return (
    <>
      <Box
        sx={{
          marginBottom: "10px",
        }}
      >
        <Typography
          sx={{ color: theme.palette.common.dateColor, marginBottom: ".6rem" }}
        >
          Date
        </Typography>
        <DateComponent
          setPage={setPage}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Box>

      <PrdDetailedViewIndicators
        terminalID={terminalID}
        dateRange={dateRange}
        totalCount={totalCount}
        walletTotal={walletTotal}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px",
          border: "1px solid #E8EBEE",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            width: "fit-content",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={4}
            py={1.8}
          >
            <SearchBar
              localSearch={localSearch}
              setLocalSearch={setLocalSearch}
              setPage={setPage}
            />

            <DownloadBtn
              url={TRANSACTION_HISTORY_LIST_ENDPOINT}
              dateRange={dateRange}
              localSearch={localSearch}
              platformId={platformId}
              terminalID={terminalID}
              walletID={walletID}
              downloadMethodFilter={downloadMethodFilter}
              downloadStatusFilter={downloadStatusFilter}
              downloadTypeFilter={downloadTypeFilter}
            />
          </Stack>
        </Box>

        <Box
          sx={{
            paddingRight: "32px",
          }}
        >
          <Stack direction={"row"}>
            <Box
              sx={{
                mr: "20px",
              }}
            >
              <Dropdown
                text={"Get platform table"}
                setPlatformId={setPlatformId}
              />
            </Box>
            <FilterBtn
              setPage={setPage}
              options={["By Terminal", "By Wallet", "Reset"]}
              setOpenWalletDialog={setOpenWalletDialog}
              setOpenTerminalDialog={setOpenTerminalDialog}
              setWalletOrTerminal={setWalletOrTerminal}
            />
            <CustomModal
              setPage={setPage}
              setOpenDialog={setOpenTerminalDialog}
              setID={setTerminalID}
              setAnchorElFilter={setAnchorElFilter}
              openDialog={openTerminalDialog}
              url={TERMINAL_LIST_ENDPOINT}
              text="terminal"
              localSearch={localSearchTerminal}
              setLocalSearch={setLocalSearchTerminal}
              setWalletOrTerminal={setWalletOrTerminal}
            />
            <CustomModal
              text="wallet"
              setPage={setPage}
              setOpenDialog={setOpenWalletDialog}
              setID={setWalletID}
              setAnchorElFilter={setAnchorElFilter}
              openDialog={openWalletDialog}
              url={WALLET_LIST_ENDPOINT}
              localSearch={localSearchWallet}
              setLocalSearch={setLocalSearchWallet}
              setWalletOrTerminal={setWalletOrTerminal}
            />
          </Stack>
        </Box>
      </Box>

      {walletID && <WalletDetails walletID={walletID} />}

      <PrdHistoryTable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        setDownloadStatusFilter={setDownloadStatusFilter}
        setDownloadTypeFilter={setDownloadTypeFilter}
        setDownloadMethodFilter={setDownloadMethodFilter}
        allTransactions={allTransactions}
        setStatus={setStatus}
        setPaymentMethod={setPaymentMethod}
        setTransactionType={setTransactionType}
      />
    </>
  );
};

export default PrdDetailedView;
