import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import SearchBar from "components/search/SearchBar";
import FilterBtn from "components/buttons/filter/FilterButton";
import { SIGNIN_ROUTE } from "constants/Routes";
import { POS_REQUEST_ENDPOINT } from "constants/Endpoints";
import TRDRow from "pages/ams/trd/TrdRow";
import TRDIndicators from "pages/ams/trd/TrdIndicators";

import { globalLoadingAction } from "service/store/GlobalSlice";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import {
  changePageNumber,
  userLogoutAction,
} from "service/store/UserProfileSlice";
import { customFetch } from "utils/Axios";
import { Column, RequestProps } from "utils/types/CommonTypes";
import { capitalizeFirstLetter, formattedDate } from "utils/Helpers";

const columns: Column[] = [
  { id: "sn", label: " ", minWidth: 50, align: "left" },
  { id: "merchantName", label: "Merchant Name", align: "left" },
  { id: "businessName", label: "Business Name", align: "left" },
  { id: "businessCategory", label: "Business category", align: "left" },
  { id: "phoneNumber", label: "Phone number", align: "left" },
  { id: "referralCode", label: "Referral code", align: "center" },
  { id: "requestStatus", label: "Request Status", align: "left" },
];

const columnsExtra: Column[] = [
  { id: "status", label: "Status", minWidth: 200, align: "center" },
  { id: "address", label: "Address", minWidth: 200, align: "left" },
  {
    id: "statusCreatedDate",
    label: "Date created",
    minWidth: 130,
    align: "left",
  },
  {
    id: "statusUpdateDate",
    label: "Date updated",
    minWidth: 130,
    align: "left",
  },
  {
    id: "statusUpdatedBy",
    label: "Updated by",
    minWidth: 200,
    align: "center",
  },
  {
    id: "terminalSerialNo",
    label: "Terminal serial no",
    minWidth: 200,
    align: "left",
  },
];

function createData(
  sn: string,
  merchantName: string,
  businessName: string,
  businessCategory: string,
  phoneNumber: string,
  referralCode: string,
  requestStatus: string,
  address: string,
  statusCreatedDate: string,
  statusUpdateDate: string,
  statusUpdatedBy: string,
  terminalSerialNumber: string
) {
  return {
    sn,
    merchantName,
    businessName,
    businessCategory,
    phoneNumber,
    referralCode,
    requestStatus,
    extraDetails: [
      {
        status: requestStatus,
        address: address,
        statusCreatedDate: statusCreatedDate,
        statusUpdateDate: statusUpdateDate,
        statusUpdatedBy: statusUpdatedBy,
        terminalSerialNo: terminalSerialNumber,
      },
    ],
  };
}

function TerminalRequest() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useAppSelector((store) => store.profileSliceReducer);
  const currentID = useRef("");
  const theme = useTheme();
  const [page, setPage] = useState<number | any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [requestStatus, setRequestStatus] = useState("");
  const [updateTerminalRequest, setUpdateTerminalRequest] =
    useState<boolean>(false);
  const [terminals, setAllTerminals] = useState([]);
  const [localSearch, setLocalSearch] = useState<string>("");

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    dispatch(changePageNumber(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(pageNumber);
  };

  let url = `${POS_REQUEST_ENDPOINT}?page=${page + 1}&page_size=${rowsPerPage}`;

  const getAllTerminalRequests = async () => {
    dispatch(globalLoadingAction(true));
    try {
      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (requestStatus) {
        url = url + `&status=${requestStatus}`;
      }
      const resp = await customFetch.get(url);
      const newData = resp.data.data.results.map((request: RequestProps) => {
        const {
          id,
          merchant_address,
          merchant_business_category,
          merchant_business_name,
          merchant_name,
          merchant_phone_number,
          referral_code,
          status,
          terminal_serial_no,
          updated_at,
          updated_by,
          created_at,
        } = request;
        return createData(
          id,
          merchant_name,
          merchant_business_name,
          merchant_business_category,
          merchant_phone_number,
          referral_code,
          status === "new_request"
            ? "New request"
            : status === "out_for_delivery"
            ? "Out for delivery"
            : capitalizeFirstLetter(status),
          merchant_address,
          formattedDate(created_at),
          formattedDate(updated_at),
          updated_by,
          terminal_serial_no
        );
      });
      setAllTerminals(newData);
      setTotalCount(resp.data.data.count);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.message)
        : toast.error("An error occurred");
      dispatch(globalLoadingAction(false));
    }
  };

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllTerminalRequests();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [
    page,
    localSearch,
    requestStatus,
    navigate,
    rowsPerPage,
    dispatch,
    updateTerminalRequest,
  ]);

  return (
    <>
      <TRDIndicators />

      <Box
        sx={{
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "inherit",
            marginBottom: "30px",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              width: "inherit",
              direction: "row",
              alignItems: "center",
              justifyContent: "space-between",
              px: 4,
              py: 1.8,
            }}
          >
            <Stack
              width={"100%"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                width="21rem"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <SearchBar
                    localSearch={localSearch}
                    setLocalSearch={setLocalSearch}
                    setPage={setPage}
                    border={`2px solid ${theme.palette.common.lightBlue}`}
                    border2={"none"}
                    height2={"0.4rem"}
                  />
                </Box>

                <FilterBtn
                  setPage={setPage}
                  setFilter={setRequestStatus}
                  options={[
                    "All",
                    "New request",
                    "Out for delivery",
                    "Delivered",
                    "Cancelled",
                    "Returned",
                  ]}
                />
              </Stack>
            </Stack>
          </Paper>
        </Box>

        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    backgroundColor: "#EFF1F9",
                    color: theme.palette.common.greyColor,
                    fontWeight: "600",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {terminals.map(
                (element: { [key: string]: any }, index: number) => {
                  return (
                    <TRDRow
                      key={index}
                      element={element}
                      columns={columns}
                      index={index}
                      columnsExtra={columnsExtra}
                      currentID={currentID}
                      updateTerminalRequest={updateTerminalRequest}
                      setUpdateTerminalRequest={setUpdateTerminalRequest}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}

export default TerminalRequest;
