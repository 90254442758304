import React from "react";
import { Menu, MenuItem } from "components/common/ExternalComponents";
import { PlatformData, PlatformListProps } from "utils/types/CommonTypes";

const PlatformListComponent = ({
  setPlatformId,
  anchorEl,
  setAnchorEl,
  open,
  handleClosePlatform,
  list,
}: PlatformListProps) => {
  const selectPlatform = (id: string) => {
    setPlatformId(id);
    setAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClosePlatform}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {list?.map((el: PlatformData, index: number) => {
        return (
          <MenuItem key={index} onClick={(e) => selectPlatform(el.id)}>
            {el.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default PlatformListComponent;
