import React, { useEffect, useState } from "react";
import {
  Box,
  Close,
  KeyboardArrowRight,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import PlatformListComponent from "components/lists/PlatformList";
import { PLATFORM_LIST_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { customFetch } from "utils/Axios";
import { theme } from "utils/Theme";

const Dropdown = ({ text, setPlatformId }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [list, setList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleClickPlatform = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePlatform = () => {
    setAnchorEl(null);
  };

  const getAllPlatforms = async () => {
    try {
      const resp = await customFetch.get(PLATFORM_LIST_ENDPOINT);
      setList(resp?.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      } else if (
        error.response.status === 404 ||
        error.response.status === 500
      ) {
        error?.response?.data?.error?.server
          ? toast.error(error?.response?.data?.error?.server[0])
          : toast.error("An error ocurred");
      } else {
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error)
          : toast.error("An error occurred");
      }
    }
  };

  useEffect(() => {
    getAllPlatforms();
  }, [open]);

  return (
    <Box
      sx={{
        mr: "20px",
      }}
    >
      <CustomButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClickPlatform(e)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#EFF1F9",
          color: "#728394",
          "&:hover": {
            backgroundColor: "#EFF1F9",
            color: "#728394",
          },
        }}
      >
        {text}
        {open ? (
          <Close
            fontSize="small"
            sx={{
              color: theme.palette.common.lightBlue,
              transition: "0.2s",
              ml: 1,
            }}
            onClick={handleClosePlatform}
          />
        ) : (
          <KeyboardArrowRight
            sx={{
              transition: "0.2s",
              ml: 1,
            }}
          />
        )}
      </CustomButton>
      {open && (
        <PlatformListComponent
          open={open}
          list={list}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setPlatformId={setPlatformId}
          handleClosePlatform={handleClosePlatform}
        />
      )}
    </Box>
  );
};

export default Dropdown;
