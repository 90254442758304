import { configureStore } from "@reduxjs/toolkit";
import globalReducer, { globalSliceName } from "./GlobalSlice";
import userProfileReducer, {
  userProfileSliceName,
} from "./UserProfileSlice";
import roleReducer, { roleSliceName } from "./RoleSlice";

const store = configureStore({
  reducer: {
    [globalSliceName]: globalReducer,
    [userProfileSliceName]: userProfileReducer,
    [roleSliceName]: roleReducer,
  },
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
