import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Field,
  Form,
  Formik,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import { FormikControl } from "components/form/FormikControl";
import { UPDATE_POS_REQUEST_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";
import { deliveredTerminalValidator } from "utils/validators/TerminalValidator";

interface DeliveredFormProps {
  currentID: any;
  onClose: any;
  [key: string]: any;
}

interface FormikProps {
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  [key: string]: any;
}

function DeliveredForm({
  currentID,
  onClose,
  terminalSerialNumber,
  updateTerminalRequest,
  setUpdateTerminalRequest,
}: DeliveredFormProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateCall = async (
    terminalSerialNumber: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
  ) => {
    let url = `${UPDATE_POS_REQUEST_ENDPOINT}?id=${currentID}`;
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.patch(url, {
        terminal_serial_no: terminalSerialNumber,
        status: "delivered",
        recipient_first_name: firstName,
        recipient_last_name: lastName ? lastName : undefined,
        recipient_phone_number: phoneNumber,
      });
      onClose();
      setUpdateTerminalRequest(!updateTerminalRequest);
    } catch (error: any) {
      if (
        error.response.detail ===
        "Authentication credentials were not provided."
      ) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
        // toast.error("Unauthorized! Logging Out...");
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.message)
        : toast.error("An error occurred");
      dispatch(globalLoadingAction(false));
    }
  };

  const onSubmit = (values: { [key: string]: any }) => {
    const { terminalSerialNumber, firstName, lastName, phoneNumber } = values;
    updateCall(terminalSerialNumber, firstName, lastName, phoneNumber);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        phoneNumber: "",
        terminalSerialnumber: terminalSerialNumber || "",
      }}
      validationSchema={deliveredTerminalValidator}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, isSubmitting }: FormikProps) => {
        return (
          <Form placeholder={undefined}>
            <Box>
              <Field name={"firstName"}>
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      required={" *"}
                      background={"null"}
                      id={"firstName"}
                      control="input3"
                      label="First name of recipient"
                      name={"firstName"}
                      value={form?.values["firstName"]}
                      onChange={form.handleChange("firstName")}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Field name={"lastName"}>
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      background={"null"}
                      id={"lastName"}
                      control="input3"
                      label="Last name of recipient (optional)"
                      name={"lastName"}
                      value={form?.values["lastName"]}
                      onChange={form.handleChange("lastName")}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Field name={"phoneNumber"}>
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      required={" *"}
                      background={"null"}
                      id={"phoneNumber"}
                      control="input3"
                      label="Phone number of recipient"
                      name={"phoneNumber"}
                      value={form?.values["phoneNumber"]}
                      onChange={form.handleChange("phoneNumber")}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box
              sx={{
                marginTop: "25px",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!dirty || !isValid || isSubmitting}
                sx={{
                  "&.MuiButton-root": {
                    textTransform: "Capitalize",
                    borderRadius: "10px",
                    display: "block",
                    marginLeft: "auto",
                  },
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={22}
                    sx={{
                      color: "common.white",
                      margin: "0px 10px",
                    }}
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DeliveredForm;
