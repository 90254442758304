import { Yup } from "components/common/ExternalComponents";

export const STAFF_EMAIL = "credential";
export const PHONE_NUMBER = "phone_number";
export const PASSWORD = "password";
export const PASSWORD_CONFIRMATION = "confirm_password";

export const phoneNumberValidator = Yup.object({
  [PHONE_NUMBER]: Yup.string().required("Phone number is required"),
  // .matches(phoneNumberRegex, "Invalid phone number"),
  // .max(14, "Must not be more than 14 characters"),
});

export const otpValidator = Yup.object({
  otp: Yup.string()
    .required("otp is required")
    .max(6, "Must not be more than 6 digits"),
});

export const passwordValidator = Yup.object({
  [PASSWORD]: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
    .test(
      "isValidPass",
      " Password should contain at least: 1 uppercase, 1 lowercase, 1 number and 1 special character",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value!);
        const hasLowerCase = /[a-z]/.test(value!);
        const hasNumber = /[0-9]/.test(value!);
        const hasSymbol = /[!@#%&]/.test(value!);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
});

export const initialSigninValidator = Yup.object({
  [STAFF_EMAIL]: Yup.string()
    .matches(
      /^([a-zA-Z]{0,}\.?[a-zA-Z]{1,})@(dukka.com|dipfellows.org)$/,
      "Email should be a valid Dukka email Address"
    )
    .required("Email is a required field")
    .email("Email must be a valid email"),
  [PASSWORD]: Yup.string().required("Password is a required field"),
});

export const changePasswordValidator = Yup.object({
  [PASSWORD]: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters")
    .test(
      "isValidPass",
      " Password should contain at least: 1 uppercase, 1 lowercase, 1 number and 1 special character",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value!);
        const hasLowerCase = /[a-z]/.test(value!);
        const hasNumber = /[0-9]/.test(value!);
        const hasSymbol = /[!@#%&]/.test(value!);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  [PASSWORD_CONFIRMATION]: Yup.string()
    .required("Confirm Password is a required field")
    .oneOf([Yup.ref("password"), null], "Passwords should match"),
});
