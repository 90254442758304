import React, { ReactNode } from "react";
import { Input } from "components/form/FormikInput";
import { DateInput } from "components/form/FormikDate";
import Input2 from "components/form/FormikInput2";
import { Input3 } from "components/form/FormikInput3";
import { Select } from "components/form/FormikSelect";
import { Telephone } from "components/form/FormikPhone";
import { Select2 } from "./FormikSelect2";
import { Textarea } from "./FormikTextarea";

interface FormControlProp {
  control?: string;
  children?: ReactNode;
  [key: string]: any;
}

export const FormikControl = ({ control, ...rest }: FormControlProp) => {
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "input2":
      return <Input2 {...rest} />;
    case "input3":
      return <Input3 {...rest} />;
    case "date":
      return <DateInput {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "select2":
      return <Select2 {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "telephone":
      return <Telephone {...rest} />;
    default:
      return <Input {...rest} />;
  }
};
