import React, { useEffect, useState } from "react";
import box from "assets/images/amsAssets/box.svg";
import bike from "assets/images/amsAssets/bike.svg";
import posImage from "assets/images/amsAssets/pos.svg";
import IndicatorCard from "components/others/IndicatorCard";
import { Grid, toast, useNavigate } from "components/common/ExternalComponents";
import { SIGNIN_ROUTE } from "constants/Routes";
import { TERMINAL_REQUEST_TOTAL_ENDPOINT } from "constants/Endpoints";
import { useAppDispatch } from "service/hook/Hooks";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { customFetch } from "utils/Axios";
import { theme } from "utils/Theme";
import { IndicatorData } from "utils/types/CommonTypes";

const Indicators = () => {
  const [indicatorsData, setIndicatorsData] = useState<IndicatorData[] | null>(
    []
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getMetrics = async () => {
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.get(TERMINAL_REQUEST_TOTAL_ENDPOINT);
      setIndicatorsData([
        {
          id: 1,
          image: posImage,
          total: resp?.data?.data.total_new_requests,
          bgColor: theme.palette.common.prdPur,
          text: "Total new request",
        },
        {
          id: 2,
          image: bike,
          total: resp?.data?.data?.total_out_for_delivery,
          bgColor: theme.palette.common.purple,
          text: "Total out for delivery",
        },
        {
          id: 3,
          image: box,
          total: resp?.data?.data?.total_delivered,
          bgColor: theme.palette.common.green,
          text: "Total delivered",
        },
      ]);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getMetrics();
    }, 750);
  }, []);

  return (
    <>
      <Grid container alignItems={"center"}>
        {indicatorsData &&
          indicatorsData.map((data) => {
            const { id, image, total, bgColor, text } = data;
            return (
              <Grid
                item
                xs={6}
                md={3.5}
                key={id}
                sx={{
                  maxWidth: "300px",
                  marginRight: "40px",
                }}
              >
                <IndicatorCard
                  image={image}
                  total={total}
                  bgColor={bgColor}
                  text={text}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default Indicators;
