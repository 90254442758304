import React, { useState } from "react";
import {
  Box,
  CalendarMonth,
  Cancel,
  DateRangePicker,
  Popover,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { useAppDispatch } from "service/hook/Hooks";
import { changePageNumber } from "service/store/UserProfileSlice";
import { theme } from "utils/Theme";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateComponent = ({ setPage, dateRange, setDateRange }: any) => {
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState<null>(null);

  const dispatch = useAppDispatch();

  const handleSelect = (ranges: any) => {
    setDateRange(ranges.selection);
    setPage(0);
    dispatch(changePageNumber(0));

    if (ranges.selection.startDate && ranges.selection.endDate) {
      // setDateRangeAnchorEl(null);
    } else if (ranges.selection.startDate && !ranges.selection.endDate) {
      setDateRangeAnchorEl(dateRangeAnchorEl); // Don't close popover if only start date selected
    }
  };

  const handleClick = (event: any) => {
    setDateRangeAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDateRangeAnchorEl(null);
  };

  const handleCancelClick = (event: any) => {
    event.stopPropagation();
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };

  return (
    <Box
      sx={{
        marginBottom: "10px",
      }}
    >
      <CustomButton
        variant="outlined"
        onClick={handleClick}
        startIcon={<CalendarMonth />}
        endIcon={<Cancel onClick={handleCancelClick} />}
        color={"secondary"}
        sx={{
          "&.MuiButton-outlined": {
            borderColor: theme.palette.common.lightBlue,
            borderRadius: "8px",
            color: theme.palette.common.lightBlue,
            maxWidth: "auto",
            display: "flex",
            justifyContent: "space-between",
          },
        }}
      >
        {dateRange.startDate && dateRange.endDate
          ? `${dateRange.startDate.toDateString()} - ${dateRange.endDate.toDateString()}`
          : "Choose Date"}
      </CustomButton>

      <Popover
        open={Boolean(dateRangeAnchorEl)}
        anchorEl={dateRangeAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          ranges={[dateRange]}
          onChange={handleSelect}
          maxDate={new Date()}
        />
      </Popover>
    </Box>
  );
};

export default DateComponent;
