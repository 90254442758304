import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Field,
  Form,
  Formik,
  Paper,
  Stack,
  Typography,
  Yup,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { ID_CARD_UPDATE_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { customFetch } from "utils/Axios";
import { theme } from "utils/Theme";
import { FormikControl } from "components/form/FormikControl";

const DvaultModal = ({
  status,
  kycID,
  setAnchorEl,
  setOpenStatus,
  openStatus,
}: any) => {
  const [id, setId] = React.useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleStatusClose = () => {
    setAnchorEl(null);
    setOpenStatus(false);
  };

  const changeStatus = async (status: string, values: any) => {
    try {
      const resp = await customFetch.patch(
        `${ID_CARD_UPDATE_ENDPOINT}?id=${kycID}`,
        {
          verification_status: status === "APPROVED" ? "REJECTED" : "APPROVED",
          reason_for_rejection:
            status === "APPROVED"
              ? values.reason_for_rejection
              : null,
        }
      );
      dispatch(globalLoadingAction(true));
      toast.success(`Update successful`);
      handleStatusClose();
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      } else if (
        error.response.status === 404 ||
        error.response.status === 500
      ) {
        error?.response?.data?.error?.server
          ? toast.error(error?.response?.data?.error?.server[0])
          : toast.error("Update failed");
      } else {
        error?.response?.data?.error
          ? toast.error(error?.response?.data?.error)
          : toast.error("Update failed");
      }
      handleStatusClose();
    }
  };

  const names = [
    "ID Card",
    "NIN",
    "National ID Card",
    "Voter's Card",
    "Driver's License",
    "international Passport",
    "CAC Document",
    "Proof of address",
    "Selfie",
    "Wrong input field",
  ];

  const handleSubmit = (values: { [key: string]: any }) => {
    return changeStatus(status, values);
  };

  const handleDelete = () => setId("");

  return (
    <Dialog
      open={openStatus}
      onClose={handleStatusClose}
      sx={{
        "&.MuiDialog-root": {},
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
        },
      }}
    >
      <Paper
        sx={{
          width: "500px",
          p: 3,
        }}
      >
        <DialogContent
          sx={{
            "&.MuiDialogContent-root": {
              padding: 0,
            },
          }}
        >
          <Typography
            variant="h4"
            color="initial"
            sx={{
              fontWeight: 700,
              fontSize: "1.6rem",
              my: 1,
            }}
          >
            Reason for {status === "APPROVED" ? "rejecting" : "approving"} ID
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
              display: "block",
              fontWeight: 400,
              color: "#151920",
              opacity: "0.5",
            }}
          >
            Identify the document {status === "APPROVED" ? "with errors" : ""}{" "}
            and provide the reasons for{" "}
            {status === "APPROVED" ? "rejecting" : "approving"} the KYC.
          </Typography>

          <Formik
            initialValues={{
              type: "",
              reason_for_rejection: "",
            }}
            validationSchema={Yup.object().shape({
              type: Yup.string().required("This field is required"),
              reason_for_rejection: Yup.string()
            })}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <Form placeholder={undefined}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Field name="type">
                      {({ field, meta, form }: any) => {
                        return (
                          <FormikControl
                            required="*"
                            id={id}
                            control="select2"
                            background="null"
                            label={`Identify ${
                              status === "APPROVED" ? "faulty" : ""
                            } document`}
                            name="type"
                            value={id}
                            onChange={(e: any) => {
                              setId(e.target.value);
                              formik.setFieldValue("type", e.target.value);
                            }}
                            options={names}
                            field={field}
                            meta={meta}
                            form={form}
                            handleDelete={handleDelete}
                          />
                        );
                      }}
                    </Field>
                    {status === "APPROVED" ? (
                      <Field name="reason_for_rejection">
                        {({ field, meta, form }: any) => {
                          return (
                            <FormikControl
                              required="*"
                              control="textarea"
                              background="null"
                              label="Comment"
                              name="reason_for_rejection"
                              value={formik?.values?.reason_for_rejection}
                              onChange={formik.handleChange}
                              field={field}
                              meta={meta}
                              form={form}
                            />
                          );
                        }}
                      </Field>
                    ) : null}
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent={"flex-end"}
                    sx={{
                      marginTop: "50px",
                    }}
                  >
                    <CustomButton
                      variant="outlined"
                      color="secondary"
                      sx={{
                        marginRight: "10px",
                        background: "white",
                        border: `1px solid ${theme.palette.common.black}`,
                        "&:hover": {
                          background: "rgba(0, 0, 0, 0.05)",
                        },
                      }}
                      onClick={() => {
                        handleStatusClose();
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!formik.dirty}
                      sx={{
                        background: "black",
                        color: "white",
                      }}
                      // onClick={(e) => changeStatus(isVerified, formik.values)}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress
                          size={22}
                          sx={{
                            color: "common.white",
                            margin: "0px 10px",
                          }}
                        />
                      ) : status === "APPROVED" ? (
                        "Reject"
                      ) : (
                        "Approve"
                      )}
                    </CustomButton>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};

export default DvaultModal;

// import React from "react";
// import {
//   Box,
//   Dialog,
//   DialogContent,
//   Paper,
//   Typography,
//   toast,
//   useNavigate,
// } from "components/common/ExternalComponents";
// import CustomButton from "components/buttons/Button";
// import { KYC_UPDATE_ENDPOINT } from "constants/Endpoints";
// import { SIGNIN_ROUTE } from "constants/Routes";
// import { useAppDispatch } from "service/hook/Hooks";
// import { globalLoadingAction } from "service/store/GlobalSlice";
// import { userLogoutAction } from "service/store/UserProfileSlice";
// import { customFetch } from "utils/Axios";

// const DvaultModal = ({
//   isVerified,
//   kycID,
//   setAnchorEl,
//   setOpenStatus,
//   openStatus,
// }: any) => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();

//   const handleStatusClickClose = () => {
//     setOpenStatus(false);
//   };

//   const handleStatusClose = () => {
//     setAnchorEl(null);
//   };

//   const changeStatus = async (isVerified: boolean) => {
//     try {
//       const resp = await customFetch.patch(
//         `${KYC_UPDATE_ENDPOINT}?id=${kycID}`,
//         {
//           id_card: {
//             is_verified: !isVerified,
//           },
//         }
//       );
//       dispatch(globalLoadingAction(true));
//       toast.success(`Update successful`);
//       handleStatusClose();
//       handleStatusClickClose();
//     } catch (error: any) {
//       if (error.response.status === 401) {
//         dispatch(userLogoutAction());
//         return navigate(SIGNIN_ROUTE);
//       } else if (
//         error.response.status === 404 ||
//         error.response.status === 500
//       ) {
//         error?.response?.data?.error?.server
//           ? toast.error(error?.response?.data?.error?.server[0])
//           : toast.error("Update failed");
//       } else {
//         error?.response?.data?.error
//           ? toast.error(error?.response?.data?.error)
//           : toast.error("Update failed");
//       }
//       handleStatusClose();
//       handleStatusClickClose();
//     }
//   };

//   return (
//     <Dialog
//       open={openStatus}
//       onClose={handleStatusClickClose}
//       sx={{
//         "&.MuiDialog-root": {},
//       }}
//       PaperProps={{
//         sx: {
//           borderRadius: "16px",
//         },
//       }}
//     >
//       <Paper
//         sx={{
//           width: "300px",
//           p: 3,
//         }}
//       >
//         <DialogContent
//           sx={{
//             "&.MuiDialogContent-root": {
//               padding: 0,
//             },
//           }}
//         >
//           <Typography
//             variant="h4"
//             color="initial"
//             sx={{
//               fontWeight: 700,
//               fontSize: "1.6rem",
//               my: 1,
//             }}
//           >
//             {isVerified ? "Unverify" : "Verify"} account
//           </Typography>
//           <Typography
//             variant="body1"
//             sx={{
//               marginTop: "15px",
//               marginBottom: "15px",
//               display: "block",
//               fontWeight: 400,
//               color: "#151920",
//               opacity: "0.5",
//             }}
//           >
//             Are you sure you want to {isVerified ? "unverify" : "verify"} this
//             balance
//           </Typography>
//         </DialogContent>

//         <Box
//           sx={{
//             marginTop: "15px",
//           }}
//         >
//           <CustomButton
//             variant="outlined"
//             color="secondary"
//             sx={{
//               "&.MuiButton-root": {
//                 width: "48%",
//                 color: "rgba(21, 25, 32, 0.5)",
//                 borderRadius: "10px",
//                 fontWeight: 500,
//                 texTransform: "capitalize",
//               },
//             }}
//             onClick={() => {
//               handleStatusClickClose();
//             }}
//           >
//             Cancel
//           </CustomButton>
//           <CustomButton
//             variant="contained"
//             color="secondary"
//             sx={{
//               "&.MuiButton-root": {
//                 width: "48%",
//                 marginLeft: "10px",
//                 borderRadius: "10px",
//                 fontWeight: 500,
//                 textTransform: "capitalize",
//               },
//             }}
//             onClick={(e) => changeStatus(isVerified)}
//           >
//             {isVerified ? "Unverify" : "Verify"}
//           </CustomButton>
//         </Box>
//       </Paper>
//     </Dialog>
//   );
// };

// export default DvaultModal;
