import simoneDvault from "assets/images/navigationAssets/simoneDvault.svg";
import TMS from "assets/images/navigationAssets/tms.svg";
import AMS from "assets/images/navigationAssets/ams.svg";
import { DVAULT_ROUTE, TMS_ROUTE, ASSET_ROUTE } from "constants/Routes";
import {
  canUserUseAMS,
  canUserUseDVAULT,
  canUserUseTMS,
} from "service/helper/RoleFunctionsHelper";

export let newArr: AccordionDataProps[] = [];
export const checkRole = (roles: string[]) => {
  newArr = [];
  canUserUseDVAULT(roles) ? newArr.push(accordionData[0]) : null;

  canUserUseTMS(roles) ? newArr.push(accordionData[1]) : null;

  canUserUseAMS(roles) ? newArr.push(accordionData[2]) : null;
  return newArr;
};

interface AccordionDataProps {
  title: string;
  route: string;
  image: string;
  details: string[];
}

const accordionData: AccordionDataProps[] = [
  {
    title: "Dvault",
    route: DVAULT_ROUTE,
    image: simoneDvault,
    details: ["Merchant verification portal", "Payment management"],
  },
  {
    title: "TMS",
    route: TMS_ROUTE,
    image: TMS,
    details: [
      "Payment reconciliation",
      "Revenue",
      "Merchants",
      "Software updates",
    ],
  },
  {
    title: "AMS",
    route: ASSET_ROUTE,
    image: AMS,
    details: ["Terminal distribution", "Terminal request (TRD)"],
  },
];
