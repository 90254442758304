import React, { useEffect, useState } from "react";
import inflow from "assets/images/tmsAssets/inflow.svg";
import outflow from "assets/images/tmsAssets/outflow.svg";
import transfer from "assets/images/tmsAssets/transfer.svg";
import { Box, useNavigate, toast } from "components/common/ExternalComponents";
import Dropdown from "components/dropdown/Dropdown";
import { SIGNIN_ROUTE } from "constants/Routes";
import { PLATFORM_METRICS_ENDPOINT } from "constants/Endpoints";
import PrdIndicatorCard from "pages/tms/prd/PrdIndicatorCard";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { IndicatorData, PlatformListProps } from "utils/types/CommonTypes";
import { formatter } from "utils/Helpers";
import { customFetch } from "utils/Axios";

export default function PRDIndicators({
  platformId,
  setPlatformId,
}: PlatformListProps) {
  const [indicatorsData, setIndicatorsData] = useState<IndicatorData[] | null>(
    []
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openPlatforms = async () => {
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.get(
        `${PLATFORM_METRICS_ENDPOINT}?platform_id=${platformId}`
      );
      setIndicatorsData([
        {
          id: 1,
          image: inflow,
          total: resp?.data?.total_credit_transactions,
          text: "Total inflow",
          shadow: "",
        },
        {
          id: 2,
          image: outflow,
          total: resp?.data?.total_debit_transactions,
          text: "Total outflow",
          shadow: "",
        },
        {
          id: 3,
          image: transfer,
          total: resp?.data?.total_transactions,
          text: "Total transaction count",
          shadow: "",
        },
        {
          id: 4,
          image: transfer,
          total: formatter.format(resp?.data?.total_card_transactions),
          text: "Total card transactions",
          shadow: "",
        },
        {
          id: 5,
          image: transfer,
          total: formatter.format(resp?.data?.total_bank_transactions),
          shadow: "",
          text: "Total bank transactions",
        },
        {
          id: 6,
          image: outflow,
          total: formatter.format(resp?.data?.total_failed_transactions),
          shadow: "",
          text: "Total failed transactions",
        },
        {
          id: 7,
          image: inflow,
          total: formatter.format(resp?.data?.total_successful_transactions),
          shadow: "",
          text: "Total successful transactions",
        },
      ]);
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.error)
        : toast.error("An error occurred");
    }
  };

  useEffect(() => {
    openPlatforms();
  }, [platformId]);

  return (
    <>
      <Dropdown text={"Get platform metrics"} setPlatformId={setPlatformId} />

      {platformId && (
        <Box sx={{ paddingTop: "20px", width: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              overflow: "scroll",
            }}
          >
            {indicatorsData &&
              indicatorsData.map((data) => {
                const { id, image, total, text } = data;
                return (
                  <Box key={id}>
                    <PrdIndicatorCard
                      src={image}
                      total={total}
                      text={text}
                      shadow="0px 202.825px 490.554px rgba(120, 120, 168, 0.17)"
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </>
  );
}
