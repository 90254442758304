import React from "react";
import CustomButton from "components/buttons/Button";
import {
  Box,
  CircularProgress,
  Stack,
  Field,
  Formik,
  Form,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import { theme } from "utils/Theme";
import { FormikControl } from "components/form/FormikControl";
import { CREATE_TERMINAL_ENDPOINT } from "constants/Endpoints";
import { SIGNIN_ROUTE } from "constants/Routes";
import { userLogoutAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { useAppDispatch } from "service/hook/Hooks";
import { customFetch } from "utils/Axios";
import {
  createOrUpdateInitialValues,
  createTerminalValidator,
} from "utils/validators/TerminalValidator";

interface FormikProps {
  field: { [key: string]: any };
  meta: { [key: string]: any };
  form: { [key: string]: any };
  [key: string]: any;
}
interface UpdateTerminalProps {
  [key: string]: any;
}

function CreateTerminal({
  handleDialogClose,
  updateTerminalList,
  setUpdateTerminalList,
}: UpdateTerminalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: { [key: string]: any }) => {
    dispatch(globalLoadingAction(true));
    try {
      const resp = await customFetch.post(CREATE_TERMINAL_ENDPOINT, {
        tid: values?.terminalID,
        name: values?.name ? values?.name : undefined,
        serial_number: values?.serialNumber ? values?.serialNumber : undefined,
      });
      toast.success(`Terminal created successfully`);
      setUpdateTerminalList(!updateTerminalList);
      handleDialogClose();
      dispatch(globalLoadingAction(false));
    } catch (error: any) {
      if (error.response.status === 401) {
        dispatch(userLogoutAction());
        return navigate(SIGNIN_ROUTE);
      }
      error?.response?.data?.error
        ? toast.error(error?.response?.data?.message)
        : toast.error("An error occurred");
      dispatch(globalLoadingAction(false));
    }
  };

  return (
    <Formik
      initialValues={createOrUpdateInitialValues}
      validationSchema={createTerminalValidator}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        return (
          <Form placeholder={undefined}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Field name="terminalID">
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      required={" *"}
                      control="input"
                      label="Terminal ID"
                      name="terminalID"
                      background="null"
                      value={formik?.values?.terminalID}
                      onChange={formik.handleChange}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Field name="serialNumber">
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      control="input"
                      background="null"
                      label="Serial number"
                      name="serialNumber"
                      value={formik?.values?.serialNumber}
                      onChange={formik.handleChange}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
              <Field name="name">
                {({ field, meta, form }: FormikProps) => {
                  return (
                    <FormikControl
                      control="input"
                      background="null"
                      label="Name"
                      name="name"
                      onBlur={formik.handleBlur}
                      value={formik?.values?.name}
                      field={field}
                      meta={meta}
                      form={form}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box>
              <Stack direction="row" justifyContent={"flex-end"} mt={2}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  sx={{
                    marginRight: "10px",
                    background: "white",
                    border: `1px solid ${theme.palette.common.black}`,
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.05)",
                    },
                  }}
                  onClick={() => handleDialogClose()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!formik.dirty}
                  sx={{
                    background: "black",
                    color: "white",
                  }}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress
                      size={22}
                      sx={{
                        color: "common.white",
                        margin: "0px 10px",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </CustomButton>
              </Stack>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CreateTerminal;
