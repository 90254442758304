import React, { useState, useEffect } from "react";
import { authFormLayoutStyles } from "assets/styles/auth/AuthStyles";
import success1 from "assets/images/loginAssets/success.svg";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  VisibilityOff,
  Visibility,
  Formik,
  Form,
  useNavigate,
  useLocation,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import { FormikControl } from "components/form/FormikControl";
import AuthFormLayout from "layouts/AuthFormLayout";
import { SIGNIN_ROUTE } from "constants/Routes";
import { RESET_PASSWORD_ENDPOINT } from "constants/Endpoints";
import AuthLayout from "layouts/AuthLayout";
import { useAppDispatch } from "service/hook/Hooks";
import { globalLoadingAction } from "service/store/GlobalSlice";
import { useRequest } from "service/hook/RequestHook";
import { PASSWORD_CONFIRMATION } from "utils/validators/AuthFormValidator";
import {
  PASSWORD,
  changePasswordValidator,
} from "utils/validators/AuthFormValidator";

type inputValue = { [key: string]: any };

const initialValues = {
  [PASSWORD]: "",
  [PASSWORD_CONFIRMATION]: "",
};

const ResetPasswordPage: React.FC<any> = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const {
    state: { loading, success },
    makeRequest,
  } = useRequest();

  const onSubmit = (values: inputValue) => {
    makeRequest(RESET_PASSWORD_ENDPOINT, {
      body: {
        password: values?.password,
        confirm_password: values?.confirm_password,
        token: state?.state?.data?.token,
        otp_code: state?.values?.otp,
      },
      redirectOn401: false,
      bearer: false,
    });
  };

  useEffect(() => {
    dispatch(globalLoadingAction(false));
  }, []);

  return (
    <AuthLayout>
      {success ? (
        <Box sx={authFormLayoutStyles.container}>
          <Grid container direction="column" alignItems="center" spacing={4}>
            <Grid item>
              <Box
                component="img"
                src={success1}
                alt="simone logo"
                width="100px"
              />
            </Grid>
            <Grid item width="90%">
              <Box mb={4} textAlign={"center"}>
                <Typography
                  sx={{
                    color: "#6B6B6B",
                    fontWeight: 600,
                    marginBottom: "20px",
                  }}
                >
                  Password has been updated successfully!
                </Typography>
                <Typography sx={{ fontWeight: 500 }}>
                  Click Proceed to be redirected to login page.
                </Typography>
              </Box>

              <Box textAlign="center">
                <CustomButton onClick={() => navigate(`/${SIGNIN_ROUTE}`)}>
                  Proceed
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <AuthFormLayout>
          <Box
            mb={3}
            textAlign="center"
            sx={{
              color: "#6B6B6B",
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Reset password
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordValidator}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form placeholder={undefined}>
                  <Box mb={3}>
                    <FormikControl
                      control="input2"
                      placeholder="Enter new password"
                      name={PASSWORD}
                      type={!showPassword ? "password" : "text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box mb={4}>
                    <FormikControl
                      control="input2"
                      placeholder="Confirm password"
                      name={PASSWORD_CONFIRMATION}
                      type={!showConfirmPassword ? "password" : "text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <CustomButton
                      type="submit"
                      disabled={!formik.dirty || !formik.isValid || loading}
                    >
                      Update
                    </CustomButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </AuthFormLayout>
      )}
    </AuthLayout>
  );
};

export default ResetPasswordPage;
