import {
  ASSET_ROUTE,
  DVAULT_ROUTE,
  MERCHANT_ROUTE,
  MERCHANT_VERIFICATION_ROUTE,
  PAYMENT_MANAGEMENT_ROUTE,
  PRD_ROUTE,
  REVENUE_ROUTE,
  SOFTWARE_UPDATE_ROUTE,
  TERMINAL_DISTRIBUTION_ROUTE,
  TERMINAL_REQUEST_ROUTE,
  TMS_ROUTE,
} from "constants/Routes";

export const linkName = (el: string) => {
  switch (el) {
    case "Merchant verification portal":
      return `${DVAULT_ROUTE}/${MERCHANT_VERIFICATION_ROUTE}`;
    case "Payment management":
      return `${DVAULT_ROUTE}/${PAYMENT_MANAGEMENT_ROUTE}`;
    case "Payment reconciliation":
      return `${TMS_ROUTE}/${PRD_ROUTE}`;
    case "Revenue":
      return `${TMS_ROUTE}/${REVENUE_ROUTE}`;
    case "Merchants":
      return `${TMS_ROUTE}/${MERCHANT_ROUTE}`;
    case "Software updates":
      return `${TMS_ROUTE}/${SOFTWARE_UPDATE_ROUTE}`;
    case "Terminal request (TRD)":
      return `${ASSET_ROUTE}/${TERMINAL_REQUEST_ROUTE}`;
    default:
      return `${ASSET_ROUTE}/${TERMINAL_DISTRIBUTION_ROUTE}`;
  }
};
