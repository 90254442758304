import React from "react";
import {
  FormControl,
  TextField,
  Field,
  ErrorMessage,
  FieldProps,
} from "components/common/ExternalComponents";
import { TextError } from "components/form/TextError";

interface InputProp {
  [key: string]: any;
}

const Input2: React.FC<InputProp> = (props) => {
  const handleAllowed = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: any,
    allow: {}
  ) => {
    const target = e.target as HTMLInputElement;

    const re = /\D/g;
    let input = target.value;
    if (allow === FORM_ALLOWED.NUMBER) {
      input = input.replace(re, "");
    }
    form.handleChange(e);
    form.setFieldValue(props.name, input);
  };

  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        return (
          <FormControl
            fullWidth
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: `${props.padding ?? "default"}`,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: `${props.bradius ?? "12px"}`,
                "& ::placeholder": { fontSize: "14px" },
              },
            }}
          >
            <TextField
              id={props.name}
              {...props}
              {...field}
              label={props.label && props.placeholder}
              onChange={(e) => handleAllowed(e, form, props.allow)}
              type={props.type || "text"}
              error={!!(form.errors[props.name] && form.touched[props.name])}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <ErrorMessage name={props.name} component={TextError} />
          </FormControl>
        );
      }}
    </Field>
  );
};
export default Input2;

export const FORM_ALLOWED: { NUMBER: string } = {
  NUMBER: "number",
};

export const UncontrolledInput: React.FC<InputProp> = (props) => {
  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: `${props.padding || "7.5px 14px"}`,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: `${props.bradius || "8px"}`,
        },
      }}
    >
      <TextField
        disabled
        id={props.name}
        {...props}
        label={props.label && props.placeholder}
        type={props.type || "text"}
      />
    </FormControl>
  );
};
