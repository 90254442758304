import React from "react";
import { MenuItem } from "components/common/ExternalComponents";
import { theme } from "utils/Theme";
import { ModalProps } from "utils/types/CommonTypes";

const ModalList = ({ options, handleChange, formik }: ModalProps) => {
  return (
    options !== undefined &&
    options?.map((option: any, index: number) => {
      return (
        <MenuItem
          key={index}
          value={option.id}
          onClick={() => handleChange(option?.id, formik)}
          sx={{
            borderBottom: `1px solid ${theme.palette.common.primaryColor}`,
            px: 1,
            py: 2,
          }}
        >
          {option.name || option?.account_name}
        </MenuItem>
      );
    })
  );
};

export default ModalList;
