import React from "react";
import { TextError } from "components/form/TextError";
import {
  FormControl,
  FormLabel,
  Typography,
  MenuItem,
  ErrorMessage,
  Select,
  Box,
  Chip,
  Theme,
} from "components/common/ExternalComponents";
import { theme } from "utils/Theme";

interface InputProp {
  [key: string]: any;
}

export const Select2 = (props: InputProp) => {
  const {
    label,
    name,
    type,
    error,
    meta,
    field,
    form,
    background,
    required,
    helperText,
    maxLength,
    defaultValue,
    value,
    options,
    id,
    onChange,
    handleDelete,
    ...rest
  } = props;

  function getStyles(name: string, personName: string, theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        "& .css-13nvzlm-MuiFormHelperText-root": {
          fontSize: "13px",
          ml: 0,
        },
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          color: "black",
          fontWeight: 500,
          paddingBottom: "10px",
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {label}
        <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
          {required}
        </Typography>
      </FormLabel>
      <Select
        labelId={name}
        id={name}
        value={value}
        onChange={onChange}
        renderValue={() => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip
              key={value}
              label={value}
              onDelete={handleDelete}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          </Box>
        )}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            style={getStyles(name, id, theme)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
