import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  useNavigate,
  Outlet,
} from "components/common/ExternalComponents";
import SideBar from "components/nav/Sidebar";
import Navbar from "components/nav/Navbar";
import { SIGNIN_ROUTE } from "constants/Routes";
import { useAppSelector } from "service/hook/Hooks";

function AppLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { token } = useAppSelector((store) => store.profileSliceReducer);

  useEffect(() => {
    !token && navigate(SIGNIN_ROUTE);
  }, [token]);

  return (
    <>
      {matches && (
        <Typography style={{ textAlign: "center" }}>
          Content only available on Desktop mode
        </Typography>
      )}
      <Grid
        container
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        <Grid
          item
          xs={2.5}
          sx={{
            height: "100vh",
            position: "sticky",
            top: 0,
            background: "#1B1C1E",
          }}
        >
          <SideBar />
        </Grid>
        <Grid item xs={9.5} sx={{ background: "#F5F6Fa" }}>
          <Navbar />
          <Box
            sx={{
              px: 6,
              paddingTop: "48px",
            }}
          >
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AppLayout;
