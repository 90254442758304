import React, { useState } from "react";
import CustomButton from "components/buttons/Button";
import {
  Box,
  FilterList,
  Popover,
  useTheme,
} from "components/common/ExternalComponents";
import FilterOptions from "components/buttons/filter/FilterOptions";
import { useAppDispatch } from "service/hook/Hooks";
import { changePageNumber } from "service/store/UserProfileSlice";
import { FilterProps } from "utils/types/CommonTypes";

const FilterBtn = ({
  setPage,
  setFilter,
  options,
  setOpenWalletDialog,
  setOpenTerminalDialog,
  setWalletOrTerminal,
}: FilterProps) => {
  const [anchorEl, setAnchorEL] = useState<null>(null);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const handleChange = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    filterInput: boolean | string
  ) => {
    setPage(0);
    dispatch(changePageNumber(0));
    if (filterInput === "By Wallet") setOpenWalletDialog(true);
    else if (filterInput === "By Terminal") setOpenTerminalDialog(true);
    else if (filterInput === "Reset") setWalletOrTerminal("");
    else setFilter(filterInput);
    handleClose(e);
  };

  const handleClick = (e: any) => {
    setAnchorEL(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEL(null);
  };

  return (
    <>
      <CustomButton
        variant="outlined"
        startIcon={<FilterList />}
        sx={{
          border: `2px solid ${theme.palette.common.lightBlue}`,
          color: theme.palette.common.lightBlue,
          "&:hover": {
            background: "rgba(0, 0, 0, 0.05)",
            border: `2px solid ${theme.palette.common.lightBlue}`,
          },
        }}
        aria-describedby={id}
        onClick={handleClick}
      >
        Filter
      </CustomButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: "150px",
          }}
        >
          <FilterOptions handleChange={handleChange} options={options} />
        </Box>
      </Popover>
    </>
  );
};

export default FilterBtn;
