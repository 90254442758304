import React, { useEffect, useState } from "react";
import simoneBigLogo from "assets/images/loginAssets/simoneBigLogo.svg";
import { landingPageStyles } from "assets/styles/IndexStyles";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Typography,
  Formik,
  Form,
  Visibility,
  VisibilityOff,
  toast,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";
import CustomLink from "components/links/Link";
import { FormikControl } from "components/form/FormikControl";
import { LOGIN_ENDPOINT } from "constants/Endpoints";
import { FORGOT_PASSWORD_ROUTE, LANDING_ROUTE } from "constants/Routes";
import AuthFormLayout from "layouts/AuthFormLayout";
import AuthLayout from "layouts/AuthLayout";
import { useAppDispatch, useAppSelector } from "service/hook/Hooks";
import { useRequest } from "service/hook/RequestHook";
import { userLoginAction } from "service/store/UserProfileSlice";
import { globalLoadingAction } from "service/store/GlobalSlice";
import {
  PASSWORD,
  STAFF_EMAIL,
  initialSigninValidator,
} from "utils/validators/AuthFormValidator";
import { firstLetterLowercase } from "utils/Helpers";

const initialValues = { [STAFF_EMAIL]: "", [PASSWORD]: "" };

type LoginValues = {
  credential: string;
  password: string;
};

const SigninPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const { loading } = useAppSelector((store) => store.globalSliceReducer);

  useEffect(() => {
    const progressCount = setInterval(() => {
      setProgress((prev) => prev + 10);
    }, 300);
    return () => {
      progress === 100 && setShowLoginPage(true);
      clearInterval(progressCount);
    };
  }, [progress]);

  const {
    state: { success, data },
    makeRequest,
  } = useRequest();

  const onSubmit = (values: LoginValues) => {
    makeRequest(LOGIN_ENDPOINT, {
      body: {
        credential: firstLetterLowercase(values.credential),
        password: values.password,
      },
      bearer: false,
    });
    dispatch(globalLoadingAction(true));
  };

  useEffect(() => {
    if (success) {
      toast.success("LoggedIn");
      dispatch(userLoginAction({ ...data }));
      dispatch(globalLoadingAction(false));
      navigate(LANDING_ROUTE);
    }
    dispatch(globalLoadingAction(false));
  }, [success, data]);

  return (
    <>
      {showLoginPage ? (
        <AuthLayout>
          <AuthFormLayout>
            <Formik
              initialValues={initialValues}
              validationSchema={initialSigninValidator}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form placeholder={undefined}>
                    <Box mb={4}>
                      <FormikControl
                        control="input2"
                        sx={{ py: 1.5 }}
                        placeholder="Enter your email"
                        name={STAFF_EMAIL}
                        type="email"
                      />
                      <FormikControl
                        control="input2"
                        sx={{ py: 1.5 }}
                        placeholder="Enter your password"
                        name={PASSWORD}
                        type={!showPassword ? "password" : "text"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box textAlign={"end"}>
                        <CustomLink
                          styles={{
                            color: "#b0b0b0",
                            fontWeight: 600,
                            fontSize: "13px",
                            mt: 1,
                          }}
                          route={FORGOT_PASSWORD_ROUTE}
                        >
                          Forgot password?
                        </CustomLink>
                      </Box>
                    </Box>

                    <Box textAlign="center">
                      <CustomButton
                        type="submit"
                        disabled={!formik.dirty || !formik.isValid}
                      >
                        {loading ? (
                          <CircularProgress
                            size={22}
                            sx={{
                              color: "common.white",
                              margin: "0px 10px",
                            }}
                          />
                        ) : (
                          "Sign in"
                        )}
                      </CustomButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </AuthFormLayout>
        </AuthLayout>
      ) : (
        <Container maxWidth="sm" sx={landingPageStyles.container}>
          <Grid
            container
            direction="column"
            sx={landingPageStyles.logoContainer}
          >
            <Box
              component="img"
              src={simoneBigLogo}
              alt="simone logo"
              width="100%"
            />
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={progress}
              sx={landingPageStyles.linearProgress}
            />
            <Box
              sx={{
                minWidth: 35,
                mt: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default SigninPage;
