import React from "react";
import simone from "assets/images/loginAssets/simoneBigLogo.svg";
import {
  Box,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useNavigate,
} from "components/common/ExternalComponents";
import CustomButton from "components/buttons/Button";

const NotFound = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      {matches && (
        <Typography variant="h2" style={{ textAlign: "center" }}>
          Content only available on Desktop mode
        </Typography>
      )}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100vh"}
        sx={{ background: "#F5F6Fa" }}
      >
        <Stack
          direction={"column"}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            margin: "0 auto",
          }}
        >
          <Box
            component="img"
            alt="Dvault"
            src={simone}
            sx={{
              width: "180px",
              margin: "0 auto",
            }}
          />
          <Box width={"500px"} textAlign={"center"} mb={4}>
            <Typography
              sx={{ textAlign: "center", fontSize: "3rem", color: "#6B6B6B" }}
            >
              Page Not Found!
            </Typography>
            <Typography
              sx={{
                lineHeight: "25px",
                marginTop: { md: "2rem" },
                fontSize: "1rem",
              }}
            >
              This is a 404 error which means you may have clicked on a bad link
              or typed in an invalid URL. In the meantime, do try again.
            </Typography>
          </Box>

          <Box textAlign={"center"}>
            <CustomButton
              color="secondary"
              variant="contained"
              sx={{
                marginLeft: "10px",
                background: "black",
                color: "white",
                border: "1px solid black",
                "&:hover": {
                  background: "rgba(0, 0, 0, 0.05)",
                  color: "black",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Try again
            </CustomButton>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default NotFound;
