import React from "react";
import { Button, ButtonProps } from "components/common/ExternalComponents";

interface ButtonBaseProps extends ButtonProps {
  [key: string]: any;
  bgColor?: string;
  textColor?: string;
  bRadius?: string;
  px?: string;
  py?: string;
  mx?: string;
  width?: string;
}

const CustomButton: React.FC<ButtonBaseProps> = ({
  children,
  textColor = "white",
  bgColor = "black",
  bRadius = "6px",
  px = "4rem",
  py = "0.4rem",
  mx,
  width,
  ...props
}) => (
  <Button
    disableElevation
    variant="contained"
    sx={{
      "&.MuiButton-root": {
        bgcolor: bgColor,
        color: textColor,
        borderRadius: bRadius,
        px: px,
        py: py,
        mx: mx,
        width: width,
        textTransform: "capitalize",
      },
      "&.Mui-disabled": {
        backgroundColor: "rgba(0,0,0,0.5)",
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export default CustomButton;
