//Base route
export const LANDING_ROUTE = "/";
export const SIGNIN_ROUTE = "login";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const ENTER_OTP_ROUTE = "/otp";
export const PASSWORD_RESET_ROUTE = "/password-reset";
export const TMS_ROUTE = "tms";
export const DVAULT_ROUTE = "dvault";
export const ASSET_ROUTE = "ams";

//Dvault routes
export const MERCHANT_VERIFICATION_ROUTE = "merchant-verification-portal";
export const BALANCE_DETAILS_ROUTE = "balance-details";
export const BALANCE_DETAILS_EDIT_ROUTE = "edit";
export const PAYMENT_MANAGEMENT_ROUTE = "payment-management";

//TMS routes
export const PRD_ROUTE = "payment-reconciliation";
export const PRD_DETAILS_ROUTE = "details";
export const REVENUE_ROUTE = "revenue";
export const MERCHANT_ROUTE = "merchant";
export const SOFTWARE_UPDATE_ROUTE = "software-update";

//AMS routes
export const TERMINAL_DISTRIBUTION_ROUTE = "terminal-distribution";
export const TERMINAL_REQUEST_ROUTE = "terminal-request";
