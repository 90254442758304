import React, { useState } from "react";
import {
  FormLabel,
  Typography,
  FormControl,
  TextField,
  ErrorMessage,
  DesktopDatePicker,
  LocalizationProvider,
  AdapterDayjs,
} from "components/common/ExternalComponents";
import { TextError } from "components/form/TextError";

interface InputProp {
  [key: string]: any;
}

export const DateInput = (props: InputProp) => {
  const {
    label,
    name,
    error,
    onChange,
    minDate,
    maxDate,
    disableFuture,
    disablePast,
    required,
    value,
    ...rest
  } = props;

  const [toggleDate, setToggleDate] = useState(false);

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        "& .css-13nvzlm-MuiFormHelperText-root": {
          fontSize: "13px",
          ml: 0,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormLabel
          htmlFor={name}
          sx={{
            color: "black",
            fontWeight: 500,
            paddingBottom: "10px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          {label}
          <Typography component="span" sx={{ color: "red", fontSize: "1rem" }}>
            {" "}
            *
          </Typography>
        </FormLabel>
        <DesktopDatePicker
          views={["year", "month", "day"]}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={new Date()}
          onChange={onChange}
          openTo="day"
          inputFormat="YYYY-MM-DD"
          onClose={() => setToggleDate(false)}
          value={value}
          open={toggleDate}
          renderInput={(params) => (
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "35px",
                  width: "400px",
                  color: "black",
                  fontSize: "14px",
                  background: "#F5f5f5",
                  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              fullWidth
              label={label}
              name={props.name}
              id={props.name}
              {...props}
              onClick={() => {
                setToggleDate(true);
              }}
              {...params}
              {...rest}
            />
          )}
        />
      </LocalizationProvider>
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
