import React from "react";
import {
  Box,
  Paper,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "components/common/ExternalComponents";
import { IndicatorData } from "utils/types/CommonTypes";

function IndicatorCard({ image, total, bgColor, text }: IndicatorData) {
  const theme = useTheme();
  return (
    <>
      <Paper
        sx={{
          borderRadius: "13px",
          px: 2,
          py: 2,
          width: "300px",
        }}
      >
        <Grid container alignItems={"center"} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={12} lg={3}>
            <Box component={"span"}>
              <Box
                component={"img"}
                src={image}
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    marginLeft: "auto",
                    marginRight: "auto",
                  },
                  objectFit: "contain",
                  border: "20px solid transparent",
                  display: "block",
                  height: "4rem",
                  width: "4rem",
                  overflow: "visible",
                  borderRadius: "50%",
                  background: `${bgColor}`,
                  margin: "10px 0",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6.9}>
            <Stack>
              <Typography
                sx={{
                  color: theme.palette.common.lightBlue,
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                {text}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    textAlign: "center",
                  },
                  fontWeight: 500,
                  color: theme.palette.common.black,
                }}
              >
                {total}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default IndicatorCard;
