import React from "react";
import {
  FormControl,
  MuiTelInput,
  ErrorMessage,
} from "components/common/ExternalComponents";
import { TextError } from "components/form/TextError";

interface InputProp {
  [key: string]: any;
}

export const Telephone = (props: InputProp) => {
  const {
    label,
    name,
    type,
    error,
    meta,
    field,
    form,
    background,
    required,
    helperText,
    maxLength,
    defaultValue,
    value,
    ...rest
  } = props;

  const [phone, setPhone] = React.useState("+234");

  const handleChange = (newPhone: any) => {
    setPhone(newPhone);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: `${props.padding ?? "default"}`,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: `${props.bradius ?? "12px"}`,
          "& ::placeholder": { fontSize: "14px" },
        },
      }}
    >
      <MuiTelInput
        name={name}
        id={name}
        value={value || phone}
        onChange={handleChange}
        {...rest}
        sx={{
          margin: "1rem 0 0 0",
          "& .MuiOutlinedInput-input": { fontSize: "1.2rem" },
        }}
      />
      <ErrorMessage name={name} component={TextError} />
    </FormControl>
  );
};
